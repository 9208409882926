// ==========================================================================
// Content
// ==========================================================================

.overlay {
  &.active {
    display: block;
    background: hsla(0, 0%, 0%, 0.4);
    position: fixed;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: 2;
  }
}

  .overlay {
    &.active {
      background: hsla(0, 0%, 0%, 0.7);
    }
  }

img {
  max-width: 100%;
  height: auto;
}
.content {}

// Onglets sur page type logi, quand titre passe sur 2 lignes
.nav-link {
  .border-gray-1 & {
    height: 100%;
  }
}
