.read-more {
  $self: &;

  &__text {
    // le max-height correspond au nombres de lignes max * le line-height d'une ligne
    max-height: rem(100);
    overflow: hidden;
    transition: all ease 0.4s;
  }

  &__btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__btn {
    border: 0;
    padding: 0;
    background: none;
    color: $pink;

    &:hover {
      text-decoration: underline;
    }

    &--closeÂ  {
      display: none;
    }
  }

  &.active {
    #{$self} {
      &__btn {
        &--open {
          display: none;
        }

        &--close {
          display: block;
        }
      }
    }
  }
}
