// ==========================================================================
// Buttons
// ==========================================================================

/*doc
---
title: Boutons
name: buttons
category: Base CSS
---

```html_example
<div>
  <button type="button" class="btn">Ajouter</button>
  <button type="button" class="btn btn--wider btn-hovereffect"><span class="btn-hovereffect__circle"><span>DÃ©couvrez</span></button>
  <button type="button" class="btn btn--wider btn-hovereffect disabled"><span class="btn-hovereffect__circle"><span>DÃ©couvrez</span></button>
</div>

<div>
  <a href class="btn-icon btn-delete">
    <svg class="icon-trash" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.7 17.5"><path fill="#222" d="M5.8 6.9c.2 0 .4.2.4.4v6.2c0 .2-.2.4-.4.4s-.4-.2-.4-.4V7.3c0-.2.2-.4.4-.4zm4 0c.2 0 .4.2.4.4v6.2c0 .2-.2.4-.4.4s-.4-.2-.4-.4V7.3c0-.2.2-.4.4-.4z"/><path fill="none" stroke="#222" stroke-width=".8" stroke-linecap="round" d="M.4 3.9h14.9m-1.2 0l-1.5 11.5c-.1 1-1 1.7-2 1.7H5.1c-1 0-1.8-.7-2-1.7L1.4 3.9m2.5-1.4L11.8.4"/></svg>
  </a>
  <a href="" class="btn-icon btn-favorite">
    <svg class="icon-star" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.9 18.1"><path fill="#C40040" d="M9.8 14.8l4.8 2.5h.1v-.1l-.8-5.3c0-.3 0-.6.3-.8l3.9-3.8v-.1H18l-5.4-.8c-.3 0-.5-.2-.7-.5L9.5 1s-.1-.1-.1 0L6.9 5.9c-.1.2-.4.4-.7.5l-5.3.7c-.1 0-.1.1-.1.1v.1l3.9 3.8c.2.2.3.5.2.8L4 17.3c0 .1 0 .1.1.1h.1L9 14.9c.2-.2.6-.2.8-.1zm-.4.7L4.6 18c-.4.2-1 .1-1.2-.4-.1-.2-.1-.4-.1-.6l.9-5.4v-.1l-4-3.6c-.3-.4-.3-.9 0-1.3.1-.1.3-.2.5-.3l5.4-.8s.1 0 .1-.1L8.6.5c.2-.4.8-.6 1.2-.4.2.1.3.2.4.4l2.4 4.9s0 .1.1.1l5.4.8c.5.1.8.5.8 1 0 .2-.1.4-.3.5l-3.9 3.8v.1l.9 5.4c.1.5-.2 1-.7 1-.2 0-.4 0-.6-.1l-4.9-2.5z"/></svg>
    <svg class="icon-star-fill" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 18.1"><path fill="#C40040" d="M9.5 15.5L4.7 18c-.4.2-1 .1-1.2-.4-.1-.2-.1-.4-.1-.6l.9-5.4v-.1l-4-3.6c-.4-.3-.4-.9 0-1.3.1-.1.3-.2.5-.3l5.4-.8s.1 0 .1-.1L8.7.5c.2-.4.8-.6 1.2-.4.2.1.3.2.4.4l2.4 4.9s0 .1.1.1l5.4.8c.5.1.8.5.8 1 0 .2-.1.4-.3.5l-3.9 3.8v.1l.9 5.4c.1.5-.2 1-.7 1-.2 0-.4 0-.6-.1l-4.9-2.5z"/></svg>
  </a>
  <a href="" class="btn-icon btn-share">
    <svg class="icon-share" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.2 18.6"><g fill="none" stroke="#222" stroke-width=".8"><g transform="translate(10.353)"><circle cx="2.9" cy="2.9" r="2.5"/><circle cx="2.9" cy="15.7" r="2.5"/></g><circle cx="2.9" cy="9.6" r="2.5"/><path d="M5.1 8.3l6.1-4m-.4 10.1L5 10.7"/></g></svg>
  </a>
</div>

<div>
  <div class="counter-container" data-trigger="counter">
    <button type="button" class="btn counter-container__button" data-operation="remove" >-</button>
    <span class="counter-container__number" data-operation="result">100</span>
    <button type="button" class="btn counter-container__button" data-operation="add">+</button>
  </div>
  <div class="counter-container counter-container--pink" data-trigger="counter">
    <button type="button" class="btn counter-container__button" data-operation="remove">-</button>
    <span class="counter-container__number" data-operation="result">0</span>
    <button type="button" class="btn counter-container__button" data-operation="add">+</button>
  </div>
</div>

<div>
  <a href="#" class="btn-entry">
    <svg class="icon-entry" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41 41"><circle class="icon-entry-circle" fill="none" stroke="#222" cx="20.5" cy="20.5" r="20"/><path class="icon-entry-arrow" fill="none" stroke="#222" d="M10.5 20.8h18.1m-4.3-4.3l5 4.3-5 4.3"/></svg>
  </a>
</div>

<div>
  <a href="#" class="simple-link">Lien simple</a>
  <a href="#" class="simple-link simple-link--thin">Lien simple</a>
</div>
```

*/

.btn {
  display: inline-block;

  background: transparent;
  border: 1px solid $darkbrown;
  border-radius: 2em;
  transition: all ease 250ms;

  color: $darkbrown;
  line-height: 2;
  &:hover,
  &:focus,
  &:active {
    background: $pink;
    border-color: $pink;

    color: #fff;
  }
  &--lh-normal {
    line-height: normal;
  }
}

.btn--full {
  background: $pink;
  border-color: $pink;
  color: $white;
  &:hover,
  &:focus,
  &:active {
    background: #fff;
    border-color: $darkbrown;
    color: $darkbrown;
  }
}

.btn--black {
  &:hover,
  &:focus,
  &:active {
    background: $darkbrown;
    border-color: $darkbrown;

    color: #fff;
  }
}

.btn--black-full {
  background: $darkbrown;
  border-color: $darkbrown;
  color: #fff;

  &:hover,
  &:focus,
  &:active {
    background: #fff;
    border-color: $darkbrown;
    color: $darkbrown;
  }
}

.btn--grey {
  border-color: $gray-2;
  color: $gray-2;
}

.btn--white {
  border-color: #fff;

  color: #fff;
  &:hover,
  &:focus,
  &:active {
    background: #fff;
    border-color: #fff;

    color: $darkbrown;
  }
}

.btn--wider {
  padding-left: $input-btn-padding-x * 2.16;
  padding-right: $input-btn-padding-x * 2.16;
}

.disabled {
  cursor: default;
  opacity: 1;

  color: $gray-3;
  border-color: $gray-3;
  &:hover,
  &:focus,
  &:active {
    color: $gray-3;
    border-color: $gray-3;
  }
  &.btn-hovereffect {
    &:hover::before,
    &:focus::before,
    &:active::before {
      --size: 0;
    }
  }
}

.btn-hovereffect {
  position: relative;
  overflow: hidden;
  span {
    position: relative;
  }
  &.btn--white {
    &::before {
      background: #fff;
    }
  }
  &:hover,
  &:focus,
  &:active {
    span {
      color: $white;
    }
  }
  &.isHover {
    span {
      color: $white;
    }
  }
  .btn-hovereffect__circle {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;

    background: $darkbrown;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    transition: all linear 100ms;
  }
}

.btn--block {
  display: block;
  width: 100%;
}

.btn-entry {
  &--cart {
    border: rem(1) solid $darkbrown;
    border-radius: 50%;
    width: rem(40);
    height: rem(40);
    transition: all ease 0.4s;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    svg {
      color: $darkbrown;
      width: rem(23);
      height: auto;
      transition: all ease 0.4s;
    }

    &:hover,
    &:focus,
    &:active {
      background: $darkbrown;

      svg {
        color: #fff;
      }
    }
  }
}

.btn--reverse {
  background: $pink;
  border-color: $pink;
  color: $white;

  &:hover {
    background: transparent;
    border-color: $darkbrown;
    color: $darkbrown;
  }
}

.simple-link {
  display: inline-block;
  color: $darkbrown;
  font-size: rem(13);
  font-weight: $font-weight-medium;
  text-decoration: underline;
  &:hover,
  &:focus,
  &:active {
    color: $darkbrown;
    text-decoration: none;
  }
}

.simple-link--thin {
  font-weight: 100;
}

.simple-link--no-line {
  text-decoration: none;
  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
}

.simple-link--no-border {
  border: none;
}

.simple-link--no-background {
  background: none;
}

.simple-link--collapse {
  position: relative;
  padding-right: rem(15);

  &:before {
    content: "";
    width: 0;
    height: 0;
    border-left: rem(3) solid transparent;
    border-right: rem(3) solid transparent;
    border-top: rem(6) solid $black;
    position: absolute;
    right: 0;
    top: 50%;
    transition: transform 0.2s;
    transform: rotate(180deg);
  }

  &.collapsed {
    &:before {
      transform: rotate(0);
    }
  }
}

.btn-icon {
  display: inline-block;
  cursor: pointer;
}

.btn-icon--white {
  path {
    fill: $white;
  }
}

.notransition {
  transition: none !important;
}
