.cards {
  margin-top: rem(20);

  img {
    width: rem(50);
    height: auto;
    margin-right: rem(10);

    &:last-of-type {
      margin-right: 0;
    }
  }
}
