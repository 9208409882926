.delivery-slots {
  &__content {
    margin-top: rem(15);
  }

  &__list {
    padding-left: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-auto-flow: column;

    .form-check {
      margin-bottom: rem(10);
    }
  }

  &__date {
    font-weight: 400;
    color: $pink;
  }

  @include media-breakpoint-up(md) {
    display: flex;

    &__content {
      width: 50%;

      &:first-of-type {
        margin-right: rem(40);
      }
    }
  }
}
