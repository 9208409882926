// ==========================================================================
// Helpers
// ==========================================================================

// Containers
.container-only-md {
  @include make-container();
  @include media-breakpoint-up(md) {
    @include make-container();
    @include make-container-max-widths();
  }
}

// Backgrounds
.black-bg {
  background: $darkbrown;
}

.bg-darker {
  background: hsla(0, 0%, 0%, 0.02);
}

@include media-breakpoint-down(sm) {
  .skin-bg-sm {
    background: $skin;
  }
}

.bg-img-full {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

a.hover-neutral {
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
}

.font-header {
  font-family: $headings-font-family;
}

.font-like-text {
  font-family: $font-family-sans-serif;
}

.text-underline {
  text-decoration: underline !important;
}

// #32645
.justify-content-evenly {
  justify-content: space-evenly;
}

.text-pink {
  color: $pink;
}

.text-small {
  font-size: rem(12);
}

.text-striked {
  text-decoration: line-through;
}
