// ==========================================================================
// Slider des thumnails Univers gourmand (uniquement mobile)
// ==========================================================================

.gourmand-universe__thumbnail {
  &:not(.active) {
    display: inline-block;
  }
  &:hover,
  &:focus,
  &:active {
    text-decoration-color: $body-color;
  }
  .product__img {
    img {
      height:  rem(250);
      object-fit: cover;
    }
  }
}

@include media-breakpoint-down(sm) {
  .gourmand-universe__thumbnails {
    .slick-slide {
      padding: 0 rem(10);
    }
  }
}

@include media-breakpoint-up(md) {
  .gourmand-universe__thumbnail {
    padding-right: rem(5);
  }
  .gourmand-universe__thumbnails {
    .product__img {
      img {
        height:  rem(135);
      }
    }
  }
}
