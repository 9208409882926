// ==========================================================================
// Forms
// ==========================================================================

/*doc
---
title: Barre de recherche
name: searchform
category: Base CSS
---

```html_example
<form class="search-form">
	<!-- Text -->
	<div class="form-group form-group--search-form">
		<label class="sr-only" for="input-search">Rechercher</label>
		<input type="search" id="input-search" class="form-control form-search-input" placeholder="Que recherchez-vous ?">
	</div>
</form>
```

*/

.search-form {
  display: none;
}

.form-group--search-form {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.form-search-input {
  padding: rem(20) rem(25) rem(20) rem(10);

  background: $gray-0 url("#{$path-img}/svg/icon-search.svg") no-repeat center right 10px / 17px; //url("../img/icon-search.svg")
  border: none;
  border-radius: 1.5em;

  color: $darkbrown;
  font-size: rem(14);
  &:focus {
    background: $gray-0;
  }
}

// #32904 : md Ã  lg
@media (hover: hover) {
  @include media-breakpoint-up(lg) {
    .form-search-input {
      padding: rem(20) rem(25);
      background-position: center right 25px;
    }
  }
}

.search-form__btn-container {
  text-align: right;
}

// #32904 : md Ã  lg
@media (hover: hover) {
  @include media-breakpoint-up(lg) {
    .search-form {
      display: block;
      //.header:not(.search-expanded) & {
      //  position: relative;
      //  top: 50%;
      //  transform: translateY(-50%);
      //}
    }

    .search-form__btn-container {
      flex: 0;
      width: 0px; height: 0px;
      overflow: hidden;
    }
    }
}
