// ==========================================================================
// Column and its blocs for filter and shopping cart
// ==========================================================================

/*doc
---
title: Colonne filtre
name: columnfilter
category: Modules
---

```html_example
<div style="width: 300px; margin: 0 0 30px;">
  <aside class="aside-column">
    <form method="" action="">
      <p class="aside-column__results">16 rÃ©sultats</p>
      <div class="aside-column__block aside-column__block--underline">
        <p class="aside-column__title">Votre sÃ©lection</p>
        <ul class="aside-column__list">
          <li class="aside-column__item">
          <button title="Supprimer ce filtre" class="delete-filter">
            <svg class="icon-cross" viewbox="0 0 15 15" preserveAspectRatio="xMidYMid slice" xmlns="http://www.w3.org/2000/svg"><path d="M7.5 6.8L14.3 0l.7.8-6.8 6.7 6.8 6.8-.7.7-6.8-6.7-6.8 6.8-.7-.8 6.8-6.8L0 .8.7.1z" fill="#222" fill-rule="evenodd"/></svg>
          </button>
          TempÃ©rature ambiante (+4Â°C)
          </li>
        </ul>
        <button class="simple-link delete-all-filters">Effacer la sÃ©lection</button>
      </div>
      <div class="aside-column__block">
        <p class="aside-column__title">Mode de conservation</p>
        <ul class="aside-column__list">
          <li class="aside-column__item form-group">
            <input type="checkbox" class="form-check-input" id="fresh">
            <label for="fresh" class="form-check-label">
              Frais (entre 0Â°C et 14Â°C) <span class="aside-column__product-count">(46)</span>
              <span data-toggle="tooltip" data-placement="top" title="Information complÃ©mentaire" class="aside-column__tooltip">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="-644 446 11 10" width="10" height="10">
                    <path fill="#222" d="M-638 449.1c-.1.1-.3.2-.5.2s-.4-.1-.5-.2c-.1-.1-.2-.3-.2-.5s.1-.4.2-.5c.1-.1.3-.2.5-.2s.4.1.5.2c.1.1.2.3.2.5s-.1.4-.2.5zm0 4.6c-.3.1-.5.2-.6.2-.1.1-.3.1-.5.1-.3 0-.6-.1-.7-.2-.2-.1-.3-.4-.3-.6v-.3c0-.1 0-.2.1-.3l.3-1.1c0-.1.1-.2.1-.3v-.3c0-.1 0-.2-.1-.3-.1-.1-.2-.1-.3-.1h-.3l-.2.1.1-.3c.2-.1.4-.2.6-.2.2-.1.4-.1.5-.1.3 0 .5.1.7.2.2.1.3.4.3.6v.3c0 .1 0 .2-.1.3l-.3 1.1c0 .1-.1.2-.1.3v.3c0 .1 0 .3.1.3.1.1.2.1.3.1.2 0 .3 0 .5-.1l-.1.3zm-5.5-2.6v4.8h5c2.7 0 5-2.3 4.9-5s-2.3-5-5-4.9c-2.7 0-5 2.2-5 5 .1 0 .1.1.1.1z"/>
                </svg>
              </span>
            </label>
          </li>
          <li class="aside-column__item form-group">
            <input type="checkbox" class="form-check-input" id="ambient-temp">
            <label for="ambient-temp" class="form-check-label">
              TempÃ©rature ambiante (+4Â°C) <span class="aside-column__product-count">(12)</span>
              <span data-toggle="tooltip" data-placement="top" title="Information complÃ©mentaire" class="aside-column__tooltip">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="-644 446 11 10" width="10" height="10">
                    <path fill="#222" d="M-638 449.1c-.1.1-.3.2-.5.2s-.4-.1-.5-.2c-.1-.1-.2-.3-.2-.5s.1-.4.2-.5c.1-.1.3-.2.5-.2s.4.1.5.2c.1.1.2.3.2.5s-.1.4-.2.5zm0 4.6c-.3.1-.5.2-.6.2-.1.1-.3.1-.5.1-.3 0-.6-.1-.7-.2-.2-.1-.3-.4-.3-.6v-.3c0-.1 0-.2.1-.3l.3-1.1c0-.1.1-.2.1-.3v-.3c0-.1 0-.2-.1-.3-.1-.1-.2-.1-.3-.1h-.3l-.2.1.1-.3c.2-.1.4-.2.6-.2.2-.1.4-.1.5-.1.3 0 .5.1.7.2.2.1.3.4.3.6v.3c0 .1 0 .2-.1.3l-.3 1.1c0 .1-.1.2-.1.3v.3c0 .1 0 .3.1.3.1.1.2.1.3.1.2 0 .3 0 .5-.1l-.1.3zm-5.5-2.6v4.8h5c2.7 0 5-2.3 4.9-5s-2.3-5-5-4.9c-2.7 0-5 2.2-5 5 .1 0 .1.1.1.1z"/>
                </svg>
              </span>
            </label>
          </li>
          <li class="aside-column__item form-group">
            <input type="checkbox" class="form-check-input" id="frozen">
            <label for="frozen" class="form-check-label">
              SurgelÃ© (-18Â°C) <span class="aside-column__product-count">(17)</span>
              <span data-toggle="tooltip" data-placement="top" title="Information complÃ©mentaire" class="aside-column__tooltip">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="-644 446 11 10" width="10" height="10">
                    <path fill="#222" d="M-638 449.1c-.1.1-.3.2-.5.2s-.4-.1-.5-.2c-.1-.1-.2-.3-.2-.5s.1-.4.2-.5c.1-.1.3-.2.5-.2s.4.1.5.2c.1.1.2.3.2.5s-.1.4-.2.5zm0 4.6c-.3.1-.5.2-.6.2-.1.1-.3.1-.5.1-.3 0-.6-.1-.7-.2-.2-.1-.3-.4-.3-.6v-.3c0-.1 0-.2.1-.3l.3-1.1c0-.1.1-.2.1-.3v-.3c0-.1 0-.2-.1-.3-.1-.1-.2-.1-.3-.1h-.3l-.2.1.1-.3c.2-.1.4-.2.6-.2.2-.1.4-.1.5-.1.3 0 .5.1.7.2.2.1.3.4.3.6v.3c0 .1 0 .2-.1.3l-.3 1.1c0 .1-.1.2-.1.3v.3c0 .1 0 .3.1.3.1.1.2.1.3.1.2 0 .3 0 .5-.1l-.1.3zm-5.5-2.6v4.8h5c2.7 0 5-2.3 4.9-5s-2.3-5-5-4.9c-2.7 0-5 2.2-5 5 .1 0 .1.1.1.1z"/>
                </svg>
              </span>
            </label>
          </li>
        </ul>
      </div>
      <div class="aside-column__block">
        <p class="aside-column__title">Mode de prÃ©paration</p>
        <ul class="aside-column__list">
          <li class="aside-column__item form-group">
            <input type="checkbox" class="form-check-input" id="whole-foie-gras">
            <label for="whole-foie-gras" class="form-check-label">Foie gras entier <span class="aside-column__product-count">(4)</span></label>
          </li>
          <li class="aside-column__item form-group">
            <input type="checkbox" class="form-check-input" id="foie-gras">
            <label for="foie-gras" class="form-check-label">Bloc de foie gras <span class="aside-column__product-count">(12)</span></label>
          </li>
        </ul>
      </div>
      <div class="aside-column__block">
      <p class="aside-column__title">Prix</p>
      <div class="slider-price" data-min="8.99" data-max="230">
        <div class="ui-slider-handle handles handle-min"></div>
        <div class="ui-slider-handle handles handle-max"></div>
      </div>

        <div class="form-group">

        </div>
      </div>
    </form>


  </aside>
</div>

```

*/

.aside-column {
  position: relative;
  padding-right: rem(40);

  &::after {
    content: "";
    position: absolute;
    top: rem(40);
    bottom: 0;
    right: 0;
    width: rem(1);
    background-color: $gray-1;
  }
}

.aside-column__block {
  padding: 0 rem(10) rem(40) 0;

  &:nth-of-type(2) {
    padding-top: rem(40);
  }
}

.aside-column__results {
  color: $pink;
  font-weight: bold;
}

@include media-breakpoint-up(md) {
  .aside-column__results {
    padding-top: rem(10);
  }
}

.aside-column__block--underline {
  border-bottom: 1px solid $gray-1;
}

.aside-column__title {
  display: block;

  font-weight: bold;
}

.aside-column__product-count {
  color: $gray-3;
}

.aside-column__tooltip {
  vertical-align: super;
}

.aside-column__list {
  margin: 0;
  padding: 0;
  list-style-type: none;

  .form-check-label {
    font-size: rem(14);
  }
}

.aside-column__item {
  font-size: rem(14);
  display: flex;
}

.aside-column__filter-container {
  display: none;
}

.aside-column__item-count {
  vertical-align: middle;
  color: $gray-3;
  margin-left: rem(5);
}

.delete-filter {
  margin-right: rem(10);
  padding: 0;
  width: rem(20);
  height: rem(20);

  background: $body-color;
  border: $body-color;
  border-radius: 2em;
  line-height: 0.7;

  svg {
    width: rem(10);
    height: rem(10);

    path {
      fill: #FFF;
    }
  }
}

.delete-all-filters {
  margin-top: rem(20);

  background: none;
  border: none;

  font-size: rem(14);
  font-weight: $font-weight-normal;
}


.ui-state-default.handles {
  display: inline-block;
  width: rem(15);
  height: rem(15);

  background: $pink;
  border: none;
  border-radius: rem(10);

}

.ui-state-default.handles:focus,
.ui-state-default.handles:active {
  outline: none;
}

.ui-widget {
  .handle-value {
    position: absolute;
    top: rem(20);
    transform: translateX(-50%);
    left: 50%;
    font-size: rem(14);
    background: white;
    border: solid 1px lightgrey;
    width: 50px;
    text-align: center;
    border-radius: 15px;

    &:disabled {
      color: $black;
    }

    &:focus,
    &:active {
      outline: none;
    }
  }
}

.ui-widget.ui-widget-content {
  height: 2px;
  background: $pink;
  z-index: 0;
}

.ui-slider-horizontal {
  .ui-slider-range {
    background: $pink;
  }
}

.ui-widget.ui-widget-content {
  background: $gray-1;
  border: none;
}

.filters-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.filter-tag {

  display: flex;
  align-items: center;
  padding: rem(5) rem(5) rem(3) rem(15);
  margin-bottom: rem(20);

  background: $white;
  border: 1px solid $gray-1;
  border-radius: rem(20);

  color: $body-color;
  font-size: 1.1em;
  line-height: normal;

  span {
    vertical-align: middle;
  }

  &:hover,
  &:focus,
  &:active {
    color: inherit;
    text-decoration: none;
  }
}

.filter-tag__btn {
  width: rem(20);
  height: rem(20);
  margin-left: rem(35);

  background: $body-color url("#{$path-img}/svg/icon-cross--white.svg") no-repeat center / 50% auto;
  border: none;
  border-radius: rem(20);

  color: #FFF;
  flex-shrink: 0;
}
