// ==========================================================================
// Mon compte
// ==========================================================================

@include media-breakpoint-up(md) {
  .border-right-gray-2-md {
    border-right: 2px solid $gray-1;
  }
}

.show-more-orders-btn__counter {
  color: $gray-3;
}

.show-more-orders-btn {
  background: none;
  border: none;
}
.order {
  margin: rem(50) 0 rem(100);
  opacity: 1;
  overflow: initial;
  transition: all ease 500ms;
  &.disabled {
    margin: 0;
    opacity: 1;
    max-height: 0;
    overflow: hidden;
  }

  &--recap {
    max-height: none;
    transition: none;
    margin-bottom: rem(20);
  }
}

.order__total-line__cell--total {
  flex-basis: 100%;

  font-size: rem(20);
}

.order__total-label {
  flex: 0 0 50%;
}

.order__total-price {
  flex: 0 0 50%;
}
