// ==========================================================================
// Slider products (uniquement mobile)
// ==========================================================================

@include media-breakpoint-up(md) {
  .slider-products {
    display: flex;
    justify-content: center;
    > div {
      flex-grow: 1;
      flex-basis: 25%;
    }
    .product-quick-add {
      flex-basis: 25%;
      flex-grow: 1;
    }
  }
}

[data-slider="products"] {
  .slick-dots {
    li {
      margin: 0;
      button {
        &::before {
          font-size: 9px;
          opacity: 1;
        }
      }
      &.slick-active {
        button {
          &::before {
            color: $pink;
          }
        }
      }
    }
  }
}
