%pl-svg {
  margin-right: rem(5);
  width: rem(15);
  path {
    fill: $white;
  }
}

.infos-banner {
  .sticky & {
    display: none;
  }

  &__container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: column;
  }

  &__tel {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    position: relative;
    margin-bottom: rem(10);

    &:hover {
      color: $white;
    }

    svg {
      @extend %pl-svg;
    }
  }

  &__cost {
    svg {
      @extend %pl-svg;
    }
  }

  @include media-breakpoint-up(md) {
    &__container {
      flex-direction: row;
      align-items: center;
    }

    &__tel {
      padding-right: rem(40);
      margin-bottom: 0;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: rem(20);
        display: block;
        height: 80%;
        width: rem(1);
        background: $white;
        top: 10%;
      }
    }
  }
}
