// ==========================================================================
// Screen-reader
// ==========================================================================

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: fixed;
  top: 0; left: 0;
  padding: rem(3);
  z-index: 999;

  background: $white;
  border: 2px solid $gray-1;
}
