// @todo Remove it! (just for test purpose)
@import url('https://fonts.googleapis.com/css?family=Andada|Ubuntu:300,400,500');

// ==========================================================================
// Framework
// ==========================================================================

@import 'app/utilities/global'; // Override les variables Bootstrap

// Option 1: Inclure l'intÃ©gralitÃ© de Bootstrap
@import '~bootstrap/scss/bootstrap';

// Option 2: Inclure des parties de Bootstrap
// @link https://getbootstrap.com/docs/4.1/getting-started/theming/

// Requis
// @import "~bootstrap/scss/functions";
// @import "~bootstrap/scss/variables";
// @import "~bootstrap/scss/mixins";

// Optionnels
// @import "~bootstrap/scss/reboot";
// ...

@import "~select2/src/scss/core";
@import "~video.js/dist/video-js.css";
@import "~jquery-ui/themes/base/theme.css";
@import "~jquery-ui/themes/base/slider.css";
@import "~jquery-ui/themes/base/datepicker.css";
@import "~slick-carousel/slick/slick";
@import "~slick-carousel/slick/slick-theme";
@import "~card/dist/card.css";

// ==========================================================================
// Utilities
// ==========================================================================

@import 'app/utilities/index';

// ==========================================================================
// Elements
// ==========================================================================

@import 'app/elements/index';

// ==========================================================================
// Structure
// ==========================================================================

@import 'app/structure/index';

// ==========================================================================
// Modules
// ==========================================================================

@import 'app/modules/index';

// ==========================================================================
// Views
// ==========================================================================

@import 'app/views/index';
