.secure {
  color: $greenPale;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: "-";
    margin-right: rem(10);
    margin-left: rem(10);
  }

  &__picto {
    margin-left: rem(10);
    display: inline-block;
    width: rem(15);
    stroke: $greenPale;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: auto;
    }
  }
}
