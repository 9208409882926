// ==========================================================================
// Bloc chronofresh
// ==========================================================================

/*doc
---
title: Bloc Chronofresh
name: blocchronofresh
category: Blocs
---

```html_example
<div class="chronofresh">
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" class="picto-chronofresh-blue" enable-background="new -601 397 96 108" viewBox="-601 397 96 108">
      <path fill="#FEFEFE" d="M-506 445.6c0-26.2-21.3-47.4-47.5-47.4s-47.5 21.2-47.5 47.4c0 23.3 16.8 42.6 39 46.6l8.5 12.7 8.5-12.7c22.2-4 39-23.3 39-46.6"/>
      <path fill="#BCE5FF" d="M-516.5 445.7c0-20.3-16.5-36.7-36.8-36.7-20.3 0-36.8 16.4-36.8 36.7 0 17.7 12.5 32.4 29.1 35.9l7.7 11.5 7.7-11.5c16.6-3.5 29.1-18.3 29.1-35.9"/>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="a" width="72.8" height="62" x="-626.9" y="395.6" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="b" width="72.8" height="62" x="-626.9" y="395.6" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#a)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-554.6 396c-16.4 14.1-55.4 47.2-71.9 61.2" mask="url(#b)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="c" width="77.5" height="66.3" x="-630" y="396.8" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="d" width="77.5" height="66.3" x="-630" y="396.8" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#c)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-552.9 397.2c-16.7 13.9-60.1 51.4-76.7 65.5" mask="url(#d)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="e" width="77.9" height="66.6" x="-628.7" y="398" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="f" width="77.9" height="66.6" x="-628.7" y="398" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#e)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-551.2 398.5c-16.4 14.4-60.6 51.5-77.1 65.8" mask="url(#f)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="g" width="75.9" height="64.7" x="-625" y="399.3" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="h" width="75.9" height="64.7" x="-625" y="399.3" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#g)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-549.5 399.7c-16.6 14.2-58.4 49.6-75.1 63.8" mask="url(#h)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="i" width="72.8" height="62" x="-632.7" y="389.8" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="j" width="72.8" height="62" x="-632.7" y="389.8" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#i)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-560.4 390.2c-16.4 14.1-55.4 47.2-71.9 61.2" mask="url(#j)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="k" width="77.5" height="66.3" x="-635.8" y="391" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="l" width="77.5" height="66.3" x="-635.8" y="391" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#k)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-558.7 391.4c-16.7 13.9-60.1 51.4-76.7 65.5" mask="url(#l)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="m" width="77.9" height="66.6" x="-634.5" y="392.3" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="n" width="77.9" height="66.6" x="-634.5" y="392.3" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#m)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-557 392.7c-16.4 14.4-60.6 51.5-77.1 65.8" mask="url(#n)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="o" width="75.9" height="64.7" x="-630.8" y="393.5" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="p" width="75.9" height="64.7" x="-630.8" y="393.5" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#o)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-555.3 393.9c-16.6 14.2-58.4 49.6-75.1 63.8" mask="url(#p)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="q" width="72.8" height="62" x="-638.5" y="384" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="r" width="72.8" height="62" x="-638.5" y="384" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#q)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-566.2 384.4c-16.4 14.1-55.4 47.2-71.9 61.2" mask="url(#r)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="s" width="77.5" height="66.3" x="-641.6" y="385.2" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="t" width="77.5" height="66.3" x="-641.6" y="385.2" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#s)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-564.5 385.7c-16.7 13.9-60.1 51.4-76.7 65.5" mask="url(#t)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="u" width="77.9" height="66.6" x="-640.3" y="386.5" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="v" width="77.9" height="66.6" x="-640.3" y="386.5" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#u)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-562.8 386.9c-16.4 14.4-60.6 51.5-77.1 65.8" mask="url(#v)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="w" width="75.9" height="64.7" x="-636.6" y="387.7" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="x" width="75.9" height="64.7" x="-636.6" y="387.7" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#w)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-561.1 388.1c-16.6 14.2-58.4 49.6-75.1 63.8" mask="url(#x)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="y" width="72.8" height="62" x="-644.3" y="378.2" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="z" width="72.8" height="62" x="-644.3" y="378.2" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#y)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-572 378.6c-16.4 14.1-55.4 47.2-71.9 61.2" mask="url(#z)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="A" width="77.5" height="66.3" x="-647.3" y="379.5" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="B" width="77.5" height="66.3" x="-647.3" y="379.5" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#A)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-570.3 379.9c-16.7 13.9-60.1 51.4-76.7 65.5" mask="url(#B)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="C" width="77.9" height="66.6" x="-646.1" y="380.7" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="D" width="77.9" height="66.6" x="-646.1" y="380.7" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#C)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-568.6 381.1c-16.4 14.4-60.6 51.5-77.1 65.8" mask="url(#D)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="E" width="75.9" height="64.7" x="-642.4" y="381.9" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="F" width="75.9" height="64.7" x="-642.4" y="381.9" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#E)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-566.9 382.4c-16.6 14.2-58.4 49.6-75.1 63.8" mask="url(#F)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="G" width="72.8" height="62" x="-650.1" y="372.5" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="H" width="72.8" height="62" x="-650.1" y="372.5" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#G)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-577.8 372.9c-16.4 14.1-55.4 47.2-71.9 61.2" mask="url(#H)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="I" width="77.5" height="66.3" x="-653.1" y="373.7" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="J" width="77.5" height="66.3" x="-653.1" y="373.7" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#I)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-576.1 374.1c-16.7 13.9-60.1 51.4-76.7 65.5" mask="url(#J)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="K" width="77.9" height="66.6" x="-651.9" y="374.9" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="L" width="77.9" height="66.6" x="-651.9" y="374.9" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#K)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-574.4 375.3c-16.4 14.4-60.6 51.5-77.1 65.8" mask="url(#L)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="M" width="75.9" height="64.7" x="-648.2" y="376.2" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="N" width="75.9" height="64.7" x="-648.2" y="376.2" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#M)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-572.7 376.6c-16.6 14.2-58.4 49.6-75.1 63.8" mask="url(#N)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="O" width="72.8" height="62" x="-655.9" y="366.7" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="P" width="72.8" height="62" x="-655.9" y="366.7" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#O)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-583.5 367.1c-16.4 14.1-55.4 47.2-71.9 61.2" mask="url(#P)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="Q" width="77.5" height="66.3" x="-658.9" y="367.9" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="R" width="77.5" height="66.3" x="-658.9" y="367.9" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#Q)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-581.9 368.3c-16.7 13.9-60.1 51.4-76.7 65.5" mask="url(#R)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="S" width="77.9" height="66.6" x="-657.7" y="369.1" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="T" width="77.9" height="66.6" x="-657.7" y="369.1" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#S)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-580.2 369.6c-16.4 14.4-60.6 51.5-77.1 65.8" mask="url(#T)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="U" width="75.9" height="64.7" x="-654" y="370.4" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="V" width="75.9" height="64.7" x="-654" y="370.4" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#U)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-578.5 370.8c-16.6 14.2-58.4 49.6-75.1 63.8" mask="url(#V)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="W" width="72.8" height="62" x="-661.7" y="360.9" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="X" width="72.8" height="62" x="-661.7" y="360.9" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#W)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-589.3 361.3c-16.4 14.1-55.4 47.2-71.9 61.2" mask="url(#X)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="Y" width="77.5" height="66.3" x="-664.7" y="362.1" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="Z" width="77.5" height="66.3" x="-664.7" y="362.1" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#Y)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-587.7 362.5c-16.7 13.9-60.1 51.4-76.7 65.5" mask="url(#Z)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="aa" width="77.9" height="66.6" x="-663.5" y="363.4" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="ab" width="77.9" height="66.6" x="-663.5" y="363.4" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#aa)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-586 363.8c-16.4 14.4-60.6 51.5-77.1 65.8" mask="url(#ab)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="ac" width="75.9" height="64.7" x="-659.8" y="364.6" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="ad" width="75.9" height="64.7" x="-659.8" y="364.6" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#ac)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-584.3 365c-16.6 14.2-58.4 49.6-75.1 63.8" mask="url(#ad)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="ae" width="72.8" height="62" x="-667.5" y="355.1" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="af" width="72.8" height="62" x="-667.5" y="355.1" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#ae)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-595.1 355.5c-16.4 14.1-55.4 47.2-71.9 61.2" mask="url(#af)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="ag" width="77.5" height="66.3" x="-670.5" y="356.3" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="ah" width="77.5" height="66.3" x="-670.5" y="356.3" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#ag)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-593.5 356.8c-16.7 13.9-60.1 51.4-76.7 65.5" mask="url(#ah)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="ai" width="77.9" height="66.6" x="-669.3" y="357.6" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="aj" width="77.9" height="66.6" x="-669.3" y="357.6" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#ai)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-591.8 358c-16.4 14.4-60.6 51.5-77.1 65.8" mask="url(#aj)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="ak" width="75.9" height="64.7" x="-665.6" y="358.8" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="al" width="75.9" height="64.7" x="-665.6" y="358.8" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#ak)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-590.1 359.2c-16.6 14.2-58.4 49.6-75.1 63.8" mask="url(#al)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="am" width="72.8" height="62.1" x="-620.2" y="400.5" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="an" width="72.8" height="62.1" x="-620.2" y="400.5" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#am)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-547.8 400.9c-16.8 14.1-55.4 47.1-71.9 61.2" mask="url(#an)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="ao" width="75.6" height="64.6" x="-621.6" y="401.9" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="ap" width="75.6" height="64.6" x="-621.6" y="401.9" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#ao)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-546.3 402.3c-16.7 14.1-58.3 49.6-74.8 63.8" mask="url(#ap)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="aq" width="72.9" height="62.4" x="-617.6" y="403.5" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="ar" width="72.9" height="62.4" x="-617.6" y="403.5" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#aq)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-545.1 403.9c-16.4 14-55.6 47.5-72.1 61.6" mask="url(#ar)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="as" width="68.3" height="58.3" x="-611.9" y="405.3" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="at" width="68.3" height="58.3" x="-611.9" y="405.3" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#as)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-544.1 405.7c-16.2 13.8-51.3 43.6-67.5 57.5" mask="url(#at)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="au" width="67.2" height="57.5" x="-609.9" y="407.1" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="av" width="67.2" height="57.5" x="-609.9" y="407.1" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#au)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-543.1 407.6c-15.7 13.6-50.4 43.3-66.4 56.7" mask="url(#av)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="aw" width="61.1" height="52.4" x="-603" y="409.2" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="ax" width="61.1" height="52.4" x="-603" y="409.2" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#aw)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-542.3 409.6c-15.6 13-44.8 38.4-60.2 51.5" mask="url(#ax)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="ay" width="59.8" height="51.2" x="-600.9" y="411.1" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="az" width="59.8" height="51.2" x="-600.9" y="411.1" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#ay)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-541.5 411.5c-15.2 12.5-43.9 37.8-59 50.4" mask="url(#az)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="aA" width="58.1" height="49.8" x="-598.4" y="413.2" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="aB" width="58.1" height="49.8" x="-598.4" y="413.2" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#aA)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-540.8 413.6c-14.4 12.5-42.7 36.6-57.2 49" mask="url(#aB)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="aC" width="65" height="55.7" x="-604.7" y="415.2" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="aD" width="65" height="55.7" x="-604.7" y="415.2" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#aC)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-540.1 415.6c-13.7 12.1-50.4 43.1-64.2 54.9" mask="url(#aD)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="aE" width="62.5" height="53.6" x="-601.6" y="417.4" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="aF" width="62.5" height="53.6" x="-601.6" y="417.4" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#aE)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-539.5 417.8c-13.2 10.9-48.7 41.6-61.7 52.7" mask="url(#aF)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="aG" width="60" height="51.2" x="-598.7" y="419.8" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="aH" width="60" height="51.2" x="-598.7" y="419.8" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#aG)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-539.1 420.2c-12.3 10.2-47 40.2-59.2 50.4" mask="url(#aH)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="aI" width="56.8" height="48.6" x="-595" y="422" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="aJ" width="56.8" height="48.6" x="-595" y="422" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#aI)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-538.6 422.4c-11.3 9.3-44.9 38.2-56 47.8" mask="url(#aJ)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="aK" width="46.9" height="40.3" x="-584.7" y="424.3" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="aL" width="46.9" height="40.3" x="-584.7" y="424.3" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#aK)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-538.2 424.8c-9.6 8.6-36.5 31.1-46.1 39.5" mask="url(#aL)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="aM" width="44" height="37.7" x="-581.4" y="426.6" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="aN" width="44" height="37.7" x="-581.4" y="426.6" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#aM)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-537.8 427c-8.8 7.4-34.5 29.3-43.2 36.9" mask="url(#aN)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="aO" width="41.5" height="35.7" x="-578.6" y="429" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="aP" width="41.5" height="35.7" x="-578.6" y="429" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#aO)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-537.4 429.4c-8.1 6.6-32.6 28.3-40.7 34.8" mask="url(#aP)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="aQ" width="39.5" height="33.9" x="-576.5" y="431.7" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="aR" width="39.5" height="33.9" x="-576.5" y="431.7" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#aQ)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-537.5 432.1c-7.3 6.3-31.3 26.8-38.6 33.1" mask="url(#aR)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="aS" width="35.3" height="30.4" x="-572.6" y="434.6" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="aT" width="35.3" height="30.4" x="-572.6" y="434.6" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#aS)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-537.7 435c-11.5 9.9-23 19.7-34.5 29.6" mask="url(#aT)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="aU" width="33.7" height="29.3" x="-571" y="437.3" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="aV" width="33.7" height="29.3" x="-571" y="437.3" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#aU)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-537.7 437.7c-5 4.2-27.9 24.2-32.8 28.4" mask="url(#aV)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="aW" width="32.3" height="28" x="-569.1" y="439.6" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="aX" width="32.3" height="28" x="-569.1" y="439.6" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#aW)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-537.3 440c-4.5 3.8-27 23.3-31.5 27.1" mask="url(#aX)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="aY" width="30.9" height="26.9" x="-566.9" y="441.5" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="aZ" width="30.9" height="26.9" x="-566.9" y="441.5" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#aY)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-536.4 441.9c-4 3.5-26.1 22.7-30.1 26.1" mask="url(#aZ)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="ba" width="29.8" height="25.9" x="-564.4" y="443" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="bb" width="29.8" height="25.9" x="-564.4" y="443" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#ba)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-535.1 443.4c-3.7 3.1-25.3 22-28.9 25.1" mask="url(#bb)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="bc" width="28" height="24.1" x="-561.2" y="444.4" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="bd" width="28" height="24.1" x="-561.2" y="444.4" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#bc)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-533.6 444.9c-3 2.6-24.2 20.7-27.2 23.3" mask="url(#bd)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="be" width="26.5" height="22.8" x="-558" y="445.7" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="bf" width="26.5" height="22.8" x="-558" y="445.7" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#be)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-531.9 446.1c-2.3 2-23.4 20-25.7 22" mask="url(#bf)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="bg" width="26.5" height="22.8" x="-556.6" y="447.1" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="bh" width="26.5" height="22.8" x="-556.6" y="447.1" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#bg)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-530.5 447.5c-2.3 2-23.4 20-25.7 22" mask="url(#bh)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="bi" width="26.5" height="22.8" x="-555.2" y="448.4" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="bj" width="26.5" height="22.8" x="-555.2" y="448.4" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#bi)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-529.1 448.9c-2.3 2-23.4 20-25.7 22" mask="url(#bj)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="bk" width="26.5" height="22.8" x="-553.8" y="449.8" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="bl" width="26.5" height="22.8" x="-553.8" y="449.8" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#bk)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-527.7 450.3c-2.3 2-23.4 20-25.7 22" mask="url(#bl)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="bm" width="26.5" height="22.8" x="-552.4" y="451.2" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="bn" width="26.5" height="22.8" x="-552.4" y="451.2" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#bm)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-526.3 451.6c-2.3 2-23.4 20-25.7 22" mask="url(#bn)"/>
      </g>
      <g transform="translate(19.862 13.812)">
        <defs>
          <filter id="bo" width="26.5" height="22.8" x="-551" y="452.6" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="bp" width="26.5" height="22.8" x="-551" y="452.6" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-597.1 398.5c-1 0-2 .4-2.7 1.1-.7.7-1.1 1.7-1.1 2.6l.3 49c0 2 1.7 3.7 3.7 3.7h4.8c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h21.3c.4 3.9 3.8 7 7.8 7 4.1 0 7.4-3.1 7.8-7h4.4c2.1 0 3.7-1.7 3.7-3.7v-22.4c0-.8-.3-1.5-.8-2l-4.5-4.5c-.1-.1-.1-.2-.2-.3l-5-10.4c-.6-1.1-1.7-1.9-3-1.9h-11.9v-7.5c0-2.1-1.7-3.7-3.7-3.7" filter="url(#bo)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".817" d="M-524.9 453c-2.3 2-23.4 20-25.7 22" mask="url(#bp)"/>
      </g>
      <path fill="none" stroke="#120C0E" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.596" d="M-562.7 471.7h22.9m14.6 0h4.8c1.6 0 2.9-1.3 2.9-2.9v-22.4c0-.5-.2-1-.6-1.4l-4.5-4.5c-.1-.1-.3-.3-.4-.5l-5-10.4c-.4-.8-1.3-1.4-2.2-1.4h-12.7m-19.7 43.5c0 3.9-3.2 7-7.1 7-3.9 0-7.1-3.2-7.1-7 0-3.9 3.2-7 7.1-7 3.9 0 7.1 3.1 7.1 7z"/>
      <path fill="none" stroke="#120C0E" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.596" d="M-525.6 471.7c0 3.9-3.2 7-7.1 7-3.9 0-7.1-3.2-7.1-7 0-3.9 3.2-7 7.1-7 3.9 0 7.1 3.1 7.1 7zm7.8-14.1H-585m42.1-.1v-37.6c0-1.6-1.3-2.9-2.9-2.9h-36.7c-1.6 0-2.9 1.3-2.9 2.8.1 9.5.2 39.6.3 49 0 1.6 1.3 2.8 2.9 2.8h5.2"/>
      <path fill="#BCE5FF" d="M-550.5 437.6l-4.1 2.3-6.1-3.8 6.2-3.6 4 2.4c.3.2.8.1 1-.2.2-.3.1-.8-.2-1l-3.3-2 2.9-1.7c.3-.2.4-.7.2-1-.2-.3-.6-.4-.9-.2l-2.9 1.7.1-3.9c0-.4-.3-.7-.7-.7-.4 0-.7.3-.7.7l-.1 4.8-6.1 3.6v-7.3l4-2.4c.3-.2.4-.7.2-1s-.7-.4-1-.2l-3.2 2v-3.4c0-.4-.3-.7-.7-.7-.4 0-.7.3-.7.7v3.4l-3.2-2c-.3-.2-.8-.1-1 .2-.2.3-.1.8.2 1l4 2.4v7.3l-6-3.8.1-4.8c0-.4-.3-.7-.7-.7-.4 0-.7.3-.7.7v3.9l-2.8-1.8c-.3-.2-.8-.1-1 .2-.2.3-.1.8.2 1l2.9 1.8-3.4 1.9c-.3.2-.5.6-.3 1 .2.3.6.5 1 .3l4.1-2.3 6.1 3.8-6.2 3.6-4-2.4c-.4-.2-.8-.1-1 .3-.2.3-.1.7.2.9l3.3 2-2.9 1.7c-.3.2-.5.6-.3 1 .2.3.6.5 1 .3l2.9-1.7-.1 3.9c0 .4.3.7.7.7.4 0 .7-.3.7-.7l.1-4.8 6.1-3.6v7.3l-4 2.4c-.3.2-.5.6-.3 1 .2.3.6.5 1 .3h.1l3.2-2v3.4c0 .4.3.7.7.7.4 0 .7-.3.7-.7v-3.4l3.2 2c.3.2.8.1 1-.2.2-.3.1-.8-.2-1l-4-2.4v-7.3l6 3.8-.1 4.8c0 .4.3.7.7.7.4 0 .7-.3.7-.7v-3.9l2.8 1.8c.3.2.8.1 1-.2.2-.3.1-.8-.2-1l-2.9-1.8 3.4-1.9c.3-.2.5-.6.3-1-.2-.6-.7-.7-1-.5"/>
      <path fill="#020303" d="M-553 438.6l-4.1 2.3-6.1-3.8 6.2-3.6 4 2.4c.3.2.8.1 1-.2.2-.3.1-.8-.2-1l-3.3-2 2.9-1.7c.3-.2.4-.7.2-1-.2-.3-.6-.4-.9-.2l-2.9 1.7.1-3.9c0-.4-.3-.7-.7-.7-.4 0-.7.3-.7.7l-.1 4.8-6.1 3.6v-7.3l4-2.4c.3-.2.4-.7.2-1-.2-.3-.7-.4-1-.2l-3.2 2v-3.4c0-.4-.3-.7-.7-.7-.4 0-.7.3-.7.7v3.4l-3.2-2c-.3-.2-.8-.1-1 .2-.2.3-.1.8.2 1l4 2.4v7.3l-6-3.8.1-4.8c0-.4-.3-.7-.7-.7-.4 0-.7.3-.7.7v3.9l-2.8-1.8c-.3-.2-.8-.1-1 .2-.2.3-.1.8.2 1l2.9 1.8-3.4 1.9c-.3.2-.5.6-.3 1 .2.3.6.5 1 .3l4.1-2.3 6.1 3.8-6.2 3.6-4-2.4c-.4-.2-.8-.1-1 .3-.2.3-.1.7.2.9l3.3 2-2.9 1.7c-.3.2-.5.6-.3 1 .2.3.6.5 1 .3l2.9-1.7-.1 3.9c0 .4.3.7.7.7.4 0 .7-.3.7-.7l.1-4.8 6.1-3.6v7.3l-4 2.4c-.4.2-.5.6-.3 1s.6.5 1 .3h.1l3.2-2v3.4c0 .4.3.7.7.7.4 0 .7-.3.7-.7v-3.4l3.2 2c.3.2.8.1 1-.2.2-.3.1-.8-.2-1l-4-2.4v-7.3l6 3.8-.1 4.8c0 .4.3.7.7.7.4 0 .7-.3.7-.7v-3.9l2.8 1.8c.3.2.8.1 1-.2.2-.3.1-.8-.2-1l-2.9-1.8 3.4-1.9c.3-.2.5-.6.3-1-.2-.6-.7-.7-1-.5"/>
    </svg>
  </div>
  <div>
    <p class="chronofresh__title">Livraison Chronofresh 24h</p>
    <p class="chronofresh__text">A partir de 100 â¬ d'achat la livraison est offerte</p>
  </div>
</div>

```

*/

.chronofresh {
  display: flex;
  align-items: center;
  padding: rem(18) rem(30);
  background: $lightblue;

  svg {
    margin-right: rem(30);
    width: 58px; height: 65px;
  }

  &--clean {
    background:transparent;
    padding:0;
    svg {
      margin: 0;
    }
  }
}

.chronofresh__title {
  margin: 0 0 rem(5);
  font-size: rem(18);
  font-weight: $font-weight-normal;
}

.chronofresh__text {
  margin: 0;
  font-size: rem(12);
}
