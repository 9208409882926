// ==========================================================================
// Microdata Soulard dans footer
// ==========================================================================

.soulard-card__img {
  width: rem(120);
  margin: 0 0 rem(25);
  img {
    width: 100%;
  }
}

.soulard-card__address-link {
  color: $body-color;
  &:hover,
  &:focus,
  &:active {
    color: inherit;
    text-decoration: none;
  }
}

@include media-breakpoint-down(sm) {
  .border-bottom-gray-mobile {
    border-bottom: 1px solid $gray-1;
  }
}

@include media-breakpoint-up(md) {
  .border-right-gray-md {
    border-right: 1px solid $gray-1;
  }
}

.soulard-card__store {
  font-weight: $font-weight-medium;
  line-height: 1.7;
  p {
    line-height: 1.7;
  }
}
