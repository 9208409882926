// ==========================================================================
// FAQ
// ==========================================================================

.list-faq {
  margin: 0;
  padding: 0;
  list-style-type: none;

  &__head-title {
    cursor: pointer;
    display: flex;
    align-items: first baseline;
    justify-content: flex-start;
    margin-top: rem(15);

    &:first-of-type {
      margin-top: 0;
    }

    &::after {
      content: "";
      display: block;
      height: 0;
      width: 0;
      border-style: solid;
      border-width: 17px 10px 0 10px;
      border-color: #e5b122 transparent transparent;
      margin-left: rem(30);
    }
  }

  &__item {
    height: 0;
    visibility: hidden;
    transition: height ease 0.4s;

    &.active {
      visibility: visible;
      margin-bottom: rem(15);

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.list-faq__title {
  font-size: rem(22);

  &::before {
    content: "";
    display: inline-block;
    width: rem(15);
    height: rem(15);
    margin-right: rem(10);

    background: url("#{$path-img}/svg/icon-list.svg") no-repeat center / contain;
  }
}

.yellow_underline {
  position: relative;
  background-image: linear-gradient(to top, #e5b122 30%, transparent 0);
  background-repeat: repeat-y;
  color: inherit;
  text-decoration: none;
  white-space: nowrap;
  transition: all ease 250ms;
  z-index: 1;
}
.yellow_underline:hover {
  text-decoration: none;
}
.yellow_underline::before {
  content: "";
  display: inline-block;
  height: 30%;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  transition: height ease 100ms;
  z-index: -1;
  background: #e5b122;
}

.yellow_underline:hover::before,
.yellow_underline:focus::before,
.yellow_underline:active::before {
  height: 75%;
}
