// ==========================================================================
// DiffÃ©rents menus du site
// ==========================================================================

/*doc
---
title: Menus
name: Menus
category: Modules
---

```html_example
<!-- Main menu -->
<nav class="main-menu">
  <ul>
    <li><a href="">Foies gras de canard</a></li>
    <li><a href="">Viandes de canard</a></li>
    <li><a href="">SpÃ©cialitÃ©s de canard</a></li>
    <li><a href="">Produits du terroir</a></li>
  </ul>
</nav>

<!-- Utilities menu  -->
<nav class="utilities-menu">
  <ul>
    <li>
      <a href="" class="utilities-menu__link">
        <span class="utilities-menu__img"><svg class="icon-user" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.2 19.2"><g fill="none"><path stroke="#C40040" stroke-width=".8" d="M5.6 11.5v.8c0 .4-.3.8-.7 1l-.9.3-2.2.7c-.8.3-1.4 1-1.4 1.9v1.6c0 .6.4 1 1 1h12.4c.6 0 1-.4 1-1v-1.6c0-.9-.6-1.6-1.4-1.9l-3.1-1c-.4-.1-.7-.5-.7-1v-.8"/><path stroke="#C40040" stroke-width=".8" d="M4.1 13.6l-2.3-1.2c-.3-.2-.5-.5-.5-.9C1.3 4.1 3.5.4 7.9.4s6.4 3.7 6 11.2c0 .3-.2.7-.5.8l-2.2 1.2"/><path stroke="#C40040" stroke-width=".8" d="M10.2 4.6c.4 1.8.9 2.8 1.7 3.1 1.1.4-.8 4.6-4.5 4.6-2.4 0-3.8-1.9-4.1-5.8l6.9-1.9z"/><path d="M4.1 13.7c1.4 1.8 2.6 2.7 3.5 2.7s2.1-.9 3.5-2.7"/></g></svg></span>
        <span class="utilities-menu__text">Mon compte</span>
      </a>
    </li>
    <li>
      <a href="" class="utilities-menu__link logged">
        <span class="utilities-menu__img"><svg class="icon-user" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.2 19.2"><g fill="none"><path stroke="#C40040" stroke-width=".8" d="M5.6 11.5v.8c0 .4-.3.8-.7 1l-.9.3-2.2.7c-.8.3-1.4 1-1.4 1.9v1.6c0 .6.4 1 1 1h12.4c.6 0 1-.4 1-1v-1.6c0-.9-.6-1.6-1.4-1.9l-3.1-1c-.4-.1-.7-.5-.7-1v-.8"/><path stroke="#C40040" stroke-width=".8" d="M4.1 13.6l-2.3-1.2c-.3-.2-.5-.5-.5-.9C1.3 4.1 3.5.4 7.9.4s6.4 3.7 6 11.2c0 .3-.2.7-.5.8l-2.2 1.2"/><path stroke="#C40040" stroke-width=".8" d="M10.2 4.6c.4 1.8.9 2.8 1.7 3.1 1.1.4-.8 4.6-4.5 4.6-2.4 0-3.8-1.9-4.1-5.8l6.9-1.9z"/><path d="M4.1 13.7c1.4 1.8 2.6 2.7 3.5 2.7s2.1-.9 3.5-2.7"/></g></svg></span>
        <span class="utilities-menu__text">Mon compte</span>
      </a>
    </li>
    <li>
      <a href="" class="utilities-menu__link">
        <span class="utilities-menu__img"><svg class="icon-star" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.9 18.1"><path fill="#C40040" d="M9.8 14.8l4.8 2.5h.1v-.1l-.8-5.3c0-.3 0-.6.3-.8l3.9-3.8v-.1H18l-5.4-.8c-.3 0-.5-.2-.7-.5L9.5 1s-.1-.1-.1 0L6.9 5.9c-.1.2-.4.4-.7.5l-5.3.7c-.1 0-.1.1-.1.1v.1l3.9 3.8c.2.2.3.5.2.8L4 17.3c0 .1 0 .1.1.1h.1L9 14.9c.2-.2.6-.2.8-.1zm-.4.7L4.6 18c-.4.2-1 .1-1.2-.4-.1-.2-.1-.4-.1-.6l.9-5.4v-.1l-4-3.6c-.3-.4-.3-.9 0-1.3.1-.1.3-.2.5-.3l5.4-.8s.1 0 .1-.1L8.6.5c.2-.4.8-.6 1.2-.4.2.1.3.2.4.4l2.4 4.9s0 .1.1.1l5.4.8c.5.1.8.5.8 1 0 .2-.1.4-.3.5l-3.9 3.8v.1l.9 5.4c.1.5-.2 1-.7 1-.2 0-.4 0-.6-.1l-4.9-2.5z"/></svg></span>
        <span class="utilities-menu__text">Mes envies</span>
      </a>
    </li>
    <li>
      <a href="" class="utilities-menu__link">
        <span class="utilities-menu__img"><svg class="icon-cart" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.9 18.2"><path fill="#C40040" d="M1.4 6.5c-.4 0-.7.3-.7.7v1c0 .4.3.7.7.7h18c.4 0 .7-.3.7-.7v-1c0-.4-.3-.7-.7-.7h-18zm18.2 3.2l-1.1 5.8c-.3 1.6-1.7 2.8-3.3 2.8H5.8c-1.6 0-3-1.1-3.3-2.7l-1.3-6C.5 9.4 0 8.8 0 8.1V7c0-.8.7-1.5 1.5-1.5h1.6s0-.1.1-.1l5-5.2c.2-.2.4-.1.6 0 .1.2.1.4 0 .5L4.2 5.4h12.7L12.2.7c-.2-.2-.2-.4 0-.6s.4-.2.6 0l5.1 5.2.1.1h1.4c.8 0 1.5.7 1.5 1.5v1c0 1.1-.6 1.8-1.3 1.8zm-.8 0H1.9l1.2 5.7c.3 1.2 1.3 2 2.5 2H15c1.2 0 2.3-.9 2.6-2.1l1.2-5.6z"/></svg></span>
        <span class="utilities-menu__text">Mon panier</span>
      </a>
    </li>
  </ul>
</nav>

<!-- Inline simple menu -->
<div style="padding: 10px;">
  <nav class="simple-menu simple-menu--pink">
    <ul>
      <li><a href="">Mentions lÃ©gales</a></li>
      <li><a href="">Conditions gÃ©nÃ©rales de vente</a></li>
      <li><a href="">Plan du site</a></li>
      <li><a href="">DonnÃ©es personnelles et cookies</a></li>
    </ul>
  </nav>
</div>

<div style="background:#1e1A0d; padding: 10px;">
  <nav class="simple-menu">
    <ul>
      <li><a href="">Nos offres spÃ©ciales</a></li>
      <li><a href="">Recettes et conseils</a></li>
      <li><a href="">La Maison Ernest Soulard</a></li>
    </ul>
  </nav>
</div>

```

*/
%pl-menus {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

%pl-main-menu-link {
  display: block;
  color: $darkbrown;
  font-size: rem(13);
  letter-spacing: 0.2px;
  text-transform: uppercase;
}

$padding-submenuitem-mobile: rem(20);

// .header__menus-container styles (pour positionnement, voir _header.scss)
.header__menus-container {
  padding: 0;
  width: 0;
  overflow: hidden;

  background: $white;
  border-top: 2px solid $gray-1;

  line-height: 3;
  text-align: left;
}

.header__menusÂ  {
  text-align: right;
}
// #32904 : md Ã  lg
@media (hover: hover) {
  @include media-breakpoint-up(lg) {
    .header__menus {
      margin-top: rem(25);
      padding-bottom: 0;
      text-align: left;
    }

    .header__menus-container {
      padding-top: rem(15);
      padding-right: 0;
      padding-bottom: 0;
      padding-left: 0;
      align-items: stretch;
      width: initial;
      overflow: initial;

      border-top: none;

      line-height: normal;
      word-break: normal;
    }
  }
}

/* == Main menu */
.main-menu {
  li {
    > a {
      @extend %pl-main-menu-link;
      &:hover,
      &:focus,
      &:active {
        color: $pink;
      }
    }
  }
}

.main-menu__list {
  @extend %pl-menus;
  padding-right: rem(15);
}

.main-menu__item {
  display: block;
  &.main-menu__item--has-submenu {
    > a {
      position: relative;
      display: block;

      font-weight: $font-weight-medium;
      &::after {
        content: "";
        display: inline-block;
        position: absolute;
        right: 0;
        top: 50%;
        width: rem(10);
        height: rem(10);
        transform: translateY(-50%) rotate(-90deg);

        background: url("#{$path-img}/svg/icon-caret.svg") no-repeat center /
          contain;
      }
    }
  }
}

// #32904 : md Ã  lg
@media (hover: hover) {
  @include media-breakpoint-up(lg) {
    .main-menu__list {
      display: flex;
      justify-items: flex-end;
      text-align: center;
    }
    .main-menu__item {
      display: inline-block;

      text-align: center;

      > a {
        padding: 0 rem(10) rem(15);
      }

      &.main-menu__item--has-submenu {
        > a {
          &::after {
            display: none;
          }
        }
      }
    }
  }
}

@media (hover: hover) {
  @include media-breakpoint-up(xl) {
    .main-menu__list {
      text-align: left;
    }
  }
}

/* == sub menu */
.sub-menu {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 100%;
  max-width: 0;
  overflow: hidden;
  transition: all ease 100ms;

  background: $white;

  z-index: 1;
  .main-menu__item.active & {
    left: 0;
    right: 0;
    max-width: 100%;
  }
}

.sub-menu__link {
  padding: rem(20) 0 0;

  font-weight: $font-weight-base;
  line-height: 2;
}

// #32904 : md Ã  lg
@media (hover: hover) {
  @include media-breakpoint-up(lg) {
    .sub-menu {
      top: 0;
      right: 0;
      bottom: initial;
      left: 0;
      z-index: -1;
      margin-top: -2px;
      transition: none;

      border-top: 2px solid $gray-1;
      border-bottom: 2px solid $gray-1;
      .main-menu__item.active & {
        bottom: initial;
        max-width: initial;
        overflow: initial;
        margin-top: 0;
      }
    }
  }
}

.submenu-container__encapsulator {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;

  overflow-y: scroll;
}

.sub-menu__list {
  margin-bottom: rem(25);
}

.submenu-container__image-link {
  flex-basis: rem(80);
  order: 2;

  border-top: 2px solid $gray-1;

  .image-link {
    height: 100%;
  }
}

// #32904 : md Ã  lg
@media (hover: hover) {
  @include media-breakpoint-up(lg) {
    .submenu-container__encapsulator {
      flex-direction: row;
      justify-content: space-between;
    }
    .sub-menu__list {
      position: relative;
      margin-left: rem(20);
      flex: 1 0 35%;
      margin-bottom: 0;
      padding: 0;
      margin-bottom: rem(21);
      margin-bottom: rem(21);
    }
    .submenu-container__cross-product {
      position: absolute;
      right: 0;
      top: 0;
      bottom: rem(20);
      width: 50%;
      padding-top: rem(10);
      padding-right: 3em;

      transition: opacity ease 200ms;
      opacity: 0;

      border-left: 2px solid $gray-1;
      &.active {
        opacity: 1;
        transition: opacity ease 200ms;
        z-index: 1;
      }

      .cross-article__illu {
        img {
          object-fit: scale-down;
          object-position: top;
        }
      }
    }
    .submenu-container__image-link {
      flex: 0 0 265px;
      min-height: rem(315);
      order: unset;

      border-top: none;
    }
  }
}

@media (hover: hover) {
  @include media-breakpoint-up(lg) {
    .submenu-container__cross-product {
      width: 60%;
    }
  }
}

.sub-menu__list {
  list-style-type: none;
}

// #32904 : md Ã  lg
@media (hover: hover) {
  @include media-breakpoint-up(lg) {
    .sub-menu__item {
      text-align: left;
      padding: 0;
      & + & {
        //margin-top: rem(20);
      }
    }

    .sub-menu__link {
      padding: rem(15) rem(30);
      width: 40%;

      font-weight: $font-weight-medium;
      line-height: 1;
      &:hover,
      &:focus,
      &:active {
        background: url("#{$path-img}/svg/icon-submenu.svg") no-repeat left
          center / rem(15);

        font-weight: $font-weight-bold;
        text-decoration: none;
      }
    }
  }
}

@media (hover: hover) {
  @include media-breakpoint-up(lg) {
    .sub-menu__link {
      width: 40%;
    }
  }
}

.sub-menu__button {
  @extend %pl-main-menu-link;
  width: 100%;
  position: relative;
  padding-top: $padding-submenuitem-mobile;
  padding-bottom: $padding-submenuitem-mobile;

  background: transparent;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 2px solid $gray-1;

  font-weight: $font-weight-medium;
  min-height: rem(80); /* fix ios safari #32614 */
  text-align: left;
  &::before {
    content: "";
    display: inline-block;
    position: absolute;
    left: rem(20);
    top: 50%;
    width: rem(10);
    height: rem(10);
    transform: translateY(-50%) rotate(90deg);

    background: url("#{$path-img}/svg/icon-caret.svg") no-repeat center /
      contain;
  }
}

// #32904 : md Ã  lg
@media (hover: hover) {
  @include media-breakpoint-up(lg) {
    .sub-menu__button {
      display: none;
      &::before {
        display: none;
      }
    }
  }
}

/* == Utilities menu */
.utilities-menu {
  ul {
    display: flex;
    justify-content: space-between;
    @extend %pl-menus;
  }
  li {
    display: inline-block;
  }
  span {
    vertical-align: bottom;
  }

  &--simple {
    .utilities-menu {
      &__link {
        width: initial;
        height: initial;
      }

      &__img {
        display: inline-block;
        width: rem(19);
        height: rem(19);
        margin-right: rem(5);
      }

      &__text {
        display: inline;
        font-size: rem(13);
      }
    }
  }
}
.utilities-menu__link {
  color: $darkbrown;
  font-size: rem(14);
  &:hover,
  &:focus,
  &:active,
  &.logged {
    color: $pink;
    text-decoration: none;
  }
}

.utilities-menu__amount {
  display: none;
}

.utilities-menu__loyalty {
  display: none;
}

.utilities-menu__img {
  display: block;
  position: relative;
  svg {
    height: 100%;
  }
}

.utilities-menu__cart-number {
  position: absolute;
  top: 0;
  right: 0;
  min-width: rem(10);
  min-height: rem(10);
  padding: 0 rem(1);
  transform: translate(100%, -50%);

  background: $pink;
  border-radius: rem(5);

  color: white;
  font-size: rem(8);
  text-align: center;
}

.utilities-menu__text {
  display: none;
}

// #32904 : md Ã  lg
@media (hover: hover) {
  @include media-breakpoint-up(lg) {
    .utilities-menu {
      ul {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: rem(20);
      }
      a:hover,
      a:focus,
      a:active {
        text-decoration: none;
      }
      li + li {
        margin-left: rem(30);
      }

      &--simple {
        ul {
          justify-content: flex-start;
        }
      }
    }
    .utilities-menu__link {
      font-size: rem(13);
    }

    .utilities-menu__amount {
      display: block;
      border-left: rem(1) solid $pink;
      margin-left: rem(10);
      padding-left: rem(10);
      font-size: rem(13);
    }

    .utilities-menu__loyalty {
      display: block;
      margin-top: rem(5);
      font-size: rem(10);
    }

    .utilities-menu__img {
      display: inline-block;
      width: rem(19);
      height: rem(19);
    }
  }
}

@media (hover: hover) {
  @include media-breakpoint-up(lg) {
    .utilities-menu {
      a:hover,
      a:focus,
      a:active {
        .utilities-menu__text {
          text-decoration: underline;
        }
      }
    }
    .utilities-menu__text {
      display: inline-block;
      margin-left: rem(10);
    }

    .utilities-menu__link {
      &--checkout {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
    }
  }
}

@media (hover: hover) {
  @include media-breakpoint-up(xl) {
    .utilities-menu__link {
      &--checkout {
        flex-direction: row;
      }
    }
  }
}

.utilities-menu__text-container {
  display: none;
  @media (hover: hover) {
    @include media-breakpoint-up(lg) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.header__menus-container__utilities {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;

  border-top: 2px solid $gray-1;

  line-height: 4;
  .utilities-menu__img {
    display: inline-block;
    width: rem(19);
    height: rem(19);
  }
  .utilities-menu__text {
    display: inline-block;
  }

  @media (hover: hover) {
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
}

/* == Simple menu */
.simple-menu {
  ul {
    @extend %pl-menus;
  }
  li {
    display: block;
  }
  a {
    color: #fff;
    font-size: rem(12);
  }
}

.simple-menu--pink {
  a {
    color: $pink;
    font-size: rem(13);
    font-weight: $font-weight-normal;
  }
}

// #32904 : md Ã  lg
@media (hover: hover) {
  @include media-breakpoint-up(lg) {
    .simple-menu {
      li {
        display: inline-block;
      }
      li + li {
        margin-left: rem(40);
      }
    }
    .simple-menu--pink {
      li + li {
        margin-left: rem(20);
      }
    }
    .simple-menu--header {
      border-left: 2px solid $gray-0;
      padding-left: rem(15);
    }
  }
}

.account-menu__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

// Menu aside pages Mon compte
.account-menu__link {
  display: inline-block;

  color: $body-color;
  font-weight: $font-weight-medium;
  &:hover,
  &:focus,
  &:active {
    color: inherit;
  }
}

// #32904 : md Ã  lg
@media (hover: hover) {
  @include media-breakpoint-up(lg) {
    .account-menu__link {
      padding: rem(12) 0;
    }
  }
}

.special-menu {
  &__sub-list {
    padding-left: rem(20) !important;
  }

  &__item {
    line-height: 2;
    margin-bottom: rem(10);

    > a {
      text-transform: uppercase;
      font-weight: 500;

      &:hover,
      &:focus {
        color: $pink;
      }
    }
  }

  &__sub-item {
    line-height: 1.5;
    margin-bottom: rem(10);

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  @media (hover: hover) {
    @include media-breakpoint-up(lg) {
      height: 100%;

      &__list {
        display: flex;
        justify-content: flex-start;
        height: 100%;
        text-align: center;
      }

      &__item {
        position: relative;
        line-height: normal;
        margin-bottom: 0;
        display: flex !important;
        align-items: center;
        justify-content: center;
        padding-bottom: rem(15);

        > a {
          text-transform: none;
        }

        &:hover {
          .special-menu__sub-list {
            display: block;
          }
        }
      }

      &__sub-list {
        position: absolute;
        top: calc(100% + #{rem(2)});
        left: -50%;
        padding: rem(20) !important;
        display: none;
        background: $white;
        box-shadow: 0 rem(6) rem(10) 0 rgba(0, 0, 0, 0.16);
      }

      &__sub-item {
        margin-left: 0 !important;
        white-space: nowrap;
      }
    }
  }
}
