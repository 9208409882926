// ==========================================================================
// Forms
// ==========================================================================

/*doc
---
title: Formulaires
name: forms
category: Base CSS
---

```html_example
<form>
	<!-- Text -->
	<div class="form-group">
		<label class="label" for="input-text">Text:</label>
		<input type="text" id="input-text" class="form-control form-input">
		<small id="input-text" class="form-text text-muted">Help text</small>
	</div>

		<!-- Error -->
	<div class="form-group form-group--error">
		<label class="label" for="input-text">Input en erreur :</label>
		<input type="email" id="input-text" class="form-control form-input" required>
		<p class="form-text form-text--error">Error text</p>
		<div class="form-group__icon">
		  <svg class="icon-cross" xmlns="http://www.w3.org/2000/svg" width="15" height="15.1"><path d="M7.5 6.8L14.3 0l.7.8-6.8 6.7 6.8 6.8-.7.7-6.8-6.7-6.8 6.8-.7-.8 6.8-6.8L0 .8.7.1z" fill="#222" fill-rule="evenodd"/></svg>
		</div>
	</div>

  <!-- Validate -->
	<div class="form-group form-group--valid">
		<label class="label" for="input-text">Input validÃ© :</label>
		<input type="email" id="input-text" class="form-control form-input">
		<div class="form-group__icon">
		  <svg xmlns="http://www.w3.org/2000/svg" class="icon-checkmark" viewBox="0 0 15.6 12.8" width="15" height="15.1">
        <path fill="none" stroke="#FFF" stroke-width="2" d="M.8 5.2L6 11.3 14.8.6"/>
      </svg>
		</div>
	</div>


	<!-- Readonly -->
	<div class="form-group">
		<label for="input-readonly" class="label">Readonly:</label>
		<input type="text" class="form-control form-input" placeholder="Readonly input hereâ¦" readonly>
	</div>

	<!-- Email -->
	<div class="form-group">
		<label for="input-email" class="label">Email:</label>
		<input type="email" id="input-email" class="form-control form-input" placeholder="name@example.com">
	</div>

	<!-- Tel -->
	<div class="form-group">
		<label for="input-tel" class="label">Tel:</label>
		<input type="tel" id="input-tel" class="form-control form-input" pattern="^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$">
	</div>

	<!-- Select -->
	<div class="form-group">
		<label for="select" class="label">Select:</label>
		<select id="select" class="form-control select2-select">
			<option>1</option>
			<option>2</option>
			<option>3</option>
		</select>
	</div>

	<!-- Checkbox -->
	<div class="form-group">
		<div class="form-check">
		  <input type="checkbox" class="form-check-input" id="input-checkbox">
		  <label class="form-check-label" for="input-checkbox">Checkbox</label>
		</div>
	</div>

	<!-- Radio -->
	<div class="form-group">
		<div class="form-check">
		  <input type="radio" class="form-check-input" id="input-radio">
		  <label class="form-check-label" for="input-radio">Radio</label>
		</div>
	</div>

	<!-- File -->
	<div class="form-group">
		<label for="input-file">File:</label>
		<input type="file" id="input-file" class="form-control-file">
	</div>

	<!-- Textarea -->
	<div class="form-group">
		<label for="textarea">Textarea:</label>
		<textarea id="textarea" class="form-control form-textarea" rows="3"></textarea>
	</div>



	<button type="submit" class="btn btn-primary">Valider</button>
</form>
```

*/

/* == Color */
$inactive-color: $gray-1;
$inactive-text-color: $gray-3;
$active-color: $body-color;
$select-background: $white;
$error-color: $pink;

/* == Placeholders */
%pl-inactive-form-input {
  padding-right: 0px; padding-left: 0px;

  border-top: none; border-right: none; border-left: none;
  border-bottom: 2px solid $inactive-color;
  border-radius: 0px;

  color: $inactive-text-color;
  font-weight: 300;
}

%pl-sr-only {
  position: absolute;
  width: 1px; height: 1px;
  padding: 0;
  overflow: hidden;

  border: 0;
  clip: rect(0, 0, 0, 0);

  white-space: nowrap;
}

%pl-input-framed {
  margin-top: rem(15);
  padding-top: rem(15); padding-bottom: rem(15);
  border: 2px solid $inactive-color;
}


/* == Labels */
.label {
  margin-bottom: 0px;
  font-weight: 500;

  &__required {
    padding-left: rem(5);
  }
}

.text-striked {
  .form-check {
    position: relative;
    .strikedStock {
      content: "";
      height: rem(1);
      background-color: $gray-2;
      display: block;
      position:absolute;
      top:50%;
      //left:rem(10);
    }
  }
}

.form-group {
  position:relative;
}

/* == Validation */
.form-group--error {
  .form-input,
  .form-textarea {
    border-color: $black;
    background: none;
  }
}

.form-text--error {
  color: $error-color;
  font-size: rem(13);
}

.form-group__icon {
  position: absolute;
  left: 100%; top: ($font-size-base * $line-height-base ) +  $font-size-base;
  margin-left: rem(10);
  .form-group--error &{
    path {
      fill: $pink;
    }
  }
  .form-group--valid & {
    path {
      stroke: $green;
    }
  }
}




.form-group--search-form {
  margin: 0;
}

/* == Inputs text and text-like */
.form-input,
.coupon .form-control {
  @extend %pl-inactive-form-input;
  &:focus {
    border-color: $active-color;

    color: $active-color;
  }
  &:read-only {
    @extend %pl-inactive-form-input;
    background: none;
  }
  &:invalid {
    border-color: $gray-1;
  }
  &::-webkit-input-placeholder {
    color: $inactive-text-color;
  }
  &::-moz-placeholder {
    color: $inactive-text-color;
  }
  &:-ms-input-placeholder {
    color: $inactive-text-color;
  }
  &:-moz-placeholder {
    color: $inactive-text-color;
}
  &::placeholder {
    color: $inactive-text-color;
  }
  &.form-input--framed {
    @extend %pl-input-framed;
  }
}

.coupon .form-control {
  padding-left: rem(15); padding-right: rem(15);
  @extend %pl-input-framed;
}

/* == Inputs checkboxes & radio */
.form-check {
  line-height: 0.6;
  padding-left: 0;
  overflow: hidden;
}
.product__foot .form-check {
  border-radius: 15px;
}
.form-check-input {
  &:not(.badge-input) {
    @extend %pl-sr-only;
    & + label {
      vertical-align: middle;
      display: flex;
      line-height: rem(15);
      &:before {
        content: "";
        display: inline-block;
        width: 20px; height: 20px;
        margin-right: rem(15);

        border: 1px solid $inactive-color;
        vertical-align: text-bottom;
        flex-shrink: 0;
      }
    }
    &[type="radio"] + label {
      position: relative;
      &:before {
        border-radius: 10px
      }
    }
    &:checked + label {
      &:before {
        content: "";
        display: inline-block;

        border-color: $pink;
        background: $pink;
      }
    }
    &[type="checkbox"]:checked + label {
      &:before {
        background-image: url("#{$path-img}/svg/icon-checkmark.svg"); //url(../img/icon-checkmark.svg) pour guide de style

        background-position: center;
        background-repeat: no-repeat;
        background-size: 16px;
      }
    }
    &[type="radio"]:checked + label {
      &:after {
        content: "";
        display: inline-block;
        width: 8px; height: 8px;
        position: absolute;
        top: 6px; left: 6px;

        background: $white;
        border-radius: 4px;
      }
    }
  }

  &--shipping {
    &:not(.badge-input) {

      & + label {
        &:before {
          content: "";
          display: inline-block;
          border-color: $gray-4;
          background: $white;
        }
      }

      &:checked + label {
        &:before {
          border-color: $gray-4;
          background: $white;
        }
      }

      &[type="radio"]:checked + label {
        &:after {
          content: "";
          display: inline-block;
          width: 12px; height: 12px;
          position: absolute;
          left: 4px;
          top: auto;

          background: $pink;
          border-radius: 6px;
        }
      }
    }
  }

  &--red {
    &:not(.badge-input) {
      @extend %pl-sr-only;
      & + label {
        &::before {
          border-color: $pink;
        }
      }
    }
  }
}

.delivery_slot {
  margin-top: rem(15);
  column-count: 2;
  & label {
    font-weight: 300;
  }

  .form-check-label {
    margin-bottom: rem(10);
  }
}

// == Input password
.form-password {
  position: relative;
  &__button {
    position: absolute;
    border: none;
    background: transparent;
    right: 0;
    top: 0;
    padding: 0;
    width: rem(25);
    height: rem(25);
    &:hover {
      cursor: pointer;
    }
  }
}

// == Textarea
.form-textarea {
  border: 2px solid $inactive-color;
  border-radius: 0px;
  margin-top: rem(10);

  color: $inactive-text-color;
  font-weight: 300;
  &:focus {
    color: $body-color;
  }
}

// == Custom select
select + .select2-container {
  &.select2-container--open { // Le select container constamment Ã  l'Ã©cran
    background: $select-background;
    border-top: 1px solid $inactive-color;
    border-right: 1px solid $inactive-color;
    border-left: 1px solid $inactive-color;
    border-bottom: 2px solid transparent;
  }
}

.select2-container {
  width: 100% !important;
  position: relative;

  border: 1px solid transparent;
  border-bottom: 2px solid $inactive-color;
  border-radius: 0px;
  &.select2-container--open {
    border-top: none; border-right: none; border-left: none;
  }
  .select2-dropdown {
    background: $select-background;
    border-right: 1px solid $inactive-color;
    border-left: 1px solid $inactive-color;
    border-radius: 0px;
    &.select2-dropdown--above {
      border-top: 1px solid $inactive-color;
    }
    &.select2-dropdown--below {
      border-bottom: 1px solid $inactive-color;
      margin-bottom: 2px;
    }
  }
}
.select2-container--default .select2-selection--single {
  border: none;
  background: $select-background;
  border-radius: 0px;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  color: $active-color;
  background: $gray-2;
}

.select2-results__option {
  padding: 8px;
}


.legend {
  padding-bottom: rem(10);
  margin-bottom: rem(40);

  border-bottom: 2px solid $inactive-color;

  font-family: $font-family-sans-serif;
  font-size: $font-size-base;
  font-weight: $font-weight-medium;
}
