// ==========================================================================
// Product
// ==========================================================================

.product-presentation {
}

.product-presentation__title {
  padding: rem(15);
}
.product-presentation__description {
  padding: rem(15);
}
.product-presentation__slider {
  display: flex;
  flex-direction: column;
}

.product-presentation__main-image {
  padding-bottom: rem(5);
}

.product-presentation__main-video {
  position: relative;
  &::before {
    content: "";
    display: inline-block;
    width: rem(40); height: rem(40);
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);

    background: $white;
    border-radius: rem(20);
  }
  &::after {
    content: "";
    display: inline-block;
    width: 0; height: 0;
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
    margin-left: 3.5px;

    border-top: 5px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 7px solid black;
  }
}

.product-presentation__thumbnails {
  display: flex;
  justify-content: center;
  &.product-presentation__thumbnails--three-thumbnails {
    justify-content: space-between;
  }
}

.product-presentation__thumbnail {
  & + & {
    padding-left: rem(5);
  }
  &--video {
    background: $gray-1;
    width: rem(150);
    //height: rem(112);
    position: relative;
    &::before {
      content: "";
      display: inline-block;
      width: rem(40); height: rem(40);
      position: absolute;
      top: 50%; left: 50%;
      transform: translate(-50%, -50%);

      background: $white;
      border-radius: rem(20);
    }
    &::after {
      content: "";
      display: inline-block;
      width: 0; height: 0;
      position: absolute;
      top: 50%; left: 50%;
      transform: translate(-50%, -50%);
      margin-left: 3.5px;

      border-top: 5px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 7px solid black;
    }
  }
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@include media-breakpoint-up(md) {
  .product-presentation {
    overflow: hidden;
  }

  .product-presentation__title {
    width: 43%;
    padding: rem(0) rem(0) rem(0) rem(50);
  }
  .product-presentation__description {
    width: 43%;
    padding: rem(15) 0 rem(15) rem(50);
  }

  .product-presentation__slider {
    padding-top: rem(4);
    width: 57%;
    min-height: rem(450);
    flex-direction: column;
  }

  .product-presentation__main-image {
    padding-bottom: rem(0);
    display: flex;
    justify-content: center;
  }

  .product-presentation__thumbnails {
    flex-direction: row;
    justify-content: flex-start;
  }

  .product-presentation__thumbnail {
    & + & {
      padding-left: rem(0);
    }

    padding-top: rem(5);
  }
}

.reviews-section {
  background: url('#{$path-img}/ernest-soulard-reviews-bg.png') no-repeat center / cover;
}

.modal-dialog {
  padding: rem(30);
  background: $white;
  border-radius: 0px;
}

.modal-content {
  border: none;
}
.btn-share {
  &.active {
    svg * {
      fill: $pink;
      stroke: $pink;
    }
  }
}

.social-network {
  display: none;
  &.active {
    display: inline-block;
    z-index: 2;

    position: absolute;
    right:0;
    padding: rem(0) rem(20);

    background: $white;
    border: 1px solid $pink;
  }
  .social-network__btn {
    position: absolute;
    top: 0; right: 0;
    width: rem(20); height: rem(20);
    padding: 0;

    background: $pink;
    border: 1px solid $pink;
    & svg {
      position: absolute;
      top: 50%; left: 51%;
      transform: translate(-50%, -50%) scale(0.8);
    }
  }
}

.social-network__list {
  margin: 0; padding: 0;
  list-style-type: none;
}

.social-network__item {
  padding: rem(10) rem(10);

  text-align: center;
  & + & {
    border-top: 1px solid $gray-1;
  }
}

.social-network__link {
  color: $body-color;
  &:hover,
  &:focus,
  &:active {
    color: $pink;
  }
}

.ideal-for__item {
  flex: 0 1 25%;
}
