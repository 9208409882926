// ==========================================================================
// pagination
// ==========================================================================

.pagination-arrow {
  padding: rem(10);

  color: inherit;
  &:hover,
  &:focus,
  &:active {
    color: inherit;
  }
}

.pagination-arrow--disabled {
  opacity: 0.3;
}