// ==========================================================================
// Entry product
// ==========================================================================

/*doc
---
title: Entry
category: Shop
---

```html_example
<article class="product product-entry" style="width: 320px;">
  <div class="product__wrapper">
    <div class="product__head">
      <span class="product__badge">4Â°C</span>
    </div>

    <figure class="product__img">
      <img src="https://placehold.it/260x150" alt/>
    </figure>

    <div class="product__body">
      <h2 class="product__title">Foie gras de canard entier</h2>
      <p class="product__subtitle">Verrine Weck</p>

      <a href="" class="btn-entry">
        <svg class="icon-entry" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41 41"><circle class="icon-entry-circle" fill="none" stroke="#222" cx="20.5" cy="20.5" r="20"/><path class="icon-entry-arrow" fill="none" stroke="#222" d="M10.5 20.8h18.1m-4.3-4.3l5 4.3-5 4.3"/></svg>
      </a>
    </div>
  </div>
</article>
```

*/

.product-entry {
  padding: rem(20) rem(30) rem(30);
  background: $white;
  .word-bg & {
    background: transparent;
  }

  .product__body {
    padding-right: rem(80);

    .btn-entry {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}
