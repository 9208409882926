// ==========================================================================
// Cart product
// ==========================================================================

/*doc
---
title: Cart
category: Shop
---

#### In the cart panel

```html_example
<div class="product product-cart" style="width: 330px;">
  <figure class="product__img">
    <img src="https://via.placeholder.com/135x115" alt/>
  </figure>

  <div class="product__body">
    <h2 class="product__title">GÃ©siers confits</h2>
    <p class="product__subtitle">Verrine Weck</p>
    <p class="product__ref">RÃ©f : 0156880438</p>

    <ul class="list-inline">
      <li class="list-inline-item">
        <span class="badge badge-pill">500g</span>
      </li>
    </ul>

    <a href class="btn-delete">
      <svg class="icon-trash" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.7 17.5"><path fill="#222" d="M5.8 6.9c.2 0 .4.2.4.4v6.2c0 .2-.2.4-.4.4s-.4-.2-.4-.4V7.3c0-.2.2-.4.4-.4zm4 0c.2 0 .4.2.4.4v6.2c0 .2-.2.4-.4.4s-.4-.2-.4-.4V7.3c0-.2.2-.4.4-.4z"/><path fill="none" stroke="#222" stroke-width=".8" stroke-linecap="round" d="M.4 3.9h14.9m-1.2 0l-1.5 11.5c-.1 1-1 1.7-2 1.7H5.1c-1 0-1.8-.7-2-1.7L1.4 3.9m2.5-1.4L11.8.4"/></svg>
    </a>
  </div>

  <div class="product__foot">
    <div class="counter-container counter-container--pink" data-trigger="counter">
      <button type="button" class="btn counter-container__button" data-operation="remove">-</button>
      <span class="counter-container__number" data-operation="result">0</span>
      <button type="button" class="btn counter-container__button" data-operation="add">+</button>
    </div>
    <span class="product__price">8,10 â¬</span>
  </div>
</div>
```

#### In the cart page

```html_example
<div style="width:300px;">
  <div class="product product-cart">
    <figure class="product__img">
      <img src="https://via.placeholder.com/135x115" alt/>
    </figure>
    <div class="product__body">
      <h2 class="product__title">GÃ©siers confits</h2>
      <p class="product__subtitle">Verrine Weck</p>
      <p class="product__ref">RÃ©f : 0156880438</p>
    </div>
  </div>
</div>
```

*/

.product-cart {
  display: flex;
  flex-wrap: nowrap;

  .product__img {
    width: rem(135);
    margin-bottom: 0;
  }
  .product__body {
    width: calc(100% - 135px);
    margin-bottom: rem(20);

    .btn-delete {
      position: absolute;
      top: rem(13);
      right: 0;
    }
    .cart-table & {
      width: 100%;
    }
  }
  .product__subtitle {
    margin-bottom: rem(10);
  }
  .product__foot {
    width: 100%;
  }
  .product__ref {
    font-weight: $font-weight-normal;
    display: none;
  }
}

@include media-breakpoint-up(md) {
  .product-cart {
    .product__body {
      padding: rem(20) rem(30) 0 rem(20);
    }

    .product__ref {
      display: block;
    }
  }
}
