// ==========================================================================
// Footer
// ==========================================================================

.footer {
  font-size: rem(15);
}

// == Bandeau jaune
.reinsurance-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin: 2em 0 3em;
}

.reinsurance-list__item {
  & + & {
    margin-top: rem(50);
  }
}

@include media-breakpoint-up(md) {
  .reinsurance-list {
    flex-direction: row;
  }

  .reinsurance-list__item {
    & + & {
      margin-top: 0;
    }
  }
}

// == Bandeau blanc
.footer-title {
  margin-bottom: rem(20);
  font-family: $font-family-sans-serif;
  font-size: rem(15);
  font-weight: $font-weight-medium;
  text-transform: uppercase;
}

.footer-title--lowercase {
  margin: rem(30) 0 rem(15);
  font-size: rem(16);
  text-transform: none;
}

.list-information-footer {
  margin: 0;
}

.list-information-footer__item {
  margin-bottom: rem(10);
  a {
    font-size: rem(14);
  }
}

.footer-newsletter-form {
  input {
    font-size: rem(14);
  }
}

.list-rs__item {
  margin: 0;
  & + & {
    margin-left: rem(18);
    vertical-align: middle;
  }
}

.icon-rs--youtube {
  height: rem(18);
}

.icon-rs--facebook {
  height: rem(20);
}

.icon-rs--svg {
  height: rem(20);
}

// == Bandeau noir
.banner-footer {
  padding: rem(10) 0;

  background: $darkbrown;

  color: $white;
  font-size: rem(13);
  line-height: 1.75;
}

.banner-footer__list {
  margin: 0; padding: 0;
  list-style-type: none;

  line-height: 3;
  text-align: left;
}

.banner-footer__link-btn {
  border: none;
  background: none;
}

.banner-footer__link {
  color: $white;
  &:hover,
  &:focus,
  &:active {
    color: $white;
  }
}

.banner-footer__item {
  display: inline-block;
  margin-right: rem(30);

  &--payment {
    margin-left: 0;
    img {
      height: rem(30);
      width: auto;
      margin-right: rem(8);

      &:first-of-type {
        margin-left: rem(8);
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .banner-footer__item {
    margin-right: rem(30);

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.banner-footer__text {
  margin-left: rem(20);
}
