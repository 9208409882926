// ==========================================================================
// Edito
// ==========================================================================

.reinsurance-text {
  padding: 0 0 0 rem(110);

  background-repeat: no-repeat;
  background-size: rem(80) auto;
  background-position: top left;
  min-height: rem(90);
  margin: rem(30) 0;
}

.edito-image {
  display: flex;
  flex-direction: column;
  align-items: center;

  figcaption {
    align-self: flex-end;
    margin-top: rem(1);
    font-size: rem(12);
  }
}

figure {
  &.edito-image {
    margin-top: rem(20);
    margin-bottom: rem(20);
    margin-left: -($grid-gutter-width/2);
    margin-right: -($grid-gutter-width/2);
  }
}

@include media-breakpoint-up(sm) {
  figure {
    &.edito-image {
      position: relative;
      margin: rem(40) 0;
      img {
        max-width: 100%;
      }
    }
  }
}
