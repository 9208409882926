// ==========================================================================
// Functions
// ==========================================================================

/*----------  Conversion en rem  ----------*/
// Usage : `font-size: rem(14)`

@function rem($value) {
  @return ($value / $font-size-base-px) * 1rem;
}

/*----------  CrÃ©ation de classes utiles pour les fonts  ----------*/
// exemple : .fs-24
$font-sizes:
  12,
  13,
  14,
  16,
  18,
  20,
  24,
  25,
  40,
  45
;

$line-height:
  28,
  40
;

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $font-size in $font-sizes{
      .fs#{$infix}-#{$font-size} {
        font-size: rem($font-size) !important;
      }
    }
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $l-h in $line-height{
      .lh#{$infix}-#{$l-h} {
        line-height: rem($l-h) !important;
      }
    }
  }
}

/*----------  CrÃ©ation de classes utiles pour les sizes  ----------*/
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @each $prop, $abbrev in (width: w, height: h) {
      @each $size, $length in $sizes {
        .#{$abbrev}#{$infix}-#{$size} { #{$prop}: $length !important; }
      }
    }
  }
}

/*----------  CrÃ©ation de classes utiles pour les borders  ----------*/
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .border#{$infix}-top {      border-top: $border-width solid $border-color !important; }
    .border#{$infix}-right {    border-right: $border-width solid $border-color !important; }
    .border#{$infix}-bottom {   border-bottom: $border-width solid $border-color !important; }
    .border#{$infix}-left {     border-left: $border-width solid $border-color !important; }

    .border#{$infix}-top-0 {    border-top: 0 !important; }
    .border#{$infix}-right-0 {  border-right: 0 !important; }
    .border#{$infix}-bottom-0 { border-bottom: 0 !important; }
    .border#{$infix}-left-0 {   border-left: 0 !important; }

    .border#{$infix}-x {
      border-left: $border-width solid $border-color !important;
      border-right: $border-width solid $border-color !important;
    }

    .border#{$infix}-y {
      border-top: $border-width solid $border-color !important;
      border-bottom: $border-width solid $border-color !important;
    }
  }
}
