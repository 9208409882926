// ==========================================================================
// Column for shopping cart
// ==========================================================================

/*doc
---
title: Colonne Panier
name: columncart
category: Modules
---

```html_example
<div style="width: 500px; margin: 0 0 30px;">
  <aside class="aside-column ">
    <div class="aside-column__block aside-column__block--underline">
      <table class="cart-table">
        <caption>Total panier</caption>
        <tr>
          <th scope="row">Sous total (HT)</th>
          <td>103, 04 â¬</td>
        </tr>
        <tr>
          <th scope="row">TVA & autres taxes</th>
          <td>25,80 â¬</td>
        </tr>
        <tr>
          <th scope="row">Livraison Chronofresh</th>
          <td><span class="color--grey2">Offerte</span></td>
        </tr>
        <tr>
            <th scope="row">Total (TTC)</th>
            <td>128, 84 â¬</td>
        </tr>
      </table>
      <a href="" class="btn btn-hovereffect btn--block"><span>Valider ma commande</span></a>
    </div>

    <div class="aside-column__block">
      <form>
        <div class="form-group">
          <label class="label" for="code-promo">Code promo</label>
          <input id="code-promo" class="form-control form-input form-input--framed" type="text">
        </div>
      </form>
      <button class="btn btn-hovereffect btn--block disabled"><span>Appliquer le code</span></button>
    </div>
    <div class="aside-column__block">
      <a href="" class="simple-link">Offrez une carte cadeau Ã  votre entourage !</a>
    </div>
    <div class="aside-column__block">
      <p class="label">Nous acceptons</p>
      <ul class="logo-list">
        <li class="logo-list__item"><img src="../styleguide/img/paiement_mastercard.png" alt="Mastercard"></li>
        <li class="logo-list__item"><img src="../styleguide/img/paiement_paypal.png" alt="Paypal"></li>
        <li class="logo-list__item"><img src="../styleguide/img/paiement_visa.png" alt="Visa"></li>
      </ul>
    </div>
  </aside>
</div>

```

*/

.cart-table {
  width: 100%;
  font-size: rem(16);
  caption {
    caption-side: top;
    margin-bottom: rem(40);

    color: $darkbrown;
    font-family: $headings-font-family;
    font-size: rem(45);
  }
  tr {
    line-height: 1.8;
    &:last-child{
      border-top: 2px solid $gray-1;

      color: $pink;
      font-size: rem(20);
      th, td {
        padding-top: 1.5em;
      }

    }
    &:nth-last-child(2){
      th, td {
        padding-bottom: 2em;
      }
    }
    th {
      font-weight: 500;
    }
    td {
      font-weight: 500;

      &:last-child {
        text-align: right;
      }
    }
  }
}

.logo-list {
  margin: rem(15) 0 0; padding: 0;
  list-style-type: none;
  li {
    display: inline-block;
    padding-right: rem(15);
    img {
      width: auto; height: rem(17);
    }
  }
}

.logo-list__item {
  & + & {
    padding-left: rem(15);

    border-left: 1px solid $gray-1;
  }
  &.logo-list__item--larger {
    img {
      height: rem(25);
    }
  }
}

.cart-info{
  margin-top: 2rem;
}
