// ==========================================================================
// Bloc dÃ©couvrir la gamme
// ==========================================================================

/*doc
---
title: Bloc Cross selling
name: crossblocks
category: Blocs
---

```html_example
<div style="width: 320px; margin: 0 0 30px;">
  <article class="product cross-article">
    <p class="cross-article__theme">PrÃ©paration</p>
    <figure class="cross-article__illu">
      <img src="https://placehold.it/270x320" alt/>
    </figure>
    <div class="cross-article__body">
      <a href="" class="cross-article__link">
        <h2 class="cross-article__title">Foie gras de canard entier</h2>
        <p class="cross-article__text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean imperdiet massa at urna commodo, quis venenatis risus condimentum. Ut a malesuada ex. </p>

        <div href="" class="btn-cross-article btn-entry">
          <svg class="icon-entry" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41 41"><circle class="icon-entry-circle" fill="none" stroke="#222" cx="20.5" cy="20.5" r="20"/><path class="icon-entry-arrow" fill="none" stroke="#222" d="M10.5 20.8h18.1m-4.3-4.3l5 4.3-5 4.3"/></svg>
        </div>
      </a>
    </div>
  </article>
</div>
<div style="width: 590px; margin: 0 0 30px;">
  <article class="product cross-article">
    <div class="cross-article__illu cross-article__video-container">
      <video
          id="video-ju"
          class="video-js"
          data-setup='{
            "controls": true,
            "autoplay": false,
            "preload": "auto",
            "responsive": false
          }'
          poster="https://vjs.zencdn.net/v/oceans.png">
        <source src="https://vjs.zencdn.net/v/oceans.mp4" type="video/mp4"></source>
        <source src="https://vjs.zencdn.net/v/oceans.webm" type="video/webm"></source>
        <source src="https://vjs.zencdn.net/v/oceans.ogv" type="video/ogg"></source>
        <p class="vjs-no-js">
          To view this video please enable JavaScript, and consider upgrading to a web browser that
          <a href="http://videojs.com/html5-video-support/" target="_blank">
            supports HTML5 video
          </a>
        </p>
      </video>
    </div>

    <div class="cross-article__body">
      <a href="" class="cross-article__link">
        <h2 class="cross-article__title">Foie gras de canard entier</h2>
        <p class="cross-article__text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean imperdiet massa at urna commodo, quis venenatis risus condimentum. Ut a malesuada ex. </p>
        <div href="" class="btn-cross-article btn-entry">
          <svg class="icon-entry" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41 41"><circle class="icon-entry-circle" fill="none" stroke="#222" cx="20.5" cy="20.5" r="20"/><path class="icon-entry-arrow" fill="none" stroke="#222" d="M10.5 20.8h18.1m-4.3-4.3l5 4.3-5 4.3"/></svg>
        </div>
      </a>
    </div>
    <a href="#" class="btn btn--wider btn-hovereffect cross-article__want-more-btn"><span>Nos idÃ©es recettes</span></a>
  </article>

</div>



```

*/
.cross-article {
  position: relative;
}

.cross-article__link {
  display:inline-block;
  color: $product-title-color;
  &:hover,
  &:focus,
  &:active {
    color: $darkbrown;
    text-decoration: none;
    .btn-cross-article {
      .icon-entry-circle {
        fill: $darkbrown; // @todo use variable
      }
      .icon-entry-arrow {
        stroke: $white; // @todo use variable
      }
    }
  }
}

.cross-article__theme {
  display: inline-block;
  position: absolute;
  top: 0; right: 0;
  padding: rem(5) rem(15);

  background: #FFF;

  color: $body-color;
  font-size: rem(14);
  font-weight: $font-weight-medium;
  text-transform: uppercase;
}

.cross-article__body {
  position: relative;
  margin-top: rem(20);
  padding-right: rem(80);
}
.cross-article__illu {
  margin: 0;
  height: rem(320);
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &--cover {
    img {
      object-fit: cover;
    }
  }
}

.cross-article__title {
  margin-bottom: rem(10);
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: $font-weight-medium;
  color: $product-title-color;

  a {
    color: $product-title-color;
  }

  a:hover &,
  a:focus &,
  a:active & {
    text-decoration: none;
    color: $product-title-color;
  }
}

.cross-article__text {
  font-size: rem(14);

  color: $body-color;
  a:hover &,
  a:focus &,
  a:active & {
    text-decoration: none;
  }
}

.btn-cross-article {
  position: absolute;
  top: 0;
  right: 0;
  .cross-article--no-desc & {
    top: rem(-10);
  }
}

.cross-article__want-more-btn {
  margin-top: $font-size-base;
}


.cross-article__video-container {
  position: relative;
  &::before {
    content: "";
    display: inline-block;
    width: rem(40); height: rem(40);
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);

    background: $white;
    border-radius: rem(20);
  }
  &::after {
    content: "";
    display: inline-block;
    width: 0; height: 0;
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
    margin-left: 3.5px;

    border-top: 5px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 7px solid black;
  }
}

.cross-article--in-submenu {
  display: flex;
  justify-content: space-between;
  .cross-article__illu {
    display: inline-block;
    flex: 0 0 40%;
  }
  .cross-article__body {
    flex: 1 1 60%;
    margin-top: 0;
    padding: 0 1em 0 3em;
  }
  .cross-article__title {
    text-transform: uppercase;
  }
  .cross-article__text {
    text-transform: initial;
    margin: 0;
  }
  .cross-article__illu {
    flex: 0;
  }
}

@include media-breakpoint-up(md) {
  .cross-article--in-submenu {
    flex-flow: row-reverse nowrap;
    .cross-article__illu {
      flex: 0 0 0;
    }
  }
}

@include media-breakpoint-up(lg) {
  .cross-article--in-submenu {
    .cross-article__illu {
      flex: 0 0 40%;
    }
  }
}
