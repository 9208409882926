// ==========================================================================
// Bloc dÃ©couvrir la gamme
// ==========================================================================

/*doc
---
title: Bloc Image lien
name: blocimagelink
category: Blocs
---

```html_example
<div style="width: 480px; height: 570px; margin: 0 0 30px;">
  <a href="#" class="image-link" style="background-image:url('https://via.placeholder.com/400x480')">
      <p class="image-link__title">A NoÃ«l, craquez pour le magret de canard</p>
      <p class="btn btn--white image-link__button">DÃ©couvrez notre gamme de viande de canard</p>
  </a>
</div>
```

*/

.image-link {
  display: table;
  position: relative;
  padding: rem(20) rem(20) rem(50);
  width: 100%;height: rem(470);

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  line-height: 0.9;
  text-align: center;
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
  p {
    color: $white;
    font-size: rem(14);
    text-align: left;
    text-decoration: underline;
  }


  &--shadow {
    .image-link {
      &__title {
        text-shadow: 2px 2px 2px $black;
      }
    }
  }
}

.image-link__content {
  display:table-cell;
  vertical-align: bottom;
}

@include media-breakpoint-up(md) {
  .image-link {
    padding: rem(75);
    height: 100%;
    p {
      position: absolute;
      bottom: rem(35); left: 50%;
      transform: translateX(-50%);
      width: 80%;

      text-align: center;
    }
  }
}

.image-link__title {
  margin: 60% 0 rem(30);

  color: #FFF;
  font-family: $headings-font-family;
  font-size: $h2-font-size;
  text-align: left;
}


