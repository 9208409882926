// ==========================================================================
// Onglets pour les informations additionnelles d'un produit
// ==========================================================================

/*doc
---
title: Onglets infos additionnelles
name: tabinfos
category: Blocs
---

```html_example
<article class="additional-info">
  <div class="additional-info__item add-info-texts">

      <ul class="nav nav-tabs tabs-info" role="tablist">
        <li class="nav-item tabs-info__item">
          <a class="nav-link tabs-info__link  active" id="ingredients-tab" data-toggle="tab" href="#ingredients" role="tab" aria-controls="home" aria-selected="true">IngrÃ©dients</a>
        </li>
        <li class="nav-item tabs-info__item">
          <a class="nav-link tabs-info__link" id="nutritional-values-tab" data-toggle="tab" href="#nutritional-values" role="tab" aria-controls="profile" aria-selected="false">Valeurs nutritionnelles</a>
        </li>
        <li class="nav-item tabs-info__item">
          <a class="nav-link tabs-info__link" id="allergens-tab" data-toggle="tab" href="#allergens" role="tab" aria-controls="contact" aria-selected="false">AllergÃ¨nes</a>
        </li>
        <li class="nav-item tabs-info__item">
          <a class="nav-link tabs-info__link" id="preservation-tab" data-toggle="tab" href="#preservation" role="tab" aria-controls="contact" aria-selected="false">Conservation</a>
        </li>
      </ul>
      <div class="tab-content">
        <div class="tab-pane fade show active" id="ingredients" role="tabpanel" aria-labelledby="ingredients-tab">
          <p class="tab-content__title">IngrÃ©dients</p>
          <ul class="tab-content__list tab-content__text">
            <li>Canard</li>
            <li>Sel</li>
            <li>Poivre</li>
          </ul>
        </div>
        <div class="tab-pane fade" id="nutritional-values" role="tabpanel" aria-labelledby="nutritional-values-tab">
          <p class="tab-content__title">IngrÃ©dients</p>
          <dl class="tab-content__list tab-content__text">
            <dt>Energie</dt><dd>2140 kJ/520kcal</dd>
            <dt>MatiÃ¨res grasses</dt><dd>53g (dont acides gras saturÃ©s 24g)</dd>
            <dt>Glucides</dt><dd>1,5g (dont sucres 1,2g)</dd>
            <dt>ProtÃ©ines</dt><dd>8g</dd>
            <dt>Sel</dt><dd>1,4g</dd>
          </dl>
        </div>
        <div class="tab-pane fade" id="allergens" role="tabpanel" aria-labelledby="allergens-tab">
          <p class="tab-content__title">AllergÃ¨nes</p>
          <p class="tab-content__text">Bacon ipsum dolor amet pork loin chuck pork ham, pork belly bresaola venison t-bone meatball beef pancetta ham hock pork chop short ribs. Flank turkey kielbasa, salami tail ground round ribeye pork pork chop doner.</p>
        </div>
        <div class="tab-pane fade" id="preservation" role="tabpanel" aria-labelledby="preservation-tab">
          <p class="tab-content__title">Conservation</p>
          <p class="tab-content__text">Chuck ham hock doner cow prosciutto ribeye. Drumstick chuck andouille tail pastrami short loin pork spare ribs shankle. Short ribs strip steak beef pork loin, shankle kielbasa bacon flank.</p>
        </div>

    </div>
  </div>
  <figure class="additional-info__item add-info-img">
    <img src="https://placehold.it/295x350" alt/>
  </figure>
</article>


```

*/

.additional-info {
  display: flex;
  align-items: stretch;
  padding: rem(25);

  background-color: $skin;
  background-image: url("#{$path-img}/fond-pattes-de-canard-seules.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.additional-info__item {
  &:last-child {
    display: none;
  }
}

@include media-breakpoint-up(md) {
  .additional-info__item {
    &:first-child {
      flex-grow: 1;
    }
  }
}

@include media-breakpoint-up(lg) {
  .additional-info__item {
    &:last-child {
      display: inline-block;
    }
  }
}

.add-info-texts {
  padding: rem(15);
  flex-grow: 1;

  background: #FFF;
}

@include media-breakpoint-up(md) {
  .add-info-texts {
    padding: rem(50);
  }
}

.add-info-img {
  margin: 0;
  flex-basis: 25%;
  img {
    width: 100%; height: 100%;
  }
}

.tabs-info {
  margin: 0; padding: 0;
  list-style-type: none;
}

.tabs-info__item {
  position: relative;
  overflow: hidden;
  &::after {
    content: ">";
    position: absolute;
    right: 0; bottom: rem(15);
    transform: rotate(90deg);

    font-size: rem(16);
    transition: all ease 500ms;
  }
  &.active {
    &::after {
      transform: rotate(-90deg);
    }
  }
  & + & {
    border-top: 2px solid $gray-1;
  }
}

.tabs-info__link {
  display: block;

  font-size: rem(14);
  font-weight: $font-weight-medium;
  text-transform: uppercase;

  color: $darkbrown;
  &:hover,
  &:focus,
  &:active {
    color: inherit;
    text-decoration: none;
  }
  span {
    display: inline-block;
    padding: rem(15) 0;
  }
  .active & {
    span {
      padding-left: rem(20);

      background:  url("#{$path-img}/svg/icon-tabactive.svg") no-repeat left center / rem(14);

      color: $pink;
    }

  }
}

.tabs-info__panel {
  max-height: 0;
  transition: all ease 250ms;
  .active & {
    transition: all ease 250ms;
    max-height: 50vh;
    padding-bottom: rem(30);
  }
}

.tabs-info__panel h2,
.tab-content__title {
  font-family: $headings-font-family;
  font-size: $h3-font-size;
}

.tabs-info__panel p,
.tab-content__text {
  font-size: rem(14);
}

.tabs-info__panel ul,
.tab-content__list {
  margin: 0; padding: 0;
  list-style-type: none;

  line-height: 2;
}
.tabs-info__panel ul,
.tab-content__list {
  dt, dd {
    display: inline;
  }
  dt {
    margin-right: rem(5);
  }
  dd::after {
    content:"\A";
    white-space: pre;
  }
}

.tabs-info__panel strong {
  font-weight: 600;
}

@include media-breakpoint-up(md) {
  .tabs-info { //ul
    position: relative;
    width: 100%; height: 100%;
  }
  .tabs-info__item { //li
    position: absolute;
    display: inline-block;
    top : 0; right: 0; bottom: 0; left: 0;
    overflow: initial;
    z-index: 0;

    background: $white;

    &.active {
      z-index: 1;
    }
    & + & {
      border-top: 0;
    }
    &:nth-child(2) {
      .tabs-info__link {
        margin-left : 22%;
        width: 34%;
      }
    }
    &:nth-child(3) {
      .tabs-info__link {
        margin-left : 56%;
      }
    }
    &:nth-child(4) {
      .tabs-info__link {
        margin-left : 78%;
      }
    }
    &::after {
      content: "";
      display: none;
    }
  }

  .tabs-info__link { // a
    display: inline-block;
    position: absolute;
    transform: translateY(-100%);
    width: 22%;
    .active & {
      span {
        padding-left: 0;
        background:  url("#{$path-img}/svg/icon-tabactive.svg") no-repeat top center / rem(14);
      }
    }
  }
  .tabs-info__panel { //content
    opacity: 0;
    max-height: initial;
    .active & {
      max-height: initial;
      opacity: 1;
      transition: all ease 1000ms;
    }

  }
}
