.modal-open{
    overflow: hidden;
    height: 100%;
}

#contentWrapper {display:unset;}

/* Colors */
$textColor: $body-color;

/***
* Reset CSS
*/
#tarteaucitronRoot div, #tarteaucitronRoot span, #tarteaucitronRoot applet, #tarteaucitronRoot object, #tarteaucitronRoot iframe, #tarteaucitronRoot h1, #tarteaucitronRoot h2, #tarteaucitronRoot h3, #tarteaucitronRoot h4, #tarteaucitronRoot h5, #tarteaucitronRoot h6, #tarteaucitronRoot p, #tarteaucitronRoot blockquote, #tarteaucitronRoot pre, #tarteaucitronRoot a, #tarteaucitronRoot abbr, #tarteaucitronRoot acronym, #tarteaucitronRoot address, #tarteaucitronRoot big, #tarteaucitronRoot cite, #tarteaucitronRoot code, #tarteaucitronRoot del, #tarteaucitronRoot dfn, #tarteaucitronRoot em, #tarteaucitronRoot img, #tarteaucitronRoot ins, #tarteaucitronRoot kbd, #tarteaucitronRoot q, #tarteaucitronRoot s, #tarteaucitronRoot samp, #tarteaucitronRoot small, #tarteaucitronRoot strike, #tarteaucitronRoot strong, #tarteaucitronRoot sub, #tarteaucitronRoot sup, #tarteaucitronRoot tt, #tarteaucitronRoot var, #tarteaucitronRoot b, #tarteaucitronRoot u, #tarteaucitronRoot i, #tarteaucitronRoot center, #tarteaucitronRoot dl, #tarteaucitronRoot dt, #tarteaucitronRoot dd, #tarteaucitronRoot ol, #tarteaucitronRoot ul, #tarteaucitronRoot li, #tarteaucitronRoot fieldset, #tarteaucitronRoot form, #tarteaucitronRoot label, #tarteaucitronRoot legend, #tarteaucitronRoot table, #tarteaucitronRoot caption, #tarteaucitronRoot tbody, #tarteaucitronRoot tfoot, #tarteaucitronRoot thead, #tarteaucitronRoot tr, #tarteaucitronRoot th, #tarteaucitronRoot td, #tarteaucitronRoot article, #tarteaucitronRoot aside, #tarteaucitronRoot canvas, #tarteaucitronRoot details, #tarteaucitronRoot embed, #tarteaucitronRoot figure, #tarteaucitronRoot figcaption, #tarteaucitronRoot footer, #tarteaucitronRoot header, #tarteaucitronRoot hgroup, #tarteaucitronRoot menu, #tarteaucitronRoot nav, #tarteaucitronRoot output, #tarteaucitronRoot ruby, #tarteaucitronRoot section, #tarteaucitronRoot summary, #tarteaucitronRoot time, #tarteaucitronRoot mark, #tarteaucitronRoot audio, #tarteaucitronRoot video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    /*background: initial;*/
    text-align: initial;
    text-shadow: initial;
}
/* HTML5 display-role reset for older browsers */
#tarteaucitronRoot article, #tarteaucitronRoot aside, #tarteaucitronRoot details, #tarteaucitronRoot figcaption, #tarteaucitronRoot figure, #tarteaucitronRoot footer, #tarteaucitronRoot header, #tarteaucitronRoot hgroup, #tarteaucitronRoot menu, #tarteaucitronRoot nav, #tarteaucitronRoot section {
    display: block;
}
#tarteaucitronRoot ol, #tarteaucitronRoot ul {
    list-style: none;
}
#tarteaucitronRoot blockquote, #tarteaucitronRoot q {
    quotes: none;
}
#tarteaucitronRoot blockquote:before, #tarteaucitronRoot blockquote:after, #tarteaucitronRoot q:before, #tarteaucitronRoot q:after {
    content: '';
    content: none;
}
#tarteaucitronRoot table {
    border-collapse: collapse;
    border-spacing: 0;
}

#tarteaucitronRoot a:focus, #tarteaucitronRoot button:focus {
    /*outline: 2px solid #cb3333;*/
}

/***
 * Better scroll management
 */
div#tarteaucitronMainLineOffset {
    margin-top: 0!important;
}

div#tarteaucitronServices {
    margin-top: 21px!important;
}

#tarteaucitronServices::-webkit-scrollbar {
    width: 5px;
}

#tarteaucitronServices::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0 rgba(0,0,0,0);
}

#tarteaucitronServices::-webkit-scrollbar-thumb {
  background-color: #ddd;
  outline: 0px solid slategrey;
}

div#tarteaucitronServices {
    box-shadow: 0 0 35px #575757;
}

/***
 * Responsive layout for the control panel
 */
@media screen and (max-width:479px) {
    #tarteaucitron .tarteaucitronLine .tarteaucitronName {
        width: 90% !important;
    }

    #tarteaucitron .tarteaucitronLine .tarteaucitronAsk {
        float: left !important;
        margin: 10px 15px 5px;
    }
}

@media screen and (max-width:767px) {
    #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer, #tarteaucitron {
        background: #fff;
        border: 0 !important;
        bottom: 0 !important;
        height: 100% !important;
        left: 0 !important;
        margin: 0 !important;
        max-height: 100% !important;
        max-width: 100% !important;
        top: 0 !important;
        width: 100% !important;
    }

    #tarteaucitron .tarteaucitronBorder {
        border: 0 !important;
    }

    #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList {
        border: 0 !important;
    }

    #tarteaucitron #tarteaucitronServices .tarteaucitronTitle {
        text-align: left !important;
    }

    .tarteaucitronName .tarteaucitronH2 {
        max-width: 80%;
    }

    #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk {
        text-align: center !important;
    }

        #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk button {
            margin-bottom: 5px;
        }
}

@media screen and (min-width:768px) and (max-width:991px) {
    #tarteaucitron {
        border: 0 !important;
        left: 0 !important;
        margin: 0 5% !important;
        max-height: 80% !important;
        width: 90% !important;
    }
}

/***
 * Common value
 */
#tarteaucitron * {
    zoom: 1;
}

#tarteaucitronRoot div#tarteaucitron {
    left: 0;
    right: 0;
    margin: auto;
}

#tarteaucitronRoot button#tarteaucitronBack {
    background: #eee;
}

#tarteaucitron .clear {
    clear: both;
}

#tarteaucitron a {
    color: rgb(66, 66, 66);
    font-size: 11px;
    font-weight: 700;
    text-decoration: none;
}

#tarteaucitronRoot button {
    background: transparent;
    border: 0;
}

#tarteaucitron strong {
    font-size: 22px;
    font-weight: 500;
}

#tarteaucitron ul {
    padding: 0;
}

#tarteaucitron .tarteaucitronH1, #tarteaucitron .tarteaucitronH2, #tarteaucitron .tarteaucitronH3, #tarteaucitron .tarteaucitronH4, #tarteaucitron .tarteaucitronH5, #tarteaucitron .tarteaucitronH6 {
  display: block;
}

.cookie-list {
    list-style: none;
    padding: 0;
    margin: 0;
}
/***
 * Root div added just before </body>
 */
#tarteaucitronRoot {
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}

#tarteaucitronRoot * {
    box-sizing: initial;
    color: $textColor;
    //font-family: sans-serif !important;
    font-size: 14px;
    line-height: normal;
    vertical-align: initial;
}

#tarteaucitronRoot .tarteaucitronH1 {
    font-size: 1.5em;
    text-align: center;
    color: #fff;
    margin: 15px 0 28px;
}

#tarteaucitronRoot .tarteaucitronH2 {
    display: inline-block;
    margin: 12px 0 0 10px;
    color: #fff;
}

#tarteaucitronCookiesNumberBis.tarteaucitronH2 {
    margin-left: 0;
}

/***
 * Control panel
 */
#tarteaucitronBack {
    background: #fff;
    display: none;
    height: 100%;
    left: 0;
    opacity: 0.7;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2147483646;
}

#tarteaucitron {
    display: none;
    max-height: 80%;
    left: 50%;
    margin: 0 auto 0 -430px;
    padding: 0;
    position: fixed;
    top: 6%;
    width: 860px;
    z-index: 2147483647;
}

#tarteaucitron .tarteaucitronBorder {
    background: #fff;
    border: 2px solid #333;
    border-top: 0;
    height: auto;
    overflow: auto;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronClosePanelCookie,
#tarteaucitron #tarteaucitronClosePanel {
    background: #333333;
    color: #fff;
    cursor: pointer;
    font-size: 12px;
    font-weight: 700;
    text-decoration: none;
    padding: 4px 0;
    position: absolute;
    right: 0;
    text-align: center;
    width: 70px;
}

#tarteaucitron #tarteaucitronDisclaimer {
    color: #555;
    font-size: 12px;
    margin: 15px auto 0;
    width: 80%;
}

.tarteaucitronSelfLink, #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronHidden,
#tarteaucitron #tarteaucitronServices .tarteaucitronHidden {
    background: rgba(51, 51, 51, 0.07);
}a.tarteaucitronSelfLink {
    text-align: center!important;
    display: block;
    padding: 7px!important;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronHidden {
    display: none;
    position: relative;
}

#tarteaucitronCookiesList .tarteaucitronH3.tarteaucitronTitle {
    width: 100%;
    box-sizing: border-box;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronTitle, 
#tarteaucitron #tarteaucitronServices .tarteaucitronTitle button, 
#tarteaucitron #tarteaucitronInfo, 
#tarteaucitron #tarteaucitronServices .tarteaucitronDetails {
    color: #fff;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    margin: 20px 0px 0px;
    padding: 5px 20px;
    text-align: left;
    width: auto;
    background: #333;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName a,
#tarteaucitron #tarteaucitronServices .tarteaucitronTitle a {
    color: #fff;
    font-weight: 500;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName a:hover,
#tarteaucitron #tarteaucitronServices .tarteaucitronTitle a:hover {
    text-decoration: none !important;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName a {
    font-size: 22px;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronTitle a {
    font-size: 14px;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronTitle {
    padding: 5px 10px;
    margin: 0;
}

#tarteaucitron #tarteaucitronInfo,
#tarteaucitron #tarteaucitronServices .tarteaucitronDetails {
    color: #fff;
    display: none;
    font-size: 12px;
    font-weight: 500;
    margin-top: 0;
    max-width: 270px;
    padding: 20px;
    position: absolute;
    z-index: 2147483647;
}

#tarteaucitron #tarteaucitronInfo a {
    color: #fff;
    text-decoration: underline;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine:hover {
    background: rgba(51, 51, 51, 0.2);
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine {
    background: rgba(51, 51, 51, 0.1);
    border-left: 5px solid transparent;
    margin: 0;
    overflow: hidden;
    padding: 15px 5px;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine {
    background: #333;
    border: 3px solid #333;
    border-left: 9px solid #333;
    border-top: 5px solid #333;
    margin-bottom: 0;
    margin-top: 21px;
    position: relative;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine:hover {
    background: #333;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName {
    margin-left: 15px;
    margin-top: 2px;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName button {
    color: #fff;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronAsk {
    margin-top: 0px !important;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronName {
    display: inline-block;
    float: left;
    margin-left: 10px;
    text-align: left;
    width: 50%;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronName a:hover {
    text-decoration: underline;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk {
    display: inline-block;
    float: right;
    margin: 7px 15px 0;
    text-align: right;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronAllow,
#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronDeny,
.tac_activate .tarteaucitronAllow {
    background: gray;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    padding: 6px 10px;
    text-align: center;
    text-decoration: none;
    width: auto;
    border: 0;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronName .tarteaucitronListCookies {
    color: #333;
    font-size: 12px;
}

#tarteaucitron .tarteaucitronH3 {
    font-size: 18px;
}

#tarteaucitron #tarteaucitronMainLineOffset .tarteaucitronName {
    width: auto!important;
    margin-left: 0!important;
    font-size: 14px;
}

/***
 * Big alert
 */
.tarteaucitronAlertBigTop {
    top: 0;
}

.tarteaucitronAlertBigBottom {
    bottom: 0;
}

#tarteaucitronRoot #tarteaucitronAlertBig {
  background: $skin;
  //color: #fff;
  display: none;
  font-size: 13px !important;
  left: 0;
  padding: 5px 5%;
  position: fixed;
  text-align: center;
  box-sizing: content-box;
  z-index: 2147483645;
  text-align: center;
  padding: 10px;
  margin: auto;
  width: 100%;
  &::before {
    content: "";
    position:absolute;
    left: 0;
    display: block;
    width: 100%; height: 5px;
    background-image: radial-gradient($skin 3px, transparent 0);
    background-size: 9px 12px;
    background-repeat: repeat-x;
    bottom: 100%;

    background-position: -7px 0px ;
  }
}

#tarteaucitronAlertBig #tarteaucitronDisclaimerAlert strong {
    font-weight: 700;
}

#tarteaucitronAlertBig #tarteaucitronPrivacyUrl {
    cursor: pointer;
}

#tarteaucitronAlertBig #tarteaucitronCloseAlert, #tarteaucitronAlertBig #tarteaucitronPersonalize, #tarteaucitron #tarteaucitronPrivacyUrl {
  cursor: pointer;
  display: inline-block;
  padding: 5px 2em;
  text-decoration: none;
  margin-left: 7px;

  background: $pink;
  border-radius: 1em;

  color: $white;
}

#tarteaucitronAlertBig #tarteaucitronCloseAlert, #tarteaucitron #tarteaucitronPrivacyUrl {
    margin-bottom: 3px;
    margin-left: 7px;
    border: 1px solid $textColor;

    background: transparent;
    color: inherit;
}

#tarteaucitronPercentage {
    background: $pink !important;
    height: 5px;
    left: 0;
    position: fixed;
    width: 0;
    z-index: 2147483644;
}

/***
 * Small alert
 */

.tarteaucitronAlertSmallTop,.tarteaucitronAlertSmallBottom {
    bottom: 0;
}

#tarteaucitronAlertSmall {
    background: #333;
    display: none;
    padding: 0;
    position: fixed;
    right: 0;
    text-align: center;
    width: auto;
    z-index: 2147483646;
}

#tarteaucitronAlertSmall #tarteaucitronManager {
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 11px !important;
    padding: 8px 10px 8px;
}

#tarteaucitronAlertSmall #tarteaucitronManager:hover {
    background: rgba(255, 255, 255, 0.05);
}

#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot {
    background-color: gray;
    border-radius: 5px;
    display: block;
    height: 8px;
    margin-bottom: 1px;
    margin-top: 5px;
    overflow: hidden;
    width: 100%;
}

#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotGreen,
#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotYellow,
#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotRed {
    display: block;
    float: left;
    height: 100%;
    width: 0%;
}

#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotGreen {
    background-color: #1B870B;
}

#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotYellow {
    background-color: #FBDA26;
}

#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotRed {
    background-color: #9C1A1A;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesNumber {
    background: rgba(255, 255, 255, 0.2);
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 30px;
    padding: 0px 10px;
    vertical-align: top;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesNumber:hover {
    background: rgba(255, 255, 255, 0.3);
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer {
    display: none;
    max-height: 70%;
    max-width: 500px;
    position: fixed;
    right: 0;
    width: 100%;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList {
    background: #fff;
    border: 2px solid #333;
    color: #333;
    font-size: 11px;
    height: auto;
    overflow: auto;
    text-align: left;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList strong {
    color: #333;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesTitle {
    background: #333;
    margin-top: 21px;
    padding: 13px 0 9px 13px;
    text-align: left;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesTitle strong {
    color: #fff;
    font-size: 16px;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain {
    background: rgba(51, 51, 51, 0.1);
    padding: 7px 5px 10px;
    word-wrap: break-word;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain:hover {
    background: rgba(51, 51, 51, 0.2);
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain a {
    color: #333;
    text-decoration: none;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain .tarteaucitronCookiesListLeft {
    display: inline-block;
    width: 50%;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain .tarteaucitronCookiesListLeft a strong {
    color: darkred;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain .tarteaucitronCookiesListRight {
    color: #333;
    display: inline-block;
    font-size: 11px;
    margin-left: 10%;
    vertical-align: top;
    width: 30%;
}

/***
 * Fallback activate link
 */
.tac_activate {
    background: #333;
    color: #fff;
    display: table;
    font-size: 12px;
    height: 100%;
    line-height: initial;
    margin: auto;
    text-align: center;
    width: 100%;
}

.tac_float {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}

.tac_activate .tac_float strong {
    color: #fff;
}

.tac_activate .tac_float .tarteaucitronAllow {
    background-color: #1B870B;
    display: inline-block;
}

/***
 * CSS for services
 */
ins.ferank-publicite, ins.adsbygoogle {
    text-decoration: none;
}

div.amazon_product {
    height:240px;
    width:120px;
}
.tarteaucitronIsAllowed .tarteaucitronDeny {
    opacity: 0.4!important;
}.tarteaucitronIsDenied .tarteaucitronAllow {
    opacity: 0.4!important;
}.tarteaucitronIsAllowed .tarteaucitronAllow {
    opacity: 1!important;
}.tarteaucitronIsDenied .tarteaucitronDeny {
    opacity: 1!important;
}
.tarteaucitronLine .tarteaucitronAllow, .tarteaucitronLine .tarteaucitronAllow {
    opacity: 0.4;
}

div#tarteaucitronInfo {
    display: block!important;
    position: initial!important;
    text-align: center!important;
    max-width: 80%!important;
    padding: 15px 0!important;
    margin: -10px auto 40px!important;
    font-size: 1em!important;
    border-bottom: 1px solid;
    border-top: 1px solid;
    border-color: #555;
}

a.tarteaucitronSelfLink {
    position: absolute;
    left: 0;
    right: 0;
    padding-top: 13px!important;
    display: block;
    text-shadow: 0 0 14px white;
    text-transform: uppercase;
}.tarteaucitronMainLine .tarteaucitronH2 {
    font-size: 1.2em!important;
    margin-top: 4px!important;
}

span.tarteaucitronTitle.tarteaucitronH3 {
    margin-top: 12px!important;
}
