// ==========================================================================
// Home
// ==========================================================================

// Gourmand selection
.gourmand-selection {
  .product__title {
    padding-right: rem(40);
  }
  .cross-article__body,
  .product__head {
    padding: 0 rem(30);
  }
  .btn-cross-article {
    right: rem(30);
  }
}

@include media-breakpoint-up(md) {
  .gourmand-selection {
    .product .cross-article__illu {
      max-height: rem(150);
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .product__head,
    .cross-article__body {
      padding: 0;
    }
    .btn-cross-article {
      right: 0;
    }
  }
}

// Univers gourmands et traditionnels
@include media-breakpoint-down(sm) {
  .skin-bg-sm--gourmandUniverse {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 0;
      right: 0;
      height: rem(150);

      background: $skin;
    }
  }
}

@include media-breakpoint-up(md) {
  .skin-bg-md--gourmandUniverse {
    background: linear-gradient(transparent 74%, $skin 0, $skin);
  }
}

@include media-breakpoint-up(xl) {
  .skin-bg-md--gourmandUniverse {
    background: linear-gradient(transparent 60%, $skin 0, $skin);
  }
}

//Nos offres spÃ©ciales du moment
.special-offers {
  background: linear-gradient(0deg, $skin 50%, $white 50%);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}

.special-offers__text {
  padding-top: rem(90);
}

@include media-breakpoint-up(md) {
  .special-offers__text {
    padding-top: 0;
  }
}

@include media-breakpoint-up(md) {
  .special-offers__text {
    display: flex;
    height: 100%;
    align-items: center;
    align-content: center;
  }
  .special-offers__item {
    flex-grow: 1;
    .product-quick-add {
      height: 100%;
    }
  }
}

.badges-list {
  a {
    color: inherit;
    &:hover,
    &:focus,
    &:active {
      color: inherit;
      text-decoration: none;
    }
  }
}

// PrÃ©sentation de l'entreprise
.discover-soulard {
  padding: rem(40) rem(20);
}

@include media-breakpoint-up(md) {
  .discover-soulard {
    padding: rem(80);
    height: 100%;
  }
}

.company-feature {
  padding: rem(30) rem(20);
  & + & {
    border-top: 2px dashed $gray-0;
  }
}

@include media-breakpoint-up(md) {
  .company-feature {
    padding: rem(40);
  }
}

.company-feature__title {
  margin-bottom: rem(10);

  color: $product-title-color;
  font-size: rem(16);
  font-family: $font-family-base;
  font-weight: $font-weight-medium;
  line-height: 1.2;
  text-transform: uppercase;
}

.company__illu {
  min-height: rem(260);

  background-repeat: no-repeat;
  background-size: cover;
}

@include media-breakpoint-up(md) {
  .company__illu {
    min-height: initial;
    height: 100%;
  }
}
