// ==========================================================================
// Informations principales d'un produit
// ==========================================================================

/*doc
---
title: Avis client
name: customerreview
category: Blocs
---

```html_example
<div style="background:#333; padding: 20px;">
  <h1 class="product-review__title">Avis client</h1>

  <div class="product__evaluation product__evaluation--white">
    <div class="product-review__stars">
      <svg class="icon-star-fill" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 18.1"><path fill="#C40040" d="M9.5 15.5L4.7 18c-.4.2-1 .1-1.2-.4-.1-.2-.1-.4-.1-.6l.9-5.4v-.1l-4-3.6c-.4-.3-.4-.9 0-1.3.1-.1.3-.2.5-.3l5.4-.8s.1 0 .1-.1L8.7.5c.2-.4.8-.6 1.2-.4.2.1.3.2.4.4l2.4 4.9s0 .1.1.1l5.4.8c.5.1.8.5.8 1 0 .2-.1.4-.3.5l-3.9 3.8v.1l.9 5.4c.1.5-.2 1-.7 1-.2 0-.4 0-.6-.1l-4.9-2.5z"/></svg>
      <svg class="icon-star-fill" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 18.1"><path fill="#C40040" d="M9.5 15.5L4.7 18c-.4.2-1 .1-1.2-.4-.1-.2-.1-.4-.1-.6l.9-5.4v-.1l-4-3.6c-.4-.3-.4-.9 0-1.3.1-.1.3-.2.5-.3l5.4-.8s.1 0 .1-.1L8.7.5c.2-.4.8-.6 1.2-.4.2.1.3.2.4.4l2.4 4.9s0 .1.1.1l5.4.8c.5.1.8.5.8 1 0 .2-.1.4-.3.5l-3.9 3.8v.1l.9 5.4c.1.5-.2 1-.7 1-.2 0-.4 0-.6-.1l-4.9-2.5z"/></svg>
      <svg class="icon-star-fill" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 18.1"><path fill="#C40040" d="M9.5 15.5L4.7 18c-.4.2-1 .1-1.2-.4-.1-.2-.1-.4-.1-.6l.9-5.4v-.1l-4-3.6c-.4-.3-.4-.9 0-1.3.1-.1.3-.2.5-.3l5.4-.8s.1 0 .1-.1L8.7.5c.2-.4.8-.6 1.2-.4.2.1.3.2.4.4l2.4 4.9s0 .1.1.1l5.4.8c.5.1.8.5.8 1 0 .2-.1.4-.3.5l-3.9 3.8v.1l.9 5.4c.1.5-.2 1-.7 1-.2 0-.4 0-.6-.1l-4.9-2.5z"/></svg>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="-640 441.9 18.9 18.1">
        <path fill="#C40040" d="M-630.2 456.7l4.8 2.5h.1v-.1l-.8-5.3c0-.3 0-.6.3-.8l3.9-3.8v-.1h-.1l-5.4-.8c-.3 0-.5-.2-.7-.5l-2.4-4.9s-.1-.1-.1 0l-2.5 4.9c-.1.2-.4.4-.7.5l-5.3.7c-.1 0-.1.1-.1.1v.1l3.9 3.8c.2.2.3.5.2.8l-.9 5.4c0 .1 0 .1.1.1h.1l4.8-2.5c.2-.2.6-.2.8-.1zm-.4.7l-4.8 2.5c-.4.2-1 .1-1.2-.4-.1-.2-.1-.4-.1-.6l.9-5.4v-.1l-4-3.6c-.3-.4-.3-.9 0-1.3.1-.1.3-.2.5-.3l5.4-.8s.1 0 .1-.1l2.4-4.9c.2-.4.8-.6 1.2-.4.2.1.3.2.4.4l2.4 4.9s0 .1.1.1l5.4.8c.5.1.8.5.8 1 0 .2-.1.4-.3.5l-3.9 3.8v.1l.9 5.4c.1.5-.2 1-.7 1-.2 0-.4 0-.6-.1l-4.9-2.5z"/>
        <path fill="#C40040" d="M-630.8 457.1l-4.4 2.3c-.4.2-.9.1-1.1-.4-.1-.2-.1-.4-.1-.6l.8-5v-.1l-3.7-3.4c-.4-.3-.4-.8 0-1.2.1-.1.3-.2.5-.3l5-.7s.1 0 .1-.1l2.2-4.6c.2-.4.7-.6 1.1-.4.2.1.3.2.4.4l2.2 4.6s0 .1.1.1h.3v11c0 .1-3.4-1.6-3.4-1.6z"/>
      </svg>
      <svg class="icon-star" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.9 18.1"><path fill="#C40040" d="M9.8 14.8l4.8 2.5h.1v-.1l-.8-5.3c0-.3 0-.6.3-.8l3.9-3.8v-.1H18l-5.4-.8c-.3 0-.5-.2-.7-.5L9.5 1s-.1-.1-.1 0L6.9 5.9c-.1.2-.4.4-.7.5l-5.3.7c-.1 0-.1.1-.1.1v.1l3.9 3.8c.2.2.3.5.2.8L4 17.3c0 .1 0 .1.1.1h.1L9 14.9c.2-.2.6-.2.8-.1zm-.4.7L4.6 18c-.4.2-1 .1-1.2-.4-.1-.2-.1-.4-.1-.6l.9-5.4v-.1l-4-3.6c-.3-.4-.3-.9 0-1.3.1-.1.3-.2.5-.3l5.4-.8s.1 0 .1-.1L8.6.5c.2-.4.8-.6 1.2-.4.2.1.3.2.4.4l2.4 4.9s0 .1.1.1l5.4.8c.5.1.8.5.8 1 0 .2-.1.4-.3.5l-3.9 3.8v.1l.9 5.4c.1.5-.2 1-.7 1-.2 0-.4 0-.6-.1l-4.9-2.5z"/></svg>
    </div>

    <p class="product-review__mark">4,75/5</p>

    <a href="#" class="simple-link simple-link--thin product__evaluation-link product-review__counter">5 avis</a>

  </div>

  <div style="width:320px;">
    <article class="product product-info review">
      <div class="product__wrapper">
        <p class="product__subtitle review__author">Marie Dupont</p>
        <div class="product__evaluation">
          <div class="evaluation">
            <div class="evaluation__gauge" style="width:93.29%"></div>
            <img class="evaluation__img" src="../styleguide/img/stars.svg" alt="">
          </div>
          <a href="#" class="simple-link simple-link--thin">PubliÃ© le 08/09//2018</a>
        </div>
        <p class="review__title">Un foie gras d'une qualitÃ© inÃ©galable</p>
        <p class="review__text">Bacon ipsum dolor amet turkey burgdoggen swine frankfurter shankle ground round ham hock chicken. Beef brisket doner filet mignon alcatra.</p>
      </div>
    </article>
  </div>

</div>

```

*/

%pl-layout {
  display: inline-block;
  margin-right: rem(10);
}

.product__evaluation--white {
  svg {
    display: inline-block;
    width: rem(19);
    & {
      margin-right: rem(5);
      &:last-child {
        margin-right: 0;
      }
    }
    path {
      fill: #FFF;
    }
  }
}

.product-review__title {
  margin-bottom: rem(10);

  color: #FFF;
  font-size: $h1-font-size;
}

.product-review__stars {
  @extend %pl-layout;
}

.product-review__mark {
  @extend %pl-layout;

  color: #FFF;
  font-weight: bold;
}

.product-review__counter {
  @extend %pl-layout;

  color: #FFF;
}

/* == Bloc review */
.review {
  padding: rem(40) rem(30);

  background: #FFF;

  &--disabled {
    display: none;
  }
}

.review__author {
  margin-bottom: 0;
}

.review__title {
  margin-bottom: 0.5rem;

  font-weight: bold;
}

.review__text {
  font-size: rem(14);
}

.reviews__button {
  padding-bottom: rem(30);
}

.reviews__external {
  text-align: center;
  padding-top: 1.5rem;
}
