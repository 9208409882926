// ==========================================================================
// Smooth appearing
// ==========================================================================

@include media-breakpoint-up(md) {
  .smooth-appearing {
    opacity: 0;
    position: relative;

    transition: all ease 300ms;
  }

  .smooth-appearing--from-right {
    right: -100px;
  }

  .smooth-appearing--from-bottom {
    top: 100px;
  }

  .smooth-appearing--from-left {
    left: -100px;
  }

  .smooth-appearing-visible {
    opacity: 1;
    &.smooth-appearing--from-right {
      right: 0;
    }
    &.smooth-appearing--from-bottom {
      top: 0;
    }
    &.smooth-appearing--from-left {
      left: 0;
    }
  }
}
