// ==========================================================================
// flashbag component
// ==========================================================================

.flashbag {
  $self: &;

  text-align: center;
  border: rem(2) solid;
  padding: rem(10);

  &__title {
    font-weight: 400;
    font-size: rem(18);
    color: $pink;
    display: block;
  }

  &__text {
    font-weight: 400;
  }

  &__content {
    border: rem(2) solid;
  }

  &--warning {
    border-color: $yellow;
    color: $black;
  }

  &--error {
    border-color: $pink;
    color: $pink;
  }

  &--negative {
    border-color: $pink;
    color: $pink;
  }

  &--info {
    border-color: $gray-3;
    color: $black;
  }

  &--success {
    border-color: $green-success;
    color: $green-success;

    #{$self}__content {
      border-color: $pink;
    }
  }

  &__close {
    position: absolute;
    border: 0;
    background: $pink;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: rem(36);
    height: rem(36);
    top: rem(-18);
    right: rem(-18);
    font-size: rem(28);
    line-height: rem(33);
    color: $white;
  }

  &--mobile {
    position: relative;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  &--modal {
    &#{$self}--success {
      color: $black;
    }
  }

  &--desktop {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 2;
    display: none;
    align-items: center;
    justify-content: center;
    background: rgba($gray-2, 0.3);
    border: none;

    #{$self} {
      &__content {
        background: $white;
        padding: rem(20);
        position: relative;
      }
    }

    &.unactive {
      display: none;
    }
  }

  &__btn-back {
    margin-right: rem(30);
  }

  &__img {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: rem(20);

    img {
      max-width: rem(150);
    }
  }

  @include media-breakpoint-up(md) {
    &--mobileÂ  {
      display: none !important;
    }

    &--desktop {
      display: flex;
    }
  }
}
