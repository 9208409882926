// ==========================================================================
// Page universe
// ==========================================================================

.universe-description {
  font-size: rem(16);
}

.gammes-list {
  display: flex;
  flex-direction: column;
}

.gammes-list__item {
  .product__body {
    padding: 0 rem(10);

    text-align: center;
  }
}

@include media-breakpoint-up(md) {
  .gammes-list {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    &.gammes-list--nowrap {
      flex-wrap: nowrap;
      .gammes-list__item + .gammes-list__item {
        margin-left: rem(40);
      }
    }
  }

  .gammes-list__item {
    flex-basis: 45%;
    .product__body {
      padding: 0 rem(20);
    }
  }
}

.infos-compl__bg-block {
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;
  z-index: -1;

  background: $skin;
  &::after {
    top: 100%;
  }
}

.infos-compl__img {
  width: 100%;
}

.infos-compl__text {
  padding: 20px 0 40px;
}

.infos-compl__list {
  list-style-type: none;
  margin: 0; padding: 0;
  display: flex;
  flex-direction: column;
  .picto {
    svg {
      width: rem(80);
      height: auto;
    }
  }
}

@include media-breakpoint-up(md) {
  .infos-compl__list {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    li {
      flex-grow: 0;
      flex-basis: 30%;
    }
  }

  .infos-compl__text {
    padding: 40px 0;
    p {
      line-height: 1.5;
    }
  }
}

/* == DÃ©couvrez nos autres gammes de produit */
.other-ranges {
  display: flex;
  flex-direction: column;
}

.other-ranges__item {
  &:first-child {
    padding: rem(40) rem(25) rem(80);
  }
}

@include media-breakpoint-up(md) {
  .other-ranges__item {
    &:first-child {
      padding: rem(100) rem(80);
    }
  }
}

.other-ranges__title {
  color: #FFF;
}

.image-link {
  .other-ranges__item & {
    height: rem(390);
  }
  > div {
    display: table-cell;
    vertical-align: bottom;
  }
}

.image-link__title {
   .other-ranges__item & {
     font-size: rem(20);
     text-align: center;
   }
}

.image-link__button {
  .other-ranges__item & {
    font-size: rem(14);
  }
}

@include media-breakpoint-up(md) {
  .other-ranges {
    flex-direction: row;
  }

  .image-link {
    .other-ranges__item & {
      height: rem(370);
    }
  }

  .other-ranges__item:first-child {
    flex-basis: 37%;
  }

  .other-ranges__item {
    flex-grow: 1;
    & + & {
      flex-basis: 21%;
      border-left: 1px solid $white;
    }
    &:nth-child(2) {
      border-left: none;
    }
  }
}

// #refs #32615 - pas top top :'(
@include media-breakpoint-up(xl) {
  .gammes-list {
    &.gammes-list--two-items {
      .btn {
        white-space: nowrap;
      }
    }
  }
}
