// ==========================================================================
// Product setup
// ==========================================================================

/*doc
---
title: Product setup
category: Shop
---

```html_example
<div style="margin-bottom:30px;">
  <span class="product__tag">SÃ©lection NoÃ«l</span>
  <span class="product__tag product__tag--special">-15%</span>
</div>

<div style="margin-bottom:30px;">
<span class="product__badge">< 4Â°C</span>
  <span class="product__badge">4Â°C</span>
  <span class="product__badge product__badge--lightblue">-18Â°C</span>
  <span class="product__badge product__badge--blue">-18Â°C</span>
</div>

<div style="margin-bottom:30px;">
  <span class="badge badge-pill">50g</span>
  <button class="badge badge-pill">50g</button>
  <button class="badge badge-pill badge-pill--disabled badge-pill--unavailable">50g</button>
  <button class="badge badge-pill badge-pill--disabled">50g</button>
</div>

```

*/

/* == ElÃ©ments additionnels */

// SÃ©lection NoÃ«l
.product__tag {
  padding: rem(5) rem(10);
  border: 1px solid;
  font-size: rem(14);

  color: $darkbrown;
  font-weight: $font-weight-normal;
  &:hover,
  &:focus,
  &:active {
    color: inherit;
    text-decoration: none;
  }
}
.product__tag--special {
  color: $pink;
  border-color: $pink;
  &:hover,
  &:focus,
  &:active {
    color:  $pink;
  }
}

// Pastille tempÃ©rature
.product__badge {
  display: inline-block;
  width: rem(40); height: rem(40);

  background: $skin;
  border-radius: 50%;

  font-size: rem(10);
  font-weight: $font-weight-normal;
  line-height: rem(40);
  text-align: center;

}

.product__badge--lightblue {
  background: $lightgreen;
}

.product__badge--blue {
  background: $lightblue;
}

// Pastille conditionnement
.badge {
  border: 1px solid $gray-1;

  font-weight: $font-weight-light;
}

.badge-pill {
  padding: 0.3em 0.8em;

  color: $gray-3;
}

.badge-pill--disabled {
  background-color: #FFF;

  color: $gray-3;
}

.badge-pill--unavailable {
  background-image: linear-gradient(to bottom right, transparent 50%, $gray-1 0, $gray-1 53%, transparent 0);
}

.ui.checkbox input[type=radio].badge-input,
.badge-input {
  // Deactivate Sylius style (start)
  &.sr-only {
    width: 1px; height: 1px;
    padding: 0;
  }
  & + label {
    line-height: initial;
  }
  & + label::before,
  & + label::after {
    display: none;
  }
  // Deactivate Sylius style (end)
  & + label {
    cursor: pointer;
    padding: 0.3em 0.8em;

    border: 1px solid $gray-1;
    //border-radius: 10rem;

    color: $gray-3;
    font-size: 75%;
    font-weight: $font-weight-light;

    border-radius: 15px;
    overflow: hidden;
  }
  &:checked + label {
    background: $gray-1;

    color: $body-color;
  }
  &:disabled + label {
    background-image: linear-gradient(to bottom right, transparent 50%, $gray-1 0, $gray-1 53%, transparent 0);

    cursor: default;
  }
}
/* == ElÃ©ments constitutifs */

.product {}
.product__wrapper {
  background: #FFF;
  //.word-bg & {
  //  //
  // background: transparent;
  //}
}
.product__head {
  display: flex;
  align-items: center;
  margin-bottom: rem(10);

  .product__badge {
    margin-left: auto;
  }
}
.product__tag {
  margin-right: $font-size-base;
}
.btn-favorite {
  margin-right: $font-size-base * 1.5;
}
.product__img {
  margin-bottom: rem(20);
  overflow: hidden;
  img {
    transition: all ease 500ms;
    width: 100%;
  }
  &:hover,
  &:focus,
  &:active {
    img {
      transition: all ease 500ms;
      transform: scale(1.1);
    }
  }
}
.product__figcaption{
  margin-top: rem(10);

  font-family: $headings-font-family;
  h3 {
    margin-top: rem(10);

    font-family: $headings-font-family;
    font-size: 1rem;
    font-weight: 300;
  }
  a & {
    color: $body-color;
    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
    }
  }
}
.product__body {
  position: relative;
}
.product__title {
  margin-bottom: rem(2);
  font-size: rem(14);
  font-family: $font-family-base;
  font-weight: $font-weight-medium;
  color: $product-title-color;
  text-transform: uppercase;
}
.product__subtitle {
  margin-bottom: rem(20);
  margin-top: rem(10);
  font-size: rem(18);
  font-weight: $font-weight-medium;
  color: $product-subtitle-color;
}
.product__text {
  margin-bottom: rem(30);
}
.product__ref {
  margin-bottom: rem(15);
  font-size: rem(12);
  color: $product-ref-color;
  text-transform: uppercase;
}
.product__alert {
  color: $pink;
  font-size: rem(12);
}
.product__evaluation {
  margin-bottom: rem(20);
}
.product__foot {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product__price-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: rem(-21);
}

.product__price {
  flex-grow: 1;
  font-weight: $font-weight-normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: rem(21);

  span {
    font-size:rem(25);

    &.product__price--promo {
      color: $pink;
    }
  }

  small {
    font-weight: $font-weight-light;
  }

  .product__price-base {
    text-align: center;
    font-size: rem(16);
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      height: rem(2);
      background-color: $pink;
    }
  }
}

.product-btn {
  flex-grow: 1;
  margin-top: rem(21);
}

.product__counter {
  margin-right: rem(40);
}
.product__add-cart {
  display: flex;
  align-items: center;
}
.product__link, .product__link:visited {
  &:hover, &:focus {}
}

@include media-breakpoint-up(md) {
  .product__img {
    margin-bottom: rem(40);
  }

  .product__head {
    margin-bottom: rem(30)
  }
}
