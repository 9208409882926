// ==========================================================================
// Menu
// ==========================================================================

.btn-menu {
  display: inline-block;
  width: rem(20);
  height: rem(20);
  padding: 0;
  background: transparent;
  border: none;
}

// #32904 : md Ã  lg
@media (hover: hover) {
  @include media-breakpoint-up(lg) {
    .btn-menu {
      width: initial;
      height: initial;
      &.btn-menu--search-form {
        display: none;
      }
    }
  }
}
