// ==========================================================================
// Onglets pour les filtres dans la recherche produit
// ==========================================================================

.tabs-recipe {
  margin: 0; padding: 0;
  list-style-type: none;

  display: flex;
  flex-direction: column;
}

.tabs-recipe__item {
  border-bottom: 2px solid $gray-1;
  &.active {
  }
}

@include media-breakpoint-up(md) {
  .tabs-recipe__item {
    position: relative;

    border-bottom: none;
    & + & {
      margin-left: rem(30);
    }
  }
}

.tabs-recipe__link {
  display: block;
  padding: rem(15) rem(15) rem(15) rem(50);
  position: relative;

  background-position: left 5px center;
  background-repeat: no-repeat;
  background-size: rem(35) auto;

  color: $body-color;
  font-weight: $font-weight-medium;
  &:hover,
  &:focus,
  &:active {
    color: inherit;
    text-decoration: none;
  }
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 50%; right: rem(5);
    transform: translateY(-50%);
    width: rem(8); height: rem(8);
    transition: all ease 250ms;

    background-image: url("../img/svg/icon-caret.svg");
    background-repeat: no-repeat;
  }
  .active & {
    &::after {
      transform: translateY(-50%) rotate(180deg);
    }
  }
}

@include media-breakpoint-up(md) {
  .tabs-recipe__link {
    padding-right: rem(30);
    .active & {
      background-color: $white;
      border-top: 1px solid $gray-1;
      border-right: 1px solid $gray-1;
      border-left: 1px solid $gray-1;
    }
  }
}

.tabs-recipe__link--preparation-time {
  background-image: url("../img/picto/picto-time.svg");
}
.tabs-recipe__link--cooking-time {
  background-image: url("../img/picto/picto-casserole.svg");
}
.tabs-recipe__link--difficulty {
  background-image: url("../img/picto/picto-difficulty.svg");
}
.tabs-recipe__link--dish {
  background-image: url("../img/picto/picto-cutlery.svg");
}
.tabs-recipe__link--typology {
  background-image: url("../img/picto/picto-typology.svg");
}

.tabs-recipe__panel {
  max-height: 0;
  overflow: hidden;
  transition: all ease 100ms;
  .active & {
    max-height: 50vh;
    overflow: auto;
    padding: rem(10);
    transition: all ease 250ms;
  }
}

@include media-breakpoint-up(md) {
  .tabs-recipe__panel {
    position: absolute;
    right: 0; left: 0;
    transition: all ease 100ms;
    .active & {
      margin-top: -2px;
      max-height: initial;
      z-index: 1;
      transition: all ease 100ms;

      background: $white;
      border-right: 1px solid $gray-1;
      border-bottom: 1px solid $gray-1;
      border-left: 1px solid $gray-1;

    }
  }

  .tabs-recipe {
    flex-direction: row;
  }
}
