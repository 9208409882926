// ==========================================================================
// PRODUCT COUNTER
// ==========================================================================

.counter-container {
  display: flex;
  align-items: center;
}

.counter-container__button {
  display: inline-block;
  width: $font-size-base * 1.5; height: $font-size-base * 1.5;
  padding: $circular-btn-padding;

  line-height: 1;
  text-align: center;
  &:hover,
  &:focus,
  &:active {
    background: $darkbrown;
    border-color: $darkbrown;
  }
  .counter-container--pink & {
    &:hover,
    &:focus,
    &:active {
      background: $pink;
      border-color: $pink;
    }
  }
}

.counter-container__number {
  margin:0 ($font-size-base * 0.5);
}
