// ==========================================================================
// Tables
// ==========================================================================

/*doc
---
title: Tableaux
name: tables
category: Base CSS
---

```html_example

<table class="table">
  <thead>
    <tr>
      <th scope="col">Produit</th>
      <th scope="col">QuantitÃ©</th>
      <th scope="col">Prix</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>
        <div class="product product-cart">
          <figure class="product__img">
            <img src="https://via.placeholder.com/135x115" alt/>
          </figure>
          <div class="product__body">
            <h2 class="product__title">GÃ©siers confits</h2>
            <p class="product__subtitle">Verrine Weck</p>
            <p class="product__ref">RÃ©f : 0156880438</p>
          </div>
        </div>
      </td>
      <td>5</td>
      <td>8.10 â¬</td>
    </tr>
    <tr>
      <td>
        <div class="product product-cart">
          <figure class="product__img">
            <img src="https://via.placeholder.com/135x115" alt/>
          </figure>
          <div class="product__body">
            <h2 class="product__title">GÃ©siers confits</h2>
            <p class="product__subtitle">Verrine Weck</p>
            <p class="product__ref">RÃ©f : 0156880438</p>
          </div>
        </div>
      </td>
      <td>5</td>
      <td>8.10 â¬</td>
    </tr>
    <tr>
      <td>
        <div class="product product-cart">
          <figure class="product__img">
            <img src="https://via.placeholder.com/135x115" alt/>
          </figure>
          <div class="product__body">
            <h2 class="product__title">GÃ©siers confits</h2>
            <p class="product__subtitle">Verrine Weck</p>
            <p class="product__ref">RÃ©f : 0156880438</p>
          </div>
        </div>
      </td>
      <td>5</td>
      <td>8.10 â¬</td>
    </tr>

  </tbody>
</table>
```

*/

.table {
  caption {
    caption-side: top;
  }
  margin: rem(20) 0;

  color: $darkbrown;
  font-weight: $font-weight-medium;
  thead {
    th {
      padding: rem(10) rem(5);

      border-top: none;

      font-size:rem(14);
      font-weight: $font-weight-medium;
      text-align: right;
      &:first-child {
        padding-left: 0;

        text-align: left;
      }
      &:first-child {
        padding-right: 0;
      }
    }
  }
  tbody {
    th {
      vertical-align: middle;
    }
    td {
      padding: rem(25) rem(8);

      text-align: right;
      &:first-child {
        text-align: left;
      }
    }
  }
  tr {
    border-bottom: 2px solid $gray-1;
  }
  &.table--no-margin {
    margin: 0;
  }
  &.table--total {
    tr {
      border-bottom: none;
    }
    tbody {
      th {
        border: none;
      }
      td {
        padding: 0 rem(10);
        border: none;
      }
    }
  }
}
