// ==========================================================================
// Breadcrumbs
// ==========================================================================

/*doc
---
title: Fil d'Ariane
name: breadcrumb
category: Modules
---

```html_example
<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a href="#">Accueil</a></li>
    <li class="breadcrumb-item"><a href="#">Foie Gras de canard</a></li>
    <li class="breadcrumb-item active" aria-current="page">Library</li>
  </ol>
</nav>
```

*/

.breadcrumb {
  position: relative;
  //z-index: 1;
  padding: rem(15) 0;

  background: none;
  border-radius: 0;

  font-size: $font-size-sm;
  color: $darkbrown;
}

.breadcrumb-item {
  & + & {
    &::before {
      content:"//";

      font-weight: $font-weight-base;
    }
  }
  &:last-child,
  &.active {
    a {
      font-weight: $font-weight-medium;
      color: inherit;
    }
  }
  a {
    color: inherit;
  }
}
