// ==========================================================================
// Crenelated effect on element
// ==========================================================================

/*doc
---
title: ElÃ©ments crÃ©nelÃ©s
name: Crenellatedelements
category: Modules
---

```html_example
<div style="background:#e5b122; padding: 20px;">
 <div class="crenellated crenellated--top crenellated--bottom">Lorem ipsum</div>
</div>
```

*/

%pl-pseudoelem {
  content: "";
  position:absolute;
  display: block;
  width: 100%; height: 5px;
  background-image: radial-gradient($skin 3px, transparent 0);
  background-size: 9px 12px;
  background-repeat: repeat-x;
}


.crenellated {
  position:relative;
  z-index: 0;

  background-color: $skin;

  &.crenellated--top {
    &::before {
      @extend %pl-pseudoelem;
      bottom: 100%;

      background-position: -7px 0px ;
    }
  }
  &.crenellated--bottom {
    &::after {
      @extend %pl-pseudoelem;

      background-position: -7px -7px ;
    }
  }
}

.crenellated--yellow {
  background-color: $yellow;
  &.crenellated--top {
    &::before {
      background-image: radial-gradient($yellow 3px, transparent 0);
    }
  }
  &.crenellated--bottom {
    &::after {
      background-image: radial-gradient($yellow 3px, transparent 0);
    }
  }
}

.crenellated--pink {
  background-color: $pink;
  color: $white;
  &.crenellated--top {
    &::before {
      background-image: radial-gradient($pink 3px, transparent 0);
    }
  }
  &.crenellated--bottom {
    &::after {
      background-image: radial-gradient($pink 3px, transparent 0);
    }
  }
}

.crenellated--skin {
  background-color: $skin;
  color: $white;
  &.crenellated--top {
    &::before {
      background-image: radial-gradient($skin 3px, transparent 0);
    }
  }
  &.crenellated--bottom {
    &::after {
      background-image: radial-gradient($skin 3px, transparent 0);
    }
  }
}

