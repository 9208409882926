// ==========================================================================
// Commands table
// ==========================================================================

.commands {
  p {
    margin: 0;
  }
}

.command__row {
  border-bottom: 2px solid $gray-3;
}

.commands__head,
.command__row__summary {
  padding: rem(5);
}

.command__row:nth-child(odd) {
  background: $gray-0;
}

.commands__head,
.command__row__summary {
  display: flex;
  .commands_cell {
    flex: 0 0 25%;

  }
}

.command__price {
  color: $pink;
  font-size: 1.2em;
  font-weight: bold;

}
