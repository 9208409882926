// ==========================================================================
// Datepicker
// ==========================================================================

#datepicker {
  * {
    font-family: "Andada", serif;
  }

  .ui-state-default {
    text-align: center;
    height: 30px;
    line-height: 30px;
    line-height: 24px;
  }

  .ui-state-default.ui-state-active {
    border-color: #c40040;
    background-color: #c40040;
  }

  .ui-state-disabled {
    opacity: .25;
  }

  .ui-datepicker {
    padding: unset;
  }

  .ui-widget.ui-widget-content {
    height: initial;
    background: #f4f1ea;
    //border: solid 1px #c40040;
  }

  .ui-datepicker-header {
    color: #222;
    background: white;
    border: none;
    //border: solid 1px #c40040;
  }
}
