// ==========================================================================
// Backgrounds
// ==========================================================================

/* Words */
.word-bg--gourmand {
  &::before {
    background-image: url("#{$path-img}/words/gourmand.png");
  }
}

.word-bg--inspiration {
  &::before {
    background-image: url("#{$path-img}/words/inspiration.png");
  }
}

.word-bg--inspi {
  &::before {
    background-image: url("#{$path-img}/words/inspi.png");
  }
}

.word-bg--savoirfaire {
  &::before {
    background-image: url("#{$path-img}/words/savoir-faire.png");
  }
}

.word-bg--foiegras {
  &::before {
    background-image: url("#{$path-img}/words/foie-gras.png");
  }
}

.word-bg--viandes {
  &::before {
    background-image: url("#{$path-img}/words/viandes.png");
  }
}

.word-bg--specialites {
  &::before {
    background-image: url("#{$path-img}/words/specialites.png");
  }
}

.word-bg--terroir {
  &::before {
    background-image: url("#{$path-img}/words/terroir.png");
  }
}

.word-bg--conseils {
  &::before {
    background-image: url("#{$path-img}/words/conseils.png");
  }
}

.word-bg--recettes {
  &::before {
    background-image: url("#{$path-img}/words/recettes2.png");
  }
}

.word-bg--preparation {
  &::before {
    background-image: url("#{$path-img}/words/prepa.png");
  }
}

.word-bg {
  position: relative;
  &::before {
    position: absolute;
    content: "";
    display: block;
    left: 10%;
    right: 0;
    top: 0;
    transform: translateY(-50%);
    height: rem(100);
    opacity: 0;
    z-index: -1;

    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: left center;
  }
  &.word-md-bg {
    &::before {
      display: none;
    }
  }
  &.word-bg--bottom {
    &::before {
      top: 100%;
      transform: translateY(-40%);
    }
  }
  &.word-bg--align-top {
    &::before {
      transform: translateY(0);
    }
  }
  &.word-bg--right {
    overflow: hidden;
    &.no-overflow {
      overflow: initial;
    }
    &::before {
      right: rem(-50);
      left: 0;
      background-position: right center;
    }
    &.animate {
      &::before {
        left: 0;
        right: 0;
      }
    }
  }

  &.word-bg--special-offers {
    &::before {
      top: rem(20);
      z-index: 0;
    }
  }
  &.animate {
    &::before {
      left: rem(-10);
      opacity: 1;
      transition: all ease 2000ms;
    }
  }
}

@include media-breakpoint-up(md) {
  .word-bg {
    &::before {
      height: rem(300);
      background-size: contain;
    }
    &.word-md-bg {
      &::before {
        display: block;
      }
    }
    &.word-xs-bg {
      &::before {
        display: none;
      }
    }
    &.word-bg--right {
      &::before {
        right: rem(-100);
      }
      &.animate {
        &::before {
          right: rem(-10);
        }
      }
    }

    &.word-bg--special-offers {
      &::before {
        top: 0;
      }
    }

    // Cas particulier Recettes et conseils
    &.word-bg--conseils-and-inspiration {
      &::before {
        top: rem(-30);
        left: -10%;
        width: 45%;
        height: rem(200);

        background-size: contain;
        background-image: url("#{$path-img}/words/conseils.png");
      }
      &::after {
        top: rem(-30);
        width: 45%;
        height: rem(200);
        position: absolute;
        content: "";
        display: block;
        right: -20%;
        opacity: 0;
        z-index: -1;

        background-size: contain;
        background-repeat: no-repeat;
        background-position: right center;
        background-image: url("#{$path-img}/words/inspi.png");
      }
      &.animate {
        &::before {
          left: 0;
        }
        &::after {
          right: -4%;
          opacity: 1;
          transition: all ease 2000ms;
        }
      }
    }
  }
}

// Comportement "Ã  la carte"
@include media-breakpoint-down(sm) {
  .word-bg {
    &.word-bg--sm-far-left {
      &::before {
        top: rem(-30);
        height: rem(100);

        background-position: top left;
        background-size: contain;
      }
    }

    &.word-bg--sm-far-right {
      &::before {
        top: rem(-30);
        height: rem(100);

        background-position: top right;
        background-size: contain;
      }
      &.animate {
        &::before {
          right: rem(-50);
        }
      }
    }
  }
}

@include media-breakpoint-only(sm) {
  .word-bg {
    &.word-bg--sm-far-left {
      &::before {
        left: rem(-100);
      }
    }

    &.word-bg--sm-far-right {
      &::before {
        right: rem(-150);
      }
      &.animate {
        &::before {
          right: rem(-110);
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .word-bg {
    &.word-bg--preparation {
      &::before {
        right: rem(-15);
        top: rem(20);
      }
    }
  }
}

.bg-skin {
  background: $skin;
}

.bg-white {
  background: $white;
}

.bg-black {
  background: $darkbrown;
}
