// ==========================================================================
// Bloc dÃ©couvrir la gamme
// ==========================================================================

/*doc
---
title: Bloc DÃ©couvrir une gamme de produits
name: bloclearnmore
category: Blocs
---

```html_example
<div style="width: 300px; margin: 0 0 30px;">
  <p>Composants bloc de base et texte : </p>
  <article class="product">
    <div class="product__wrapper">
      <figure class="product__img">
        <img src="https://via.placeholder.com/400x480" alt/>
      </figure>
      <div class="product__body">
         <h2 class="product__title product__title--classy">Canard maigre  </h2>
         <p class="product__text">A prÃ©parer soi-mÃªme, il est idÃ©al pour la rÃ©alisation de conserves, de terrines, mi-cuit et d'escalopes</p>
         <a href="" class=btn>DÃ©couvrir la gamme de canard maigre</a>
      </div>
    </div>
  </article>
</div>

<div style="width: 470px; margin: 30px 0">
  <p>Composants Image et lien : </p>
  <figure class="product__img gourmand-universe__img active" data-content="specialites-canard">
    <img src="https://via.placeholder.com/560x670" alt=""/>
  </figure>
  <div class="text-center">
    <a href="#" class="btn">Voir tous nos produits</a>
  </div>
</div>

<div style="margin:30px 0">
  <p>Composants Vignette : </p>
  <a href="#" class="gourmand-universe__thumbnail" data-content="canard-maigre">
    <figure class="product__img">
      <img src="https://via.placeholder.com/160x190" alt=""/>
      <figcaption class="product__figcaption">{{ 'Canard maigre'|trans }}</figcaption>
    </figure>
  </a>
</div>

```

*/

.product__title--classy {
  margin-bottom: rem(30); padding: rem(7) 0 rem(5);
  position: relative;

  background: linear-gradient(to top, $darkbrown, transparent 2px ) repeat-x bottom ;

  letter-spacing: 1.5px;
  line-height: 2;
  text-align: center;
  &::before {
    content: "";
    display: inline-block;
    height: 15px;
    position: absolute;
    left : 0; right: 0; bottom: 100%;

    background-image: linear-gradient(to top, $darkbrown, transparent 2px ), url("#{$path-img}/svg/icon-list.svg"), linear-gradient(to top, $darkbrown, transparent 2px ) ;
    background-position: bottom left, center, bottom right;
    background-repeat: no-repeat;
    background-size: 45% 2px, rem(15) rem(15), 45% 2px;
  }
}

// Bloc DÃ©couvrez les univers gourmands
.gourmand-universe__desc,
.gourmand-universe__img {
  display: none;
  &.active {
    display: block;
  }
}

@include media-breakpoint-up(md) {
  .gourmand-universe__img {
    &.product__img {
      img {
        height: 560px;
        object-fit: cover;
      }
    }
  }
}

.gourmand-universe__img {
  position: relative;
  z-index: 1;
}

.gourmand-universe__thumbnail {
  &.active {
    display: none;
  }
}

.product__img {
  .gourmand-universe__thumbnail & {
    margin-bottom: 0;
  }
}

@include media-breakpoint-up(md) {
  .gourmand-universe__img {
    position: relative;
    z-index: 1;
  }
  .gourmand-universe__thumbnails {
    display: flex;
    justify-content: space-around;
  }
  .gourmand-universe__thumbnail {
    flex: 0 1 30%;
  }
}
