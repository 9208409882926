// ==========================================================================
// Page panier
// ==========================================================================

.badge--cart {
  background: $gray-1;

  color: $body-color;
}

@include media-breakpoint-up(md) {
  .border-left-gray-md {
    border-left: 1px solid $gray-1;
  }
}

/* == Tableau pour panier */
.cart-container {
  display: flex;
  &--historic {
    margin: 0 rem(10);
    padding: 0 rem(5);
    max-height: rem(0);
    opacity: 0;
    overflow: hidden;
    transition: max-height linear 200ms, opacity linear 200ms;

    background: white;
    &.active {
      max-height: rem(1000);
      opacity: 1;

      border: 1px solid $gray-3;
    }
  }
}


// Structure de la ligne de chaque panier, avec l'image visible uniquement en mobile
.cart-table__picture {
  flex:0 0 20%;
  padding-right: rem(10);
}

@include media-breakpoint-up(md) {
  .cart-table__picture {
    //display: none;
  }
}

.cart-table__grid {
  flex:1 0 80%;
  display: flex;
  flex-flow: row wrap;
}

@include media-breakpoint-up(md) {
  .cart-table__grid {
    flex-wrap: nowrap;
  }
}

@include media-breakpoint-only(md) {
  .cart-table__grid {
    flex-wrap: nowrap;

    .cart-table__head-cell--product,
    .cart-table__body-cell--product {
      word-break: break-all;
    }
  }
}

// Structure de la partie "tableau"
.cart-table--row {
  display: flex;
  flex-flow: row wrap;
  padding: 0 0 rem(30);

  border-bottom: 2px solid $gray-1;
  & + & {
    padding-top: rem(30);
  }
  &:last-child {
    border-bottom: none;
  }
}

.order {
  .cart-table--row:last-child {
   // border-bottom: 2px solid $gray-1;
  }
}

.cart-table__body-cell--product {
  flex-basis: 80%;
}

.cart-table__body-cell--conservation {
  flex-basis: 20%;

  text-align: right;
}

.cart-table__body-cell--quantity,
.cart-table__body-cell--price,
.cart-table__body-cell--actions {
  padding-top: rem(10);
  padding-bottom: rem(10);
  flex-basis: 50%;
}

.cart-table__body-cell--quantity,
.cart-table__body-cell--price,
.cart-table__body-cell--total-price {
  font-size: rem(14);
}

.cart-price-chronofresh {
  font-size: rem(16);
  color: $pink;
  font-weight: normal;
  margin: rem(5) rem(0) rem(5) rem(115);
}

@include media-breakpoint-up(md) {
  .cart-table__body-cell--quantity,
  .cart-table__body-cell--price,
  .cart-table__body-cell--actions {
    padding-top: 0;
    padding-bottom: 0;
  }

  .cart-table__head-cell,
  .cart-table__body-cell {
    flex: 0 0 15%;
  }

  .cart-table__head-cell--product,
  .cart-table__body-cell--product {
    flex: 1 0 40%;
  }

  .cart-table__head-cell--actions,
  .cart-table__body-cell--actions {
    flex-grow: 1;
    flex-shrink: 1;
  }

  .cart-table__head-cell--quantity,
  .cart-table__body-cell--quantity {
    flex-basis: rem(90);
    flex-shrink: 0;
  }

  .cart-table__body-cell--quantity,
  .cart-table__body-cell--actions {
    padding-left: 0;
  }

  .cart-table__body-cell--conservation {
    flex-basis: auto;
  }

  .cart-table--in-my-account {
    .cart-table__head-cell,
    .cart-table__body-cell {
      flex: 0 0 15%;
    }

    .cart-table__head-cell--product,
    .cart-table__body-cell--product {
      flex: 1 0 55%;
    }

    .cart-table__body-cell--quantity,
    .cart-table__body-cell--price,
    .cart-table__body-cell--total-price {
      font-weight: $font-weight-medium;
    }
  }

  .cart-price-chronofresh {
    margin-left: rem(5);
  }
}

.cart-table__body-cell--price {
  text-align: right;
}

.cart-table__body-cell--product {
  .product-cart {
    .product__body {
      padding-top: 0; margin-bottom: 0;

    }
    .product__img {
      display: none;
    }
  }
}

@include media-breakpoint-down(md) {
  .cart-table__body-cell--product {
    .product-cart {
      .product__body {
        width: initial;
      }
    }
  }
}

.cart-table__total {
  font-weight: 500;
}

.counter-container__button {
  .cart-table__body-cell--quantity & {
    width: $font-size-base * 2; height: $font-size-base * 2;
    line-height: 1.5;
  }
}


.cart-table__head {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
}

@include media-breakpoint-up(md) {

  .cart-table__body-cell {
    .product__body {
      padding-left: 0;
    }
  }

  .cart-table__body-cell--quantity {
    padding-left: rem(20);
  }

  .cart-table__head {
    position: static;
    width: initial; height: initial;
    padding: initial;
    overflow: initial;
    clip: initial;
    white-space: initial;
    display: flex;
    border-bottom: 2px solid $gray-1;
  }

  .cart-table__head-cell {
    padding: rem(10) rem(5);
    margin: 0;

    border-top: none;

    font-size:rem(14);
    font-weight: $font-weight-medium;
    text-align: right;
    &:first-child {
      padding-left: 0;

      text-align: left;
    }
    &:first-child {
      padding-right: 0;
    }
  }

  .cart-table--row {
    flex-wrap: nowrap;
    padding: rem(20) 0;
  }

  .cart-table__head-cell--quantity,
  .cart-table__body-cell--quantity {
    &.md-order-4 {
      order: 4;
      flex-basis: auto;
    }
  }
  .cart-table__head-cell--price,
  .cart-table__body-cell--price {
    &.md-order-5 {
      order: 5;
    }
  }
  .cart-table__head-cell--actions,
  .cart-table__body-cell--actions {
    &.md-order-3 {
      order: 3;
    }
  }

  .cart-table__body-cell--quantity {
    &.md-order-4 {
      .counter-container {
        justify-content: flex-end;
      }
    }
  }
}


@include media-breakpoint-up(xl) {

  .cart-table__body-cell {
    .product__body {
      padding-left: rem(20);
    }
  }

  .cart-table__body-cell--product {
    .product-cart {
      .product__img {
        display: inline-block;
      }
    }
  }

  .cart-table__body-cell--conservation {
    flex-basis: 15%;
  }
}


