// ==========================================================================
// panel
// ==========================================================================

body.panel-open {
  overflow: hidden;
}

.panel {
  &__close {
    display: none;
  }
}

@include media-breakpoint-down(sm) {

  //left to right by default

  .panel {

    &__content {
      position: fixed;
      transform: translateX(-100%);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: transform 0.4s;
      background-color: $white;
      padding: rem(40);
      z-index: 1;
    }

    &.is-open {
      .panel {
        &__content {
          transform: translateX(0);
          overflow-y: scroll;
        }

        &__header {
          transform: translateX(0);
        }
      }
    }

    // right to left
    &--right {
      .panel {
        &__content {
          transform: translateX(100%);
        }

        &__header {
          transform: translateX(100%);
        }
      }
    }

    &__header {
      transform: translateX(-100%);
      transition: transform 0.4s;
      position: fixed;
      top: rem(65);
      right: rem(20);
      z-index: 10;
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }

    &__close {
      display: block;
      width: rem(20); height: rem(20);

      background: $white;
    }
  }
}
