// ==========================================================================
// steps
// ==========================================================================

.steps {
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  padding: rem(40) 0;
  flex-grow: 1;
  justify-content: space-between;

  &::before {
    content: "";
    width: 100%;
    height: rem(1);
    position: absolute;
    border-top: rem(1) dashed $gray-2;
    top: rem(57);
    z-index: -3;
  }

}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &:first-child {
    &::before {
      content: "";
      width:50%;
      height: 100%;
      background-color: $skin;
      position: absolute;
      left: 0;
      z-index: -2;
    }
  }

  &:last-child {
    &::before {
      content: "";
      width:50%;
      height: 100%;
      background-color: $skin;
      position: absolute;
      left: 50%;
      z-index: -2;
    }
  }

  &:hover {
    cursor: pointer;
    text-decoration: none;
  }

  &__number {
    line-height: rem(30);
    font-size: rem(18);
    width: rem(35);
    height: rem(35);
    border-radius: 50%;
    color: $white;
    background-color: $black;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: rem(20);
    position: relative;
  }

 &__title {
   text-transform: uppercase;
   font-weight: 400;
   color: $black;
   display: none;
 }

  &--active {


    .step {
      &__number {

        background-color: $pink;

        &::before{
          content: "";
          display: block;
          width: rem(47);
          height: rem(47);
          border-radius: 50%;
          position: absolute;
          background-color: $white;
          z-index: -1;
        }

        &::after{
          content:"";
          display: block;
          width: 0;
          height: 0;
          border-left: rem(5) solid transparent;
          border-right: rem(5) solid transparent;
          border-top: rem(10) solid $white;
          position: absolute;
          bottom: rem(-15);
          z-index: -1;
        }
      }

      &__title {
        display: block;
        color: $pink;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .steps {
    flex-grow: 0;
  }

  .step {
    margin: 0 rem(15);

    &__title {
      display: block;
    }


    &:first-child{
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
