// ==========================================================================
// Button
// ==========================================================================

// Any specific button style is welcome here!

/*----------  Delete  ----------*/
.btn-icon, .btn-icon:visited {
  padding: 0;
  width: rem(15);
  height: rem(20);

  background: none;
  border: 0;
}

.btn-favorite {
  .icon-star-fill {
    display: none;
    .wishlist & {
      display: inline-block;
    }
  }
  &:hover,
  &:focus,
  &:active,
  &.added-to-favorite {
    .icon-star {
      display: none;
    }
    .wishlist & {
      display: inline-block;
    }
    .icon-star-fill {
      display: inline-block;
    }
  }
}

/*----------  Entry  ----------*/
.btn-entry, .btn-entry:visited {
  display: inline-block;
  width: rem(40);
  height: rem(40);

  &:hover, &:focus {
    .icon-entry-circle {
      fill: $darkbrown; // @todo use variable
    }
    .icon-entry-arrow {
      stroke: $white; // @todo use variable
    }
  }
}

.scroll-top-btn {
  display: none;
  &.active {
    display: block;
    position: fixed;
    top: 75%; right: 0;
    transform: translateY(-50%);
    padding: rem(10);
    z-index: 1;

    background: $pink;
    border-top-left-radius: rem(5);
    border-bottom-left-radius: rem(5);
    border: none;
    svg {
      width: rem(35); height: rem(35);
      transform: rotate(-90deg);
      path {
        stroke: white;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .scroll-top-btn {
    top: 50%;
    svg {
      width: rem(50); height: rem(50);
    }
  }
}
