// ==========================================================================
// CMS
// ==========================================================================

/**
 * Tous les styles de ce module ciblent les contenus contribuÃ©s par l'utilisateur (contenus et blocs).
 *
 * Ce qui implique qu'on ne peut pas cibler les Ã©lÃ©ments par des classes, mais via un
 * sÃ©lecteur d'Ã©lÃ©ments (.wysiwyg h2, .wysiwyg a...).
 *
 * Pour les contenus, il faut englober le contenu par la classe `.wysiwyg`.
 */

/*doc
---
title: WYSIWYG
name: wysiwyg
category: Modules
---

```html_example
<div class="wysiwyg">
<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. <i>Num quid tale Democritus?</i> Quod totum contra est. Satisne ergo pudori consulat, si quis sine teste libidini pareat? Duo Reges: constructio interrete. </p>

<ul>
	<li>Non enim, si omnia non sequebatur, idcirco non erat ortus illinc.</li>
	<li>Ad eos igitur converte te, quaeso.</li>
	<li>Graecis hoc modicum est: Leonidas, Epaminondas, tres aliqui aut quattuor;</li>
	<li>Illa videamus, quae a te de amicitia dicta sunt.</li>
</ul>

<ol>
	<li>Non enim, si omnia non sequebatur, idcirco non erat ortus illinc.</li>
	<li>Ad eos igitur converte te, quaeso.</li>
	<li>Graecis hoc modicum est: Leonidas, Epaminondas, tres aliqui aut quattuor;</li>
	<li>Illa videamus, quae a te de amicitia dicta sunt.</li>
</ol>

<p>Multoque hoc melius nos veriusque quam Stoici. <a href="">Obsecro, inquit, Torquate, haec dicit Epicurus?</a> Prioris generis est docilitas, memoria; Age, inquies, ista parva sunt. Dicimus aliquem hilare vivere; Sed ille, ut dixi, vitiose. </p>

<figure>
  <img src="../styleguide/img/cuttie_8_medium.png" alt="">
  <figcaption>LÃ©gende de la photo ee</figcaption>
</figure>

<p>At ille pellit, qui permulcet sensum voluptate. <b>Hoc ipsum elegantius poni meliusque potuit.</b> Tum Piso: Quoniam igitur aliquid omnes, quid Lucius noster? Certe, nisi voluptatem tanti aestimaretis. </p>
</div>
```

*/

.wysiwyg {
  font-size: rem(16);

  p {
    line-height: 1.7;
  }

  h1, .h1 {
    font-size: rem(40);
  }

  h2, .h2 {
    font-size: rem(25);
  }

  ul,
  ol {
    margin: rem(15) 0; padding: 0;
    list-style-type: none;
  }

  li {
    padding: rem(5) rem(5) rem(5) rem(35);
    position: relative;
    &::before {
      position: absolute;
      top: 50%; left: 0;
      transform: translateY(-50%);
    }
  }

  ul {
    li {
      &::before {
        content: "";
        display: inline-block;
        margin-left: rem(10);
        width: rem(5); height: rem(4);

        background: $darkbrown;
        border-radius: 4px;
      }
    }
  }

  /* == Ordered list */
  ol {
    counter-reset: ordered-item;
    li {
      &::before {
        counter-increment: ordered-item;
        content: counter(ordered-item);
        display: inline-block;
        width: rem(20); height: rem(20);

        background: $skin;
        border-radius: 50%;

        font-size: rem(12);
        line-height: rem(20);
        text-align: center;
      }
    }
  }

  a {
    position: relative;

    background-image: linear-gradient(to top, $yellow 30%, transparent 0);
    background-repeat: repeat-y;

    color: inherit;
    text-decoration: none;
    white-space: nowrap;
    transition: all ease 250ms;
    z-index: 1;

  }

  figcaption {
    margin-top: rem(8);
    font-size: rem(12);
  }
  strong {
    font-weight: $font-weight-medium;
  }
}

@include media-breakpoint-up(md) {
  .wysiwyg {
    a {
      &::before {
        content: "";
        display: inline-block;
        height: 30%;
        position: absolute;
        left: 0; bottom:0; right:0;
        transition: height ease 100ms;
        z-index: -1;

        background: $yellow;
      }
      &:hover,
      &:focus,
      &:active {
        &::before {
          height: 75%;
        }
      }
    }
  }
}

/* == Ordered list */
.ordered-list {
  margin: rem(15) 0; padding: 0;
  list-style-type: none;
  line-height: 1.5;
  counter-reset: ordered-item;
  li {
    position: relative;
    padding-left: rem(30);

    &::before {
      position: absolute;
      left: 0;

      counter-increment: ordered-item;
      content: counter(ordered-item);
      display: inline-block;
      width: rem(20); height: rem(20);

      background: $skin;
      border-radius: 50%;

      font-size: rem(12);
      line-height: rem(20);
      text-align: center;
    }
  }
}

.ordered-list__item {
  & + & {
    margin-top: rem(20);
  }
}

.anchor{
  display: block;
  height: 115px;
  margin-top: -115px;
  visibility: hidden;
}
