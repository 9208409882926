// ==========================================================================
// Quick-add product
// ==========================================================================

/*doc
---
title: Quick-add
category: Shop
---

```html_example
<div style="margin: 30px; width: 320px;">
  <article class="product product-quick-add">
    <div class="product__wrapper">
      <div class="product__head">
        <span class="product__tag">SÃ©lection NoÃ«l</span>
        <span class="product__badge">4Â°C</span>
      </div>

      <div class="quick-add-body">
        <figure class="product__img quick-add-body__item">
          <img src="https://placehold.it/260x150" alt/>
        </figure>

        <div class="quick-add-body__item">
          <h2 class="product__title">Foie gras de canard entier</h2>
          <p class="product__subtitle">Verrine Weck</p>

          <ul class="list-inline">
            <li class="list-inline-item">
              <span class="badge badge-pill">50g</span>
            </li>
            <li class="list-inline-item">
              <span class="badge badge-pill">90g</span>
            </li>
            <li class="list-inline-item">
              <span class="badge badge-pill">110g</span>
            </li>
          </ul>

          <div class="product__foot">
            <span class="product__price">8,19 â¬ <small>(118,60 â¬ / kg)</small></span>
            <a href="#" class="btn product__link">Ajouter</a>
          </div>
        </div>
      </div>
    </div>
  </article>
</div>

<div style="margin: 30px; width: 640px;">
  <article class="product product-quick-add product-quick-add--selection">
    <div class="product__wrapper">
      <div class="product__head">
        <span class="product__selection-title">La sÃ©lection Ernest Soulard</span>
        <span class="product__tag">SÃ©lection NoÃ«l</span>
      </div>

      <div class="quick-add-body">
        <figure class="product__img quick-add-body__item">
          <img src="https://placehold.it/300x300" alt/>
        </figure>

        <div class="quick-add-body__item">
          <span class="product__badge">4Â°C</span>
          <h2 class="product__title">Foie gras de canard entier</h2>
          <p class="product__subtitle">Verrine Weck</p>

          <p class="product__alert">Produit indisponible</p>

          <div class="product__foot">
            <span class="product__price">8,19 â¬ <small>(118,60 â¬ / kg)</small></span>
            <a href="#" class="btn product__link">Ajouter</a>
          </div>
        </div>
      </div>
    </div>
  </article>
</div>
```

*/

// @include border-animation('.product-quick-add');

.product-quick-add {
  padding: rem(20) rem(30) rem(30);
  background: $white;
  border: rem(1) solid;
  border-color: white;
  transition: border-color 0.4s ease-in;

  &.is-hover {
    border-color: $gray-1;
  }
}

@include media-breakpoint-up(md) {
  .gourmand-selection {
    .product-quick-add {
      background: transparent;
    }
  }
}

.product__selection-title {
  display: none;

  font-size: rem(20);
  font-weight: $font-weight-medium;
  text-transform: uppercase;
}

.quick-add-body__product-link {
  &:hover,
  &:focus,
  &:active {
    color: inherit;
    text-decoration: none;
  }
}

.product__img {
  .quick-add-body & {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: rem(5);
    max-width: rem(320);
    .gourmand-selection & {
      max-width: inherit;
    }
  }
}

.product__container {
  &--child-h100 .product {
    height: 100%;
  }
}

// Lorsque le produit est mis en avant
.product-quick-add--selection {
  padding: rem(30);

  background-color: $skin;
  background-image: url("#{$path-img}/fond-pattes-de-canard-seules.jpg");
  background-repeat: no-repeat;
  background-size: cover;

  .product__wrapper {
    padding: rem(25);
    background-color: $white;
  }
  .product__head {
    display: flex;
    justify-content: space-between;
    padding-bottom: rem(20);

    border-bottom: 1px solid $gray-1;
    .product__selection-title {
      display: inline-block;
    }
    .product__badge {
      display: none;
    }
  }
  .quick-add-body {
    display: flex;
    flex-direction: column;
  }
  .quick-add-body__item {
    position: relative;
    flex-grow: 1;
    align-self: center;
    &:last-child {
      padding-left: rem(10);
    }
    .product__title,
    .product__subtitle {
      padding-right: rem(45);
    }
    .product__badge {
      position: absolute;
      top: 0; right: 0;
    }
  }

  .quick-add-body__description {
    margin-top: rem(10);
  }

  .product__img {
    max-width: none;
    margin: 0;
  }
  .product__foot {
    display: block;
  }
  .product__price {
    display: block;
    margin-bottom: $font-size-base;
  }

  .product__price-container {
    flex-direction: column;
  }
}

@include media-breakpoint-up(md) {
  .product-quick-add--selection {
    .quick-add-body__description {
      margin-left: rem(10);
      margin-top: 0;
    }

    .quick-add-body {
      flex-direction: row;
    }

    .quick-add-body__product-link {
      max-width: 50%;
    }
  }
}
