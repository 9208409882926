// ==========================================================================
// Pictos for reinsurance or other purpose
// ==========================================================================

/*doc
---
title: Pictos
name: Pictos
category: Modules
---

```html_example
<div style="background: #e5b122; padding: 20px;">
  <div class="picto">
    <svg xmlns="http://www.w3.org/2000/svg" class="picto-casserole" enable-background="new -597 392 105 117" viewBox="-597 392 105 117">
      <g transform="translate(-1 -.48)">
        <defs>
          <filter id="a" width="104" height="116.5" x="-595.3" y="392.7" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="b" width="104" height="116.5" x="-595.3" y="392.7" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-595.3 392.7h104v116.5h-104V392.7z" filter="url(#a)"/>
        </mask>
        <path fill="#FEFEFE" d="M-491.3 443.7c-.4-28.6-24-51.4-52.7-51-28.7.4-51.7 23.9-51.3 52.4.4 25.4 19.1 46.3 43.4 50.3l9.5 13.8 9.1-14c24.2-4.7 42.4-26.1 42-51.5" mask="url(#b)"/>
      </g>
      <path fill="#E5B122" d="M-503.8 443c-.3-22.1-18.6-39.9-40.9-39.5-22.3.3-40.1 18.5-39.8 40.6.3 19.3 14.2 35.2 32.5 38.8l8.6 12.4 8.2-12.6c18.2-4.2 31.7-20.5 31.4-39.7"/>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="c" width="78.8" height="68.8" x="-585" y="399" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="d" width="78.8" height="68.8" x="-585" y="399" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#c)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-506.7 399.4c-17.8 15.6-60 52.3-77.9 67.9" mask="url(#d)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="e" width="83.9" height="73.5" x="-588.2" y="400.3" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="f" width="83.9" height="73.5" x="-588.2" y="400.3" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#e)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-504.8 400.7c-18 15.4-65.1 57-83 72.6" mask="url(#f)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="g" width="84.4" height="73.8" x="-586.8" y="401.6" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="h" width="84.4" height="73.8" x="-586.8" y="401.6" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#g)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-502.9 402.1c-17.7 15.9-65.6 57.1-83.5 72.9" mask="url(#h)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="i" width="82.2" height="71.7" x="-582.8" y="403" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="j" width="82.2" height="71.7" x="-582.8" y="403" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#i)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-501.1 403.4c-18 15.7-63.3 55-81.3 70.8" mask="url(#j)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="k" width="78.8" height="68.8" x="-591.4" y="392.8" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="l" width="78.8" height="68.8" x="-591.4" y="392.8" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#k)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-513.1 393.2c-17.8 15.6-60 52.3-77.9 67.9" mask="url(#l)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="m" width="83.9" height="73.5" x="-594.7" y="394.1" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="n" width="83.9" height="73.5" x="-594.7" y="394.1" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#m)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-511.2 394.5c-18 15.4-65.1 57-83 72.6" mask="url(#n)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="o" width="84.4" height="73.8" x="-593.3" y="395.4" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="p" width="84.4" height="73.8" x="-593.3" y="395.4" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#o)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-509.4 395.8c-17.7 15.9-65.6 57.1-83.5 72.9" mask="url(#p)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="q" width="82.2" height="71.7" x="-589.2" y="396.7" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="r" width="82.2" height="71.7" x="-589.2" y="396.7" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#q)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-507.5 397.2c-17.9 15.7-63.3 55-81.3 70.8" mask="url(#r)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="s" width="78.8" height="68.8" x="-597.9" y="386.5" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="t" width="78.8" height="68.8" x="-597.9" y="386.5" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#s)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-519.5 387c-17.8 15.6-60 52.3-77.9 67.9" mask="url(#t)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="u" width="83.9" height="73.5" x="-601.1" y="387.9" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="v" width="83.9" height="73.5" x="-601.1" y="387.9" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#u)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-517.7 388.3c-18 15.4-65.1 57-83 72.6" mask="url(#v)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="w" width="84.4" height="73.8" x="-599.7" y="389.2" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="x" width="84.4" height="73.8" x="-599.7" y="389.2" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#w)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-515.8 389.6c-17.7 15.9-65.6 57.1-83.5 72.9" mask="url(#x)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="y" width="82.2" height="71.7" x="-595.6" y="390.5" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="z" width="82.2" height="71.7" x="-595.6" y="390.5" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#y)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-513.9 391c-17.9 15.8-63.3 55-81.3 70.8" mask="url(#z)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="A" width="78.8" height="68.8" x="-604.3" y="380.3" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="B" width="78.8" height="68.8" x="-604.3" y="380.3" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#A)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-526 380.8c-17.8 15.6-60 52.3-77.9 67.9" mask="url(#B)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="C" width="83.9" height="73.5" x="-607.5" y="381.6" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="D" width="83.9" height="73.5" x="-607.5" y="381.6" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#C)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-524.1 382.1c-18 15.4-65.1 57-83 72.6" mask="url(#D)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="E" width="84.4" height="73.8" x="-606.1" y="383" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="F" width="84.4" height="73.8" x="-606.1" y="383" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#E)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-522.2 383.4c-17.7 15.9-65.6 57.1-83.5 72.9" mask="url(#F)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="G" width="82.2" height="71.7" x="-602.1" y="384.3" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="H" width="82.2" height="71.7" x="-602.1" y="384.3" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#G)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-520.4 384.7c-17.9 15.8-63.3 55-81.3 70.8" mask="url(#H)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="I" width="78.8" height="68.8" x="-610.7" y="374.1" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="J" width="78.8" height="68.8" x="-610.7" y="374.1" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#I)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-532.4 374.5c-17.8 15.6-60 52.3-77.9 67.9" mask="url(#J)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="K" width="83.9" height="73.5" x="-614" y="375.4" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="L" width="83.9" height="73.5" x="-614" y="375.4" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#K)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-530.5 375.9c-18 15.4-65.1 57-83 72.6" mask="url(#L)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="M" width="84.4" height="73.8" x="-612.6" y="376.7" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="N" width="84.4" height="73.8" x="-612.6" y="376.7" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#M)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-528.7 377.2c-17.7 15.9-65.6 57.1-83.5 72.9" mask="url(#N)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="O" width="82.2" height="71.7" x="-608.5" y="378.1" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="P" width="82.2" height="71.7" x="-608.5" y="378.1" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#O)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-526.8 378.5c-17.9 15.8-63.3 55-81.3 70.8" mask="url(#P)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="Q" width="78.8" height="68.8" x="-617.2" y="367.9" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="R" width="78.8" height="68.8" x="-617.2" y="367.9" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#Q)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-538.8 368.3c-17.8 15.6-60 52.3-77.9 67.9" mask="url(#R)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="S" width="83.9" height="73.5" x="-620.4" y="369.2" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="T" width="83.9" height="73.5" x="-620.4" y="369.2" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#S)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-537 369.6c-18 15.4-65.1 57-83 72.6" mask="url(#T)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="U" width="84.4" height="73.8" x="-619" y="370.5" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="V" width="84.4" height="73.8" x="-619" y="370.5" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#U)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-535.1 371c-17.7 15.9-65.6 57.1-83.5 72.9" mask="url(#V)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="W" width="82.2" height="71.7" x="-614.9" y="371.8" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="X" width="82.2" height="71.7" x="-614.9" y="371.8" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#W)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-533.2 372.3c-18 15.8-63.3 55-81.3 70.8" mask="url(#X)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="Y" width="78.8" height="68.8" x="-623.6" y="361.6" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="Z" width="78.8" height="68.8" x="-623.6" y="361.6" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#Y)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-545.3 362.1c-17.8 15.6-60 52.3-77.9 67.9" mask="url(#Z)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="aa" width="83.9" height="73.5" x="-626.8" y="363" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="ab" width="83.9" height="73.5" x="-626.8" y="363" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#aa)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-543.4 363.4c-18 15.4-65.1 57-83 72.6" mask="url(#ab)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="ac" width="84.4" height="73.8" x="-625.5" y="364.3" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="ad" width="84.4" height="73.8" x="-625.5" y="364.3" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#ac)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-541.5 364.7c-17.7 15.9-65.6 57.1-83.5 72.9" mask="url(#ad)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="ae" width="82.2" height="71.7" x="-621.4" y="365.6" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="af" width="82.2" height="71.7" x="-621.4" y="365.6" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#ae)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-539.7 366.1c-17.9 15.8-63.3 55-81.3 70.8" mask="url(#af)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="ag" width="78.8" height="68.8" x="-630" y="355.4" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="ah" width="78.8" height="68.8" x="-630" y="355.4" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#ag)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-551.7 355.9c-17.8 15.6-60 52.3-77.9 67.9" mask="url(#ah)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="ai" width="83.9" height="73.5" x="-633.3" y="356.7" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="aj" width="83.9" height="73.5" x="-633.3" y="356.7" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#ai)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-549.8 357.2c-18 15.4-65.1 57-83 72.6" mask="url(#aj)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="ak" width="84.4" height="73.8" x="-631.9" y="358.1" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="al" width="84.4" height="73.8" x="-631.9" y="358.1" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#ak)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-548 358.5c-17.7 15.9-65.6 57.1-83.5 72.9" mask="url(#al)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="am" width="82.2" height="71.7" x="-627.8" y="359.4" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="an" width="82.2" height="71.7" x="-627.8" y="359.4" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#am)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-546.1 359.8c-17.9 15.7-63.3 55-81.3 70.8" mask="url(#an)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="ao" width="78.8" height="68.8" x="-577.5" y="404.2" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="ap" width="78.8" height="68.8" x="-577.5" y="404.2" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#ao)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-499.2 404.7c-18.2 15.6-60 52.2-77.9 67.9" mask="url(#ap)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="aq" width="81.9" height="71.7" x="-579" y="405.8" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="ar" width="81.9" height="71.7" x="-579" y="405.8" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#aq)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-497.5 406.2c-18.1 15.7-63.1 55.1-81 70.8" mask="url(#ar)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="as" width="78.9" height="69.2" x="-574.6" y="407.5" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="at" width="78.9" height="69.2" x="-574.6" y="407.5" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#as)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-496.1 407.9c-17.7 15.6-60.1 52.7-78 68.3" mask="url(#at)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="au" width="73.9" height="64.7" x="-568.5" y="409.4" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="av" width="73.9" height="64.7" x="-568.5" y="409.4" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#au)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-495 409.9c-17.5 15.4-55.5 48.4-73 63.8" mask="url(#av)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="aw" width="72.8" height="63.7" x="-566.3" y="411.4" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="ax" width="72.8" height="63.7" x="-566.3" y="411.4" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#aw)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-493.9 411.9c-17 15.1-54.6 48-71.9 62.9" mask="url(#ax)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="ay" width="66.1" height="58.1" x="-558.7" y="413.6" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="az" width="66.1" height="58.1" x="-558.7" y="413.6" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#ay)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-493 414.1c-16.9 14.5-48.6 42.6-65.2 57.2" mask="url(#az)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="aA" width="64.7" height="56.8" x="-556.4" y="415.8" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="aB" width="64.7" height="56.8" x="-556.4" y="415.8" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#aA)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-492.1 416.2c-16.5 13.9-47.5 41.9-63.8 55.9" mask="url(#aB)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="aC" width="62.9" height="55.2" x="-553.7" y="418" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="aD" width="62.9" height="55.2" x="-553.7" y="418" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#aC)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-491.3 418.5c-15.5 13.8-46.2 40.6-62 54.3" mask="url(#aD)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="aE" width="70.4" height="61.7" x="-560.4" y="420.2" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="aF" width="70.4" height="61.7" x="-560.4" y="420.2" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#aE)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-490.4 420.7c-14.8 13.4-54.5 47.8-69.5 60.9" mask="url(#aF)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="aG" width="67.7" height="59.4" x="-557.1" y="422.6" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="aH" width="67.7" height="59.4" x="-557.1" y="422.6" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#aG)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-489.8 423.1c-14.3 12.1-52.7 46.2-66.8 58.5" mask="url(#aH)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="aI" width="64.9" height="56.8" x="-553.8" y="425.2" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="aJ" width="64.9" height="56.8" x="-553.8" y="425.2" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#aI)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-489.3 425.6c-13.4 11.4-50.9 44.6-64 55.9" mask="url(#aJ)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="aK" width="61.5" height="53.9" x="-549.8" y="427.6" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="aL" width="61.5" height="53.9" x="-549.8" y="427.6" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#aK)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-488.7 428c-12.2 10.3-48.6 42.4-60.7 53" mask="url(#aL)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="aM" width="50.8" height="44.7" x="-538.6" y="430.1" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="aN" width="50.8" height="44.7" x="-538.6" y="430.1" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#aM)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-488.3 430.6c-10.4 9.6-39.5 34.5-49.9 43.8" mask="url(#aN)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="aO" width="47.7" height="41.8" x="-535" y="432.6" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="aP" width="47.7" height="41.8" x="-535" y="432.6" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#aO)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-487.8 433.1c-9.5 8.2-37.3 32.6-46.8 40.9" mask="url(#aP)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="aQ" width="45" height="39.5" x="-531.9" y="435.2" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="aR" width="45" height="39.5" x="-531.9" y="435.2" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#aQ)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-487.4 435.7c-8.8 7.4-35.3 31.4-44.1 38.6" mask="url(#aR)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="aS" width="42.7" height="37.6" x="-529.7" y="438.2" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="aT" width="42.7" height="37.6" x="-529.7" y="438.2" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#aS)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-487.4 438.6c-7.9 7-33.9 29.8-41.8 36.7" mask="url(#aT)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="aU" width="38.2" height="33.7" x="-525.4" y="441.4" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="aV" width="38.2" height="33.7" x="-525.4" y="441.4" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#aU)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-487.6 441.8c-12.4 11-24.9 21.9-37.3 32.8" mask="url(#aV)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="aW" width="36.4" height="32.4" x="-523.6" y="444.3" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="aX" width="36.4" height="32.4" x="-523.6" y="444.3" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#aW)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-487.6 444.7c-5.4 4.7-30.2 26.9-35.5 31.5" mask="url(#aX)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="aY" width="34.9" height="31" x="-521.5" y="446.7" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="aZ" width="34.9" height="31" x="-521.5" y="446.7" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#aY)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-487 447.2c-4.9 4.2-29.2 25.9-34 30.1" mask="url(#aZ)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="ba" width="33.5" height="29.9" x="-519" y="448.8" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="bb" width="33.5" height="29.9" x="-519" y="448.8" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#ba)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-486 449.3c-4.4 3.8-28.2 25.1-32.6 29" mask="url(#bb)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="bc" width="32.2" height="28.7" x="-516.3" y="450.5" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="bd" width="32.2" height="28.7" x="-516.3" y="450.5" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#bc)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-484.5 450.9c-4 3.5-27.4 24.4-31.3 27.9" mask="url(#bd)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="be" width="30.3" height="26.7" x="-512.8" y="452" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="bf" width="30.3" height="26.7" x="-512.8" y="452" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#be)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-482.9 452.5c-3.3 2.8-26.2 23-29.4 25.8" mask="url(#bf)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="bg" width="28.7" height="25.3" x="-509.3" y="453.3" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="bh" width="28.7" height="25.3" x="-509.3" y="453.3" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#bg)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-481 453.8c-2.5 2.2-25.3 22.2-27.8 24.4" mask="url(#bh)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="bi" width="28.7" height="25.3" x="-507.7" y="454.8" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="bj" width="28.7" height="25.3" x="-507.7" y="454.8" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#bi)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-479.5 455.3c-2.5 2.2-25.3 22.2-27.8 24.4" mask="url(#bj)"/>
      </g>
      <g transform="translate(13.508 10.338)">
        <defs>
          <filter id="bk" width="28.7" height="25.3" x="-506.2" y="456.3" filterUnits="userSpaceOnUse">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
          </filter>
        </defs>
        <mask id="bl" width="28.7" height="25.3" x="-506.2" y="456.3" maskUnits="userSpaceOnUse">
          <path fill="#FFF" d="M-560.9 395.4v.7c-15.4 1.2-26.1 6.1-26.1 12v7.4c0 1.5.7 3 2.1 4.3-5.5.5-10.8 2.3-10.8 5.4 0 .4.1.8.3 1.2.9 7.3 9 8.5 11.2 8.7v12.2c0 8 13.5 14.3 30.7 14.3s30.7-6.3 30.7-14.3v-12.2c2.1-.2 10.4-1.4 11.3-8.7.2-.4.3-.8.3-1.2 0-3.1-5.4-4.9-11-5.4 1.3-1.3 2.1-2.8 2.1-4.3v-6.9-.2-.4c0-5.9-10.7-10.8-26.1-12v-.6c0-2.1-3.8-3.1-7.2-3.1-3.8 0-7.5 1-7.5 3.1" filter="url(#bk)"/>
        </mask>
        <path fill="none" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" stroke-width=".891" d="M-477.9 456.8c-2.5 2.2-25.3 22.2-27.8 24.4" mask="url(#bl)"/>
      </g>
      <g fill="none" stroke="#120C0E" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.741">
        <path d="M-537.4 410.8c14.9 1.1 26.1 5.7 26.1 11.2 0 6.3-14.5 11.4-32.5 11.4s-32.5-5.1-32.5-11.4c0-5.5 11.1-10.1 25.9-11.2m37.3 23.8c6.1.4 10.8 2.3 10.8 4.6s-4.9 4.3-11.3 4.7"/>
        <path d="M-511.3 422.6v6.9c0 6.3-14.5 11.4-32.5 11.4-17.9 0-32.5-5.1-32.5-11.4v-6.9"/>
        <path d="M-573.6 434.4v26.9c0 7.4 13.3 13.4 29.8 13.4s29.8-6 29.8-13.4v-27.1m-23.4-24.8c0 1.2-2.9 2.2-6.4 2.2-3.5 0-6.4-1-6.4-2.2 0-1.2 2.9-2.2 6.4-2.2s6.4.9 6.4 2.2z"/>
        <path d="M-537.4 409.4v4.1c0 1.2-2.9 2.2-6.4 2.2-3.5 0-6.4-1-6.4-2.2v-4.1m6.4 6.5v5.4m30.2 27s10.1-.3 10.9-8.1m-71.6-5.6c-6.1.4-10.8 2.3-10.8 4.6s4.9 4.3 11.3 4.7m-.1 4.4s-10.1-.3-10.9-8.1"/>
      </g>
    </svg>

    <p class="picto-text">A mijoter</p>
  </div>
</div>

```

*/

.picto {
  text-align: center;
  svg {
    width: 107px; height: 119px;
  }
}

.picto-text {
  margin-top: rem(10);

  font-size: rem(14);
  font-weight: $font-weight-medium;
  text-transform: uppercase;
}

