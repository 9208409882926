// ==========================================================================
// Modale
// ==========================================================================

.modal {
  $self: &;

  width: 100%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background: $white;

  &--newsletter {
    max-width: 90%;

    #{$self} {
      &-dialog {
        margin: 0;
        padding: 0 !important;
        max-width: none !important;
        position: relative;
      }

      &-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
      }

      &-img {
        width: 100%;
      }

      &-btn {
        width: 100%;
      }

      &-title {
        width: 100%;
        text-align: center;
        padding-top: rem(10);
        padding-bottom: rem(15);
        display: block;
      }

      &-close {
        position: absolute;
        top: rem(20);
        right: rem(20);
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        border: rem(1) solid $black;
        background: rgba($white, .3);
        width: rem(30);
        height: rem(30);
        border-radius: 50%;

        &:hover {
          opacity: 1;
          background: rgba($white, .5);
        }

        span {
          position: relative;
          display: block;
          height: 100%;
          width: 100%;

          &::before,
          &::after {
            content: "";
            display: block;
            width: rem(2);
            height: rem(18);
            background: $darkbrown;
            position: absolute;
            top: calc(50% - #{rem(9)});
            left: calc(50% - #{rem(1)});
          }

          &::before {
            transform: rotate(45deg);
          }

          &::after {
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .modal {
    width: 50%;
  }
}
