// ==========================================================================
// Page 404
// ==========================================================================

.page404-section {
  background-size: 0px;
  background-repeat: no-repeat;
  background-position: top right;
}

.page404-btn-container {
  text-align: center;
}

@include media-breakpoint-up(md) {
  .page404-section {
    background-size: contain;
  }

  .page404-btn-container {
    text-align: initial;
  }
}
