// ==========================================================================
// Typographie
// ==========================================================================

/*doc
---
title: Typographie
name: typography
category: Base CSS
---

```html_example
<h1>Titre de niveau 1</h1>
<h2>Titre de niveau 2</h2>
<h3>Titre de niveau 3</h3>
<h4>Titre de niveau 4</h4>
<h5>Titre de niveau 5</h5>
<h6>Titre de niveau 6</h6>

<blockquote cite="http://loripsum.net">
	<p class="quote">Nunc vero a primo quidem mirabiliter occulta natura est nec perspici nec cognosci potest.</p>
	<footer class="quote-footer">
	  <cite>Ernest Soulard</cite>
	</footer>
</blockquote>

<a href="#" class="link-in-page">Lien dans la page</a>

<ul class="unordered-list unordered-list--classy">
	<li class="unordered-list__item">
	  <p class="unordered-list__main-text">Non enim, si omnia non sequebatur, idcirco non erat ortus illinc.</p>
	  <p class="unordered-list__secondary-text">Non enim, si omnia non sequebatur, idcirco non erat ortus illinc.</p>
	</li>
	<li class="unordered-list__item">
	  <p class="unordered-list__main-text">Ad eos igitur converte te, quaeso.</p>
	  <p class="unordered-list__secondary-text">Ad eos igitur converte te, quaeso.</p>
	</li>
	<li class="unordered-list__item">
	  <p class="unordered-list__main-text">Graecis hoc modicum est: Leonidas, Epaminondas, tres aliqui aut quattuor</p>
	  <p class="unordered-list__secondary-text">Graecis hoc modicum est: Leonidas, Epaminondas, tres aliqui aut quattuor. Graecis hoc modicum est: Leonidas, Epaminondas, tres aliqui aut quattuor</p>
	</li>
	<li class="unordered-list__item">
	  <p class="unordered-list__main-text">Illa videamus, quae a te de amicitia dicta sunt.</p>
	  <p class="unordered-list__secondary-text">Illa videamus, quae a te de amicitia dicta sunt.</p>
	</li>
</ul>

<ul class="unordered-list unordered-list--classy">
	<li class="unordered-list__item">
	  <p class="unordered-list__main-text">Non enim, si omnia non sequebatur, idcirco non erat ortus illinc.</p>
	</li>
	<li class="unordered-list__item">
	  <p class="unordered-list__main-text">Ad eos igitur converte te, quaeso.</p>
	</li>
	<li class="unordered-list__item">
	  <p class="unordered-list__main-text">Graecis hoc modicum est: Leonidas, Epaminondas, tres aliqui aut quattuor. Graecis hoc modicum est: Leonidas, Epaminondas, tres aliqui aut quattuor</p>
	</li>
	<li class="unordered-list__item">
	  <p class="unordered-list__main-text">Illa videamus, quae a te de amicitia dicta sunt.</p>
	</li>
</ul>
```

*/

/* == titles */
.h1, .h2, .h3, .h4, .h5, .h6 { line-height: 1; }

h1 {
  margin-bottom: rem(35);
}

h2 {
  margin-bottom: rem(12);
}

.title-white {
  color: $white;
}

/* == paragraphs */
body {
  font-weight: $font-weight-light;
}

.unordered-list--classy {
  margin: rem(15) 0; padding: 0;
  list-style-type: none;
  .unordered-list__item {
    margin-bottom: rem(5);
    padding: rem(5) rem(5) rem(5) rem(35);
    position: relative;
    &::before {
      content: "";
      display: inline-block;
      position: absolute;
      top: $font-size-base; left: 0;
      transform: translateY(-50%);
      margin-left: 0;
      width: rem(14); height: rem(14);

      background: url("#{$path-img}/svg/icon-list.svg") no-repeat center / rem(14); //url("../img/icon-list.svg")
      border-radius: 0;
    }
  }
}

.unordered-list__main-text,
.unordered-list__secondary-text{
  margin: 0;
}

.unordered-list__secondary-text{
  margin: 0 0 rem(5);
}

.unordered-list__main-text {
  margin-bottom: rem(5);
  font-size: rem(14);

  font-weight: $font-weight-bold;
}

/* == blockquote */
blockquote {
  margin: rem(50) 0;
  padding: rem(14) rem(14) rem(14) rem(100);

  background:  url("#{$path-img}/svg/icon-quote.svg") no-repeat top left / rem(66); //url("../img/icon-quote.svg") guide de style

  color: $gray-2;
  font-size: rem(30);
  font-family: $headings-font-family;
}

.quote {
  margin: 0;
}

.quote-footer {
  line-height: 1;
}

cite {
  color: $body-color;
  font-family: $font-family-sans-serif;
  font-size: rem(14);
  font-style: normal;
}

/* == Pseudo subtitle pink */
.pink-title {
  color: $pink;
  font-size: rem(20);
  font-weight: $font-weight-medium;

}

/* == Link */
.link-in-page {
  display: inline-block;
  padding-right: rem(50);
  margin: rem(30) 0;

  background: url("#{$path-img}/svg/icon-arrow.svg") no-repeat center right / rem(30);

  color: $pink;
  font-weight: $font-weight-medium;
  &:hover,
  &:focus,
  &:active {
    color: $pink;
  }
  .section-edito & {
    font-size: rem(16);
  }
}

/* == Colors */
.color--white {
  color: #FFF;
}

.color--pink {
  color: $pink;
}

.color--grey2 {
  color: $gray-2;
}

/* == Weight, decoration */

.font-weight-semi-bold {
  font-weight: $font-weight-medium;
}

.line-height-up {
  line-height: 1.7;
}
