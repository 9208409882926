// ==========================================================================
// Header
// ==========================================================================

header {
  //position: fixed;
  background: $white;
  border-bottom: 1px solid $gray-1;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  transition: top linear 300ms;
  position: sticky;
}

.header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;

  background: $white;
  &.search-expanded {
    align-items: center;
    padding: 0;
    transition: all ease 200ms;
    .header__search {
      flex-grow: 1;
      padding-left: rem(10);

      transition: all ease 100ms;
    }
    .header__utilities {
      flex: 0;
      width: 0px;
      height: 0px;
      overflow: hidden;
    }

    .header__menus {
      flex: 0;
      width: 0px;
      overflow: hidden;
    }
    .search-form {
      display: block;
    }
    .btn-menu--search-form {
      display: none;
      .sticky & {
        display: none;
      }
    }
    .form-search-input {
      flex: 1 0 70%;
    }
    .search-form__btn-container {
      flex: 0 1 15%;
      width: initial;
      height: initial;
      overflow: initial;
    }
  }

  &--simple {
    .header {
      &__child {
        flex-basis: auto;
        flex-grow: 0;
      }

      &__utilities {
        flex-basis: auto;
      }

      &__logo {
        max-width: rem(75);
      }
    }
  }
}

.header__logo {
  padding: rem(5) 0;
  img {
    width: rem(80);
  }
}

// #32904 : md Ã  lg
@media (hover: hover) {
  @include media-breakpoint-up(lg) {
    header {
      position: relative;
      z-index: 2;

      border-bottom: 2px solid $gray-1;
    }
  }
}

.top-info {
  display: flex;
  justify-content: space-between;
}

$padding-submenuitem-mobile: rem(20);

// .header__menus-container positionnement (pour styles, voir _menus.scss)
.header__menus-container {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 100%;
  z-index: 3;
  &.active {
    padding: $padding-submenuitem-mobile;
    width: initial;
    overflow: initial;
    left: 0;
  }
}

.header__child {
  flex: 1 1 auto;
}

// #32904 : md Ã  lg
@media (hover: hover) {
  @include media-breakpoint-up(lg) {
    .header {
      flex-flow: row wrap;
      padding-top: rem(15);
      justify-content: center;
      align-items: center;
      &.search-expanded {
        align-items: center;
        padding-top: rem(15);
        .header__search {
          flex-grow: 1;
          position: static;
          top: initial;
          transform: initial;
        }

        .header__utilities {
          flex: 0;
          width: 0px;
          height: 0px;
          overflow: hidden;
        }

        .header__menus {
          flex: 0 1 100%;
          width: initial;
          overflow: initial;
        }
        .form-search-input {
          flex: 1 0 70%;
        }
        .search-form__btn-container {
          flex: 0 1 30%;
          width: initial;
          height: initial;
          overflow: initial;
        }
      }

      &--simple {
        justify-content: center;

        &:after {
          content: "";
          flex: 1;
        }

        .header {
          &__utilities {
            flex: 1;
          }

          &__logo {
            max-width: none;
          }
        }
      }
    }

    .header__btn-menu {
      display: none !important;
    }

    .header__logo {
      flex: 0 0 150px;
      align-self: auto;

      img {
        width: initial;
      }
    }
    .header__search {
      flex: 0 1 35%;
    }

    .header__utilities {
      flex: 1 1 41.66%;
      margin-top: rem(10);
      div {
        display: none;
      }
    }

    .header__menus {
      flex: 0 0 80%;
    }

    .header__menus-container {
      display: flex;
      align-items: center;
      position: static;
      top: initial;
      right: initial;
      bottom: initial;
      left: 0;
    }

    .header__main-menu {
      flex: 0 1 60%;
      display: flex;
      align-items: flex-end;
    }

    .header__special-menu {
      flex: 40%;
    }
  }
}
@media (hover: hover) {
  @include media-breakpoint-up(lg) {
    .header__search {
      flex: 0 1 25%;
    }
  }
}
