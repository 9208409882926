// ==========================================================================
// Recipe details
// ==========================================================================
%pl-pseudo-elements-recipe-filter-list {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0; bottom: 0;
  width: 50vw;

  background: $skin;
}

.recipe-image {
  width: 100%;
}

.recipe-heading-info {
  line-height: 1;
}

@include media-breakpoint-up(md) {
  .recipe-heading-info {
    padding-left: rem(60);
  }
}

@include media-breakpoint-up(md) {
  %pl-crenellated-container-top-bottom-md {
    content: "";
    display: block;
    position: absolute;
    width: 50vw; height: 5px;

    background-color: transparent;
    background-image: radial-gradient($skin 3px, transparent 0);
    background-size: 9px 12px;
    background-repeat: repeat-x;
  }

  .recipe-filters-container {
    position: relative;

    background: $skin;
    &::before {
      @extend %pl-crenellated-container-top-bottom-md;
      bottom: 100%;

      background-position: -7px 0px ;
    }
    &::after {
      @extend %pl-crenellated-container-top-bottom-md;

      background-position: -7px -7px ;
    }
  }
}

.recipe-filters-list {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0; padding: rem(30) 0 rem(20);
  list-style-type: none;

  background: $skin;
  &::before {
    @extend %pl-pseudo-elements-recipe-filter-list;
    right: 100%;
  }
  &::after {
    @extend %pl-pseudo-elements-recipe-filter-list;
    left: 100%;
  }
}

@include media-breakpoint-up(sm) {
  .recipe-filters-list {
    flex-direction: row;
    justify-content: center;
  }
}

@include media-breakpoint-up(md) {
  .recipe-filters-list {
    flex-direction: row;
    &::before {
      display: none;
    }
  }
}


.recipe-filters-list__item {
  & + & {
    margin-top: rem(15);
  }
}

@include media-breakpoint-up(sm) {
  .recipe-filters-list__item {
    & + & {
      margin-top: rem(0);
    }
  }
}

.recipe-filters-list__text {
  padding: rem(80) rem(10) rem(10);

  background-repeat: no-repeat;
  background-position: top center;
  background-size: rem(60) auto;

  text-align: center;
  font-size: rem(12);
  span {
    display: block;

    font-size: rem(14);
    font-weight: $font-weight-medium;
  }
}

.recipe-filters-list__text--difficulty {
  background-image: url("#{$path-img}/picto/picto-difficulty.svg")
}

.recipe-filters-list__text--preparation {
  background-image: url("#{$path-img}/picto/picto-casserole.svg")
}

.recipe-filters-list__text--time {
  background-image: url("#{$path-img}/picto/picto-time.svg")
}

@include media-breakpoint-up(md) {
  .recipe-filters-list__text {
    position: relative;
    z-index: 1;
  }
}

.recipe-method-container {
  border-bottom: 2px solid $gray-1;
}

/* == Liste des ingrÃ©dients */
%pl-crenellated-container-left-right {
  content: "";
  position: absolute;
  display: block;
  top: 0; bottom: 0;
  width: 10px; height: 100%;

  background-color: transparent;
  background-image: radial-gradient($skin 3px, transparent 0);
  background-size: 10px 10px;
  background-repeat: repeat-y;

}

.recipe-ingredients {
  position: relative;
  &::before {
    @extend %pl-crenellated-container-left-right;
    right: 100%;
    background-position: right -5px center;
  }
  &::after {
    @extend %pl-crenellated-container-left-right;
    left: 100%;
    background-position: left -5px center;
  }
  ul {
    margin: rem(15) 0 rem(25); padding: 0;
    list-style-type: none;
    li {
      padding: rem(5) rem(5) rem(5) rem(20);
      position: relative;
      line-height: 1.5;
      & + & {
        margin-top: rem(20);
      }
      &::after {
        content: "";
        position: absolute;
        top: $font-size-base; left: 0;
        transform: translateY(-50%);
        display: inline-block;
        margin-left: rem(10);
        width: rem(4); height: rem(4);

        background: $darkbrown;
        border-radius: 4px;
      }
    }
  }
  img {
    width: 100%;
  }
}

.recipe-ingredients__people {
  margin: rem(15) 0;

  font-weight: $font-weight-medium;
}

/* == Les produits de cette recette */
.products-in-that-recipe {
  display: flex;
  flex-direction: column;
}

@include media-breakpoint-up(lg) {
  .products-in-that-recipe {
    flex-direction: row;
    flex-wrap: wrap;
    article {
      flex-basis: 50%;
    }
  }
}

@include media-breakpoint-up(xl) {
  .products-in-that-recipe {
    article {
      flex-basis: 33.33%;
      flex-grow: 1;
    }
    &.products-in-that-recipe--no-grow {
      article {
        flex-grow: 0;
      }
    }
  }
}
