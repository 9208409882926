// ==========================================================================
// Header sticky
// ==========================================================================

@include media-breakpoint-up(md) {
  %pl-sticky-separator {
    content: "";
    display: inline-block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 2px;
    height: 20px;

    background: $body-color;
  }

  .header__menus {
    transition: flex-basis 500ms ease-in-out, margin-bottom 500ms linear;
  }

  .sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;
    .header {
      padding: 0;
      justify-content: center;
    }

    .header__menus {
      margin-top: 0;
      //margin-bottom: rem(20);
    }
    .header__special-menu {
      position: relative;
      align-self: center;
    }

    .sub-menu {
      text-align: left;
    }
    .header__logo,
    .header__search,
    .header__utilities {
      margin-bottom: rem(0);
      flex: 0 1 0px;
      overflow: hidden;
      opacity: 0;
      transition: opacity 300ms linear;
    }
    .header__search {
      order: 3;
      text-align: center;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }

    .header__utilities {
      position: relative;
      order: 4;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      &::before {
        @extend %pl-sticky-separator; // add
      }
    }
    .utilities-menu__text {
      display: none;
    }
    .utilities-menu {
      ul {
        display: flex;
        padding-left: rem(10);
        margin-bottom: 0;
      }
      li + li {
        margin-left: rem(20);
      }
    }

    // Search form
    .search-form {
      display: none;
    }
    .btn-menu--search-form {
      &.btn-menu {
        display: inline-block;
      }
      width: rem(19);
      height: rem(19);
    }

    // utilities menu et search form
    .btn-menu {
      display: inline-block;
    }

    // #################################################################################################################
    &.sticky--half-way {
      .header {
        flex-wrap: nowrap;
      }
      .header__menus {
        //padding-left: rem(10);
        //padding-right: rem(10);
        //padding-bottom: rem(15);
        flex-basis: rem(404);
        margin-bottom: 0;
      }
      .simple-menu--header {
        border: none;
      }
      .header__special-menu {
        &::before {
          @extend %pl-sticky-separator;
          margin-top: rem(-7.5);
        }
      }
    }

    &.sticky--complete {
      .header__menus-container {
        //align-items: center;
      }
      .header__logo,
      .header__search,
      .header__utilities {
        opacity: 1;
        overflow: initial;
      }
      .header__logo {
        flex: 0 0 rem(150);
        img {
          width: rem(76);
        }
      }
      .header__search {
        flex: 0 0 rem(40);
      }

      .header__utilities {
        flex: 0 0 rem(110);
      }

      .search-expanded {
        //outline: 1px solid red;
        .header__menus {
          flex: 0;
          width: 0;
          height: 0;
          overflow: hidden;
        }
        .header__utilities {
          flex: 0 0 rem(110);
          width: initial;
          height: initial;
        }
        .header__search {
          flex-grow: 1;
          padding-right: rem(15);
        }
        .form-search-input {
          flex: 1 0 90%;
        }
      }
    }
  }
}
@media (hover: hover) {
  @include media-breakpoint-up(lg) {
    .sticky {
      &.sticky--half-way {
        .header__menus {
          flex-basis: rem(634);
        }
      }
    }
  }
}

@media (hover: hover) {
  @include media-breakpoint-up(xl) {
    .sticky {
      &.sticky--half-way {
        .header__menus {
          padding-left: rem(40);
          padding-right: rem(40);
          flex-basis: rem(934);
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .promo-banner {
    &.sticky-banner {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1;
    }
    a {
      color: white;
      text-decoration: underline;
      &:hover,
      &:focus,
      &:active {
        color: white;
      }
    }
  }
}
