// ==========================================================================
// Recipes list
// ==========================================================================

.filters-title {
  font-weight: $font-weight-medium;
  margin-bottom: 0;
}

@include media-breakpoint-up(md) {
  .filter-form-container {
    display: flex;
    justify-content: space-between;

    border-bottom: 2px solid $gray-1;
  }
}

@include media-breakpoint-up(lg) {
  .filters-title {
    align-self: center;
  }
}

