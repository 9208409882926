// ==========================================================================
// Bloc de contenu noir et pattes de canard
// ==========================================================================


.dark-duck-foot-block {
  padding: rem(25);

  background-color: $darkbrown;
  background-image: url("#{$path-img}/fond-pattes-de-canard-seules2x.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
}

// SpÃ©cial page DÃ©tail recette
.dark-duck-foot-block--tips {
  background-size: 250% auto;
}

.dark-duck-foot-block__content {
  padding: rem(30);

  background: $white;
}
